import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root'
  })
export class SignalRService {

    public hubConnection: HubConnection;
    x: {};

    constructor(private router: Router){
        //this.initializeSignalR();
    }


    initializeSignalR() {
 
        var connection =  new signalR.HubConnectionBuilder()  
            .configureLogging(signalR.LogLevel.Information)  
            .withUrl(`${environment.apiUrl}` + '/api/v1/signalrnotify')  
            .build();  
        this.x = {};
        this.hubConnection = connection;
        console.log()
        connection.start().then(function () {  
            console.log('SignalR Connected!');  
            // connection.invoke("JoinGroup", window.location.origin)  //JoinGroup is C# method name
            //     .catch(err => {
            //         console.log(err);
            //     });

            
        }).catch(function (err) {  
            return console.error(err.toString());  
        });  
    }

    async addToGroup(groupName)
    {
        while(this.hubConnection.state !== signalR.HubConnectionState.Connected)
        {
            await this.sleep(2000);
        }

        console.log('Adding user to group', groupName);  
        this.hubConnection.invoke("JoinGroup", groupName) 
        .catch(err => {
            console.log(err);
        });
    }
    
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
