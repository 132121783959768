
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  timer ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileModel } from '../models/FileModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, tap, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { AssessableEntityLinkModel, DocumentLibraryDeliverableViewModel } from '@app/models/AssessableEntityLinkModel';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DocumentService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    private cache$: Observable<Array<FileModel>>;
    private reload$ = new Subject<void>();

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }

    public getDocuments(assessableEntityId, isManager): Observable<any> {
        return this.httpclient.get(`/api/v1/documents/${assessableEntityId}/${isManager}`)
            .pipe(res => res)
    }

    public getEntityDocuments(entityId, entityType): Observable<any> {
        return this.httpclient.get(`/api/v1/documents/entity/${entityId}/${entityType}`)
            .pipe(res => res)
    }

    public getLibraryDocuments(isRepository): Observable<any> {
        return this.httpclient.get<any>(`/api/v1/documents/library/${isRepository}`)
            .pipe(map(res => res))
    }

    public getLibraryDocumentById(documentId): Observable<any> {
        return this.httpclient.get<any>(`/api/v1/documents/library/id/${documentId}`)
            .pipe(map(res => res))
    }

    public moveToFolder(id, folderid, assessableEntityId): Observable<any> {
        return this.httpclient.get(`/api/v1/documents/move/${id}/folder/${folderid}/${assessableEntityId}`)
            .pipe(res => res)
    }

    public saveFolder( isManager, formData): Observable<any> {

        return this.httpclient.post<any>(`/api/v1/documents/folders/${isManager}`, formData).pipe(
            tap(res => {
                this.toastr.success('Folder Saved');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }
    public saveLibraryFolder(formData): Observable<any> {

        return this.httpclient.post<any>(`/api/v1/documents/library/folders`, formData).pipe(
            tap(res => {
                this.toastr.success('Folder Saved');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public deleteFolder(id, assessableEntityId): Observable<any> {

        return this.httpclient.delete(`/api/v1/documents/folders/${id}/${assessableEntityId}`).pipe(
            tap(res => {
                this.toastr.success('Folder Deleted');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public deleteLibraryFolder(id, isRepository): Observable<any> {

        return this.httpclient.delete(`/api/v1/documents/library/folders/${id}/${isRepository}`).pipe(
            tap(res => {
                this.toastr.success('Folder Deleted');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public uploadLibraryFile(isRepository, formData): Observable<any> {

        return this.httpclient.post<any>(`/api/v1/documents/library/${isRepository}`, formData).pipe(
            tap(res => {
                if (isRepository) {
                    this.toastr.success('A new document has been attached to the Document Repository!');
                } else {
                    this.toastr.success('Library file Uploaded!');
                }
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public editLibraryFile(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/documents/library/edit', formData).pipe(
            tap(res => {
                this.toastr.success('Document Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public deleteLibraryFile(id): Observable<any> {

        return this.httpclient.delete(`/api/v1/documents/library/${id}`).pipe(
            tap(res => {
                this.toastr.success('Document Successfully Deleted');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public getLibraryFile(id, isRepository): Observable<any> {
        return this.httpclient.get(`/api/v1/library/${id}/${isRepository}`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public getLinkableProjects(): Observable<AssessableEntityLinkModel[]> {
        return this.httpclient.get<any>('/api/v1/documents/library/linkableprojects')
            .pipe(map(res => res))
    }

    public getLinkablePortfolios(): Observable<AssessableEntityLinkModel[]> {
        return this.httpclient.get<any>('/api/v1/documents/library/linkableportfolios')
            .pipe(map(res => res))
    }

    public getLinkableContracts(): Observable<AssessableEntityLinkModel[]> {
        return this.httpclient.get<any>('/api/v1/documents/library/linkablecontracts')
            .pipe(map(res => res))
    }

    public getLinkableGrants(): Observable<AssessableEntityLinkModel[]> {
        return this.httpclient.get<any>('/api/v1/documents/library/linkablegrants')
            .pipe(map(res => res))
    }

    public getLinkableAssets(): Observable<AssessableEntityLinkModel[]> {
        return this.httpclient.get<any>('/api/v1/documents/library/linkableassets')
            .pipe(map(res => res))
    }

    public getLinkedEntities(id: any, entityType: any): Observable<DocumentLibraryDeliverableViewModel[]> {
        return this.httpclient.get<any>(`/api/v1/documents/library/linkedentities/${id}/${entityType}`)
            .pipe(map(res => res))
    }

    public saveDocumentLink(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/documents/library/documentlink', formData).pipe(
            tap(res => {
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    errorHandler(error: any): void {
    }

    forceReload() {
        this.reload$.next();
        this.cache$ = null;
    }


}
