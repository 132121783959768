<div class="m-subheader">
    <div class="d-flex align-items-center">
        <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">
                {{headerText}}
            </h3>
            <div class="m-subheader__breadcrumbs m-nav m-nav--inline">
                <div class="m-subheader__breadcrumbs m-nav m-nav--inline">
                    <div class="m-nav__item">
                        <a [routerLink]="homeLink" class="m-nav__link m-nav__link--icon">
                            <i class="m-nav__link-icon la la-home"></i>
                        </a>
                    </div>
                    <div class="m-nav__item" *ngIf="addButtonEnabled">
                        <a class="m-nav__link m-nav__link--icon"  [routerLink]="addButtonLink">
                            <i class="m-nav__link-icon la la-plus tooltips"></i>
                        </a>
                    </div>
                    <div class="m-nav__item">
                        <a class="m-nav__link m-nav__link--icon"   onclick="window.print()">
                            <i class="m-nav__link-icon la la-print tooltips"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>