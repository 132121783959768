<div *ngIf="modal; then modalUserContent; else userContent" >  </div>
<ng-template #modalUserContent>   
    <div class="modal fade show" id="m_modal_add_approvableItem" role="dialog" tabindex="-1"
        style="padding-right: 17px; display: block;" aria-labelledby="exampleModalCenterTitle">
        <div class="modal-dialog modal-dialog-centered" style="min-width: 580px; max-width: 900px;" role="document">
            <div class="modal-content">
    <div class="m-subheader" appunwraptag=""  *ngIf="!modal" >
        <div class="d-flex align-items-center">
            <div class="mr-auto">
                <h3 class="m-subheader__title">
                    My Profile
                </h3>
                <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                    <li class="m-nav__item m-nav__item--home">
                        <a routerLink="/index" class="m-nav__link m-nav__link--icon">
                            <i class="m-nav__link-icon la la-home"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
        <div *ngIf="1==1; then userContent"> </div>
    </div>
    </div>
    </div>
</ng-template>

<ng-template #userContent>
<div class="m-content" *ngIf="user">
    <div class="row" id="user">
        <div class="col-xl-3 col-lg-4">
            <div class="m-portlet m-portlet--full-height">
                <div class="m-portlet__body">
                    <div class="m-card-profile">
                        <div class="m-card-profile__title m--hide">
                            Your Profile
                        </div>
                        <div class="m-card-profile__pic">
                            <div class="m-card-profile__pic-wrapper">
                                <div *ngIf="imageUserPic">
                                    <img class="user-photo-side-panel" [src]="imageUserPic">
                                </div>
                            </div>
                        </div>
                        <div class="m-card-profile__details">
                            <span class="m-card-profile__name">
                                {{ user.firstname }} {{ user.lastname }}
                            </span>
                        </div>
                    </div>
                    <ul class="m-nav m-nav--hover-bg m-portlet-fit--sides">
                        <li class="m-nav__separator m-nav__separator--fit"></li>
                        <li class="m-nav__section m--hide">
                            <span class="m-nav__section-text">
                                Section
                            </span>
                        </li>
                        <li class="m-nav__item">
                            <a routerLink="/user" class="m-nav__link">
                                <i class="m-nav__link-icon flaticon-profile-1"></i>
                                <span class="m-nav__link-title">
                                    <span class="m-nav__link-wrap">
                                        <span class="m-nav__link-text">
                                            My Profile
                                        </span>
                                    </span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div class="m-portlet__body-separator"></div>
                </div>
            </div>
        </div>
        <div class="col-xl-9 col-lg-8">
            <div class="m-portlet m-portlet--full-height m-portlet--tabs">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-tools">
                        <ul class="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--left m-tabs-line--primary" role="tablist">
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link active" data-toggle="tab" href="#m_user_profile_tab_1" role="tab">
                                    <i class="flaticon-share m--hide"></i>
                                    Details
                                </a>
                            </li>
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_user_profile_tab_2" role="tab">
                                    Picture
                                </a>
                            </li>
                            <li class="nav-item m-tabs__item" *ngIf="1==2" >
                                <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_user_profile_tab_3" role="tab">
                                    Change Password
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="tab-content">
                    <div class="tab-pane active" id="m_user_profile_tab_1">
                        <form class="m-form m-form--fit m-form--label-align-right" id="tab1" #profileForm="ngForm" (ngSubmit)="saveChanges(profileForm)">
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <div class="col-10 ml-auto">
                                        <h3 class="m-form__section">
                                            Personal Details
                                        </h3>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        First Name
                                    </label>
                                    <div class="col-8">
                                        <input class="form-control m-input" type="text" id="firstname" name="firstname" [(ngModel)]="user.firstname" maxlength="30"
                                               data-required="1" required #_firstname="ngModel" />
                                        <div [hidden]="_firstname.valid || _firstname.pristine">
                                            First Name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Last Name
                                    </label>
                                    <div class="col-8">
                                        <input class="form-control m-input" type="text" id="lastname" name="lastname" [(ngModel)]="user.lastname" maxlength="30"
                                               data-required="1" required #_lastname="ngModel" />
                                        <div [hidden]="_lastname.valid || _lastname.pristine">
                                            Last Name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Email
                                    </label>
                                    <div class="col-8">
                                        <input class="form-control m-input" type="text" id="email" name="email" [(ngModel)]="user.email" [attr.disabled]="!modal ? '' : null"  data-required="1" required #_email="ngModel" />
                                        <div [hidden]="_email.valid || _email.pristine">
                                            Email is required
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isRecipient && isOrganisation == 'no'">
                                    <div class="form-group m-form__group row">
                                        <label for="example-text-input" class="col-4 col-form-label">
                                            Registered Address
                                        </label>
                                        <div class="col-8">
                                            <ejs-autocomplete 
                                                class="form-control m-input {{ validateFields('required', user.address) }}"
                                                [(ngModel)]="user.address"
                                                name="address"
                                                [dataSource]="addressSearchDataSource" 
                                                [placeholder]="'Enter Registered Address'" 
                                                [fields]="{ value: 'freeformAddress' }" 
                                                [(ngModel)]='user.address'
                                                [disabled]="isManualAddress"
                                                (filtering)="onAddressChange($event)"
                                                (select)="onAddressSearchSelect($event)"
                                                [highlight]="true"
                                                [minLength]="3"
                                                #_address="ngModel"
                                                required="!isManualAddress">
                                            </ejs-autocomplete>
                                            <span>Start typing the address and choose a result, or enter address manually.</span>
                                            <div *ngIf="_address.invalid && (_address.dirty || _address.touched) && !isManualAddress" class="error-message">
                                                Address is required.
                                            </div>
                                            <div *ngIf="user.address && (_address.dirty || _address.touched) && !isAddressFound" class="error-message">
                                                No records found. Enter address manually.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label for="example-text-input" class="col-4 col-form-label">
                                        </label>
                                        <div class="col-8">
                                            <formsCheckbox
                                                [label]="'Enter address manually'"
                                                [checked]="isManualAddress"
                                                (checkBoxValue)="onCheckBoxManualAddressChange($event)">
                                            </formsCheckbox>
                                        </div>
                                    </div>
                                    <div *ngIf="isManualAddress">
                                        <div class="form-group m-form__group row">
                                            <label for="example-text-input" class="col-4 col-form-label">
                                                Address Line 1
                                            </label>
                                            <div class="col-8">
                                                <input class="form-control m-input {{ validateFields('required',user.addressLine1) }}" 
                                                    type="text" autocomplete="off"
                                                    name="addressLine1"
                                                    (ngModelChange)="enableButton()"
                                                    [(ngModel)]="user.addressLine1" 
                                                    data-required="1"
                                                    required
                                                    #_address1="ngModel"
                                                    maxlength="300" 
                                                    minlength="1" 
                                                    placeholder="e.g. 123 Four Street"/>
                                                <div *ngIf="_address1.invalid && (_address1.dirty || _address1.touched)" class="error-message">
                                                    Address Line 1 is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label for="example-text-input" class="col-4 col-form-label">
                                                Address Line 2
                                            </label>
                                            <div class="col-8">
                                                <input class="form-control m-input" 
                                                    type="text" autocomplete="off"
                                                    name="addressLine2"
                                                    (ngModelChange)="enableButton()"
                                                    [(ngModel)]="user.addressLine2" 
                                                    maxlength="300" 
                                                    minlength="1" 
                                                    placeholder="e.g. Unit 17"/>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label for="example-text-input" class="col-4 col-form-label">
                                                Country
                                            </label>
                                            <div class="col-8">
                                                <ejs-dropdownlist
                                                    class="form-control m-input {{ validateFields('required', user.country) }}"
                                                    [(ngModel)]="user.country"
                                                    (ngModelChange)="enableButton()"
                                                    name="country"
                                                    [allowFiltering]="true"
                                                    [value]="user.country"
                                                    [filterBarPlaceholder]="'Search Country'"
                                                    [placeholder]="'Select Country'"
                                                    [fields]="{ value: 'name' }" 
                                                    [dataSource]="countries"
                                                    (select)="onCountryChange($event)"
                                                    [readonly]="true"
                                                    #_country="ngModel"
                                                    required>
                                                </ejs-dropdownlist>
                                                <div *ngIf="_country.invalid && (_country.dirty || _country.touched)" class="error-message">
                                                    Country is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label for="example-text-input" class="col-4 col-form-label">
                                                State / Territory
                                            </label>
                                            <div class="col-8">
                                                <ejs-dropdownlist 
                                                    class="form-control m-input {{ validateFields('required', user.stateBusinessLocationName) }}"
                                                    [(ngModel)]="user.stateBusinessLocationName"
                                                    (ngModelChange)="enableButton()"
                                                    name="stateOrTerritory"
                                                    [allowFiltering]="true"
                                                    [filterBarPlaceholder]="'Search State or Territory'"
                                                    [placeholder]="'Select State Or Territory'"
                                                    [fields]="{ value: 'name' }" 
                                                    [dataSource]="stateBusinessLocations"
                                                    (select)="onStateChange($event)"
                                                    #_stateOrTerritory="ngModel"
                                                    required>
                                                </ejs-dropdownlist>
                                                <div *ngIf="_stateOrTerritory.invalid && (_stateOrTerritory.dirty || _stateOrTerritory.touched)" class="error-message">
                                                    State or Territory is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label for="example-text-input" class="col-4 col-form-label">
                                                City / Suburb
                                            </label>
                                            <div class="col-8">
                                                <ejs-dropdownlist
                                                    class="form-control m-input {{ validateFields('required', user.cityOrSuburb) }}"
                                                    [(ngModel)]="user.cityOrSuburb"
                                                    (ngModelChange)="enableButton()"
                                                    name="cityOrSuburb"
                                                    [allowFiltering]="true"
                                                    [filterBarPlaceholder]="'Search City or Suburb'"
                                                    [placeholder]="'Select City or Suburb'"
                                                    [fields]="{ value: 'name' }" 
                                                    [dataSource]="cities"
                                                    (select)="onCityChange($event)"
                                                    #_cityOrSuburb="ngModel"
                                                    required>
                                                </ejs-dropdownlist>
                                                <div *ngIf="_cityOrSuburb.invalid && (_cityOrSuburb.dirty || _cityOrSuburb.touched)" class="error-message">
                                                    City or Suburb is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group m-form__group row">
                                            <label for="example-text-input" class="col-4 col-form-label">
                                                Post Code
                                            </label>
                                            <div class="col-8">
                                                <input class="form-control m-input {{ validateFields('required',user.postcodeOfMainBusinessLocation) }}" 
                                                    type="text" autocomplete="off"
                                                    name="postcodeOfMainBusinessLocation"
                                                    (ngModelChange)="enableButton()"
                                                    [(ngModel)]="user.postcodeOfMainBusinessLocation"
                                                    (keypress)="numbersOnlyValidator($event)"
                                                    data-required="1"
                                                    required
                                                    #_postcodeOfMainBusinessLocation="ngModel"
                                                    maxlength="4" 
                                                    minlength="1"
                                                    min="1000"
                                                    max="9999"
                                                    placeholder="e.g. 4000"/>
                                                <div *ngIf="_postcodeOfMainBusinessLocation.invalid && (_postcodeOfMainBusinessLocation.dirty || _postcodeOfMainBusinessLocation.touched)" class="error-message">
                                                    Post Code is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div  *ngIf="showEULA()" >
                                    <div class="form-group m-form__group row">
                                        <div class="col-10 ml-auto">
                                            <h3 class="m-form__section">
                                                Legal
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="form-group m-form__group row">
                                        <label for="eula-date" class="col-4 col-form-label">
                                            EULA Accepted
                                        </label>
                                        <div class="col-8">
                                            <input class="form-control" type="date" id="eula-date" [value]="eulaAcceptedDate" name="eula-date" readonly="true" />
                                            <label class="col-form-label">
                                                <a href="{{ eulaLink }}" target="_blank" class="eula-link">
                                                    <i class="icon-funder-external-link" aria-hidden="true"> </i>View agreement
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row" *ngIf="modal">
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Organisation
                                    </label>
                                    <div class="col-8">
                                        <input class="form-control m-input" type="text" id="org" name="org" [(ngModel)]="!user.organisation ? '' : user.organisation.name" disabled="disabled" />
                                    </div>
                                </div>            
                                <div class="form-group m-form__group row" *ngIf="modal">
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Department
                                    </label>
                                    <div class="col-8">
                                        <select class="form-control m-input" name="department" id="department" [compareWith]="compareFn" required data-required="1" #_department="ngModel"
                                                [(ngModel)]="selectedDepartment" (ngModelChange)="onDepartmentOptionSelected($event)">
                                            <option *ngFor="let department of departments" [ngValue]="department">
                                                {{ department.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row" *ngIf="modal">
                                    <label for="example-text-input" class="col-4 col-form-label" >
                                        Business Unit
                                    </label>
                                    <div class="col-8">
                                        <select class="form-control m-input" name="bu" id="bu" [compareWith]="compareFn" required data-required="1" #_bu="ngModel"
                                                [(ngModel)]="selectedBusinessUnit" (ngModelChange)="onBusinessUnitOptionSelected($event)">
                                            <option *ngFor="let bu of businessUnits" [ngValue]="bu">
                                                {{ bu.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                        <label for="example-text-input" class="col-4 col-form-label">
                                            Active
                                        </label>
                                        <div class="col-8">
                                            <input type="checkbox" name="active" class="form-control-checkbox m-input" [(ngModel)]="user.active">                               
                                        </div>

                                </div>   
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                        <label for="example-text-input" class="col-4 col-form-label">
                                            View Only
                                        </label>
                                        <div class="col-8">
                                            <input type="checkbox" name="viewOnly" class="form-control-checkbox m-input"  [(ngModel)]="user.viewOnly">                               
                                        </div>
                                </div>   
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Locked
                                    </label>
                                    <div class="col-8">
                                        <input type="checkbox" name="locked" class="form-control-checkbox m-input"  [(ngModel)]="user.locked">                               
                                    </div>
                                </div>   
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                        <label for="example-text-input" class="col-4 col-form-label">
                                            Default Member
                                        </label>
                                        <div class="col-8">
                                            <input type="checkbox" name="isDefaultMember" class="form-control-checkbox m-input" [(ngModel)]="user.isDefaultMember">                               
                                        </div>
                                </div>   
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                        <label for="example-text-input" class="col-4 col-form-label">
                                            Hide Financial Data
                                        </label>
                                        <div class="col-8">
                                            <input type="checkbox" name="hideFinancialData" class="form-control-checkbox m-input" [(ngModel)]="user.hideFinancialData" >                               
                                        </div>
                                </div>    
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                        <label for="example-text-input" class="col-4 col-form-label">
                                           Customer Administrator
                                        </label>
                                        <div class="col-8">
                                            <input type="checkbox" name="isCustomerAdmin" class="form-control-checkbox m-input" [(ngModel)]="user.isCustomerAdmin" (change)="checkboxValueChange($event.target,user)" >                               
                                        </div>
                                </div>  
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                        <label for="example-text-input" class="col-4 col-form-label">
                                            System Administrator
                                        </label>
                                        <div class="col-8">
                                            <input type="checkbox" name="isAdmin" class="form-control-checkbox m-input" [(ngModel)]="user.isAdmin" (change)="checkboxValueChange($event.target,user)" [disabled]= "!requestingUser.isAdmin" >                               
                                        </div>
                                </div>  
								<div class="form-group m-form__group row" *ngIf="modal" >
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        SSO Enabled
                                    </label>
                                    <div class="col-8">
                                        <input type="checkbox" name="ssoEnabled" class="form-control-checkbox m-input" [(ngModel)]="user.ssoEnabled" >                               
                                    </div>
                                </div>    
                                <div class="form-group m-form__group row" *ngIf="modal" >
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Contractor
                                    </label>
                                    <div class="col-1">
                                        <input type="checkbox" name="isContractor" class="form-control-checkbox m-input" [(ngModel)]="user.isContractor"  (change)="checkboxValueChange($event.target,user)">  
                                    </div>
                                    <div class="col-7">
                                          <select class="form-control m-input" name="contractor" id="constractor" [compareWith]="compareFn" #_contractor="ngModel" [disabled]="!user.isContractor" 
                                        [(ngModel)]="selectedContractor" (ngModelChange)="onContractorOptionSelected($event)">
                                            <option *ngFor="let contractor of contractors" [ngValue]="contractor">
                                                {{ contractor.name }}
                                            </option>
                                        </select>                            
                                    </div>
                                </div>  
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-outline-sp btn-sm m-btn m-btn--air m-btn--custom" [disabled]="!profileForm.form.valid"
                                         data-dismiss="modal">Save Changes</button>
                                    <button type="button" *ngIf="modal"  class="btn btn-secondary" (click)="onCloseClicked()">Close</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane" id="m_user_profile_tab_2">
                        <form class="m-form m-form--fit m-form--label-align-right" id="uploadForm">
                            <div class="m-portlet__body">
                                <div class="form-group m-form__group row">
                                    <label for="example-text-input" class="col-4 col-form-label">
                                        Current Profile Photo
                                    </label>
                                    <div class="col-12 text-center">
                                        <img class="user-photo-upload" [src]="imageUserPicSelectOnUpload">
                                    </div>
                                </div>
                                <div class="form-group m-form__group row">
                                    <div class="col-12 ejs-uploader-div">
                                            <ejs-uploader #defaultupload required [multiple]="false" [autoUpload]='false'  [dropArea]='dropElement'  style="width: 800px;" allowedExtensions=".png, .jpg" [maxFileSize]=1000000 
                                             (success)="onSuccess($event)" (uploading)="onChunkuploading($event)" (removing)='onChunkuploading($event)' [asyncSettings]='path' (selected)="onSelected($event)"></ejs-uploader>     
                                    </div>
                                    <div class="uploading-photo-note">Note : Only .png and .jpg filetypes can be used.</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-template>