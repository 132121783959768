import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  Subject, ReplaySubject ,  Subscriber } from 'rxjs';
import { UserModel } from '@app/models/UserModel';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil } from 'rxjs/operators';

import { AuthService } from "@app/auth/_services/auth0.service";
import { ProjectModel } from '@app/models/ProjectModel';
import { RiskFactorQuestionModel } from '@app/models/RiskFactorQuestionModel';
import { WorkflowTemplateModel } from '@app/models/WorkflowTemplateModel';
import { WorkflowModel } from '@app/models/WorkflowModel';
import { MemberModel } from '@app/models/MemberModel';
import { ToastrService } from 'ngx-toastr';
import { WorkflowGroupModel } from '@app/models/WorkflowGroupModel';
import { firstValueFrom } from 'rxjs';

const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class WorkflowService {
    handleError(arg0: any): any {
        throw new Error("WorkflowService Method not implemented.");
    }

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }

    public getWorkflowTemplates(): Observable<WorkflowTemplateModel[]> {
        return this.httpclient.get<any>('/api/v1/workflow/templates')
            .pipe(map(res => res))
    }  

    public getWorkflowTemplate(id): Observable<WorkflowTemplateModel> {
        return this.httpclient.get<any>('/api/v1/workflow/templates/' + id)
            .pipe(map(res => res))
    }
    
    public getFilteredWorkflowTemplate(id,entityId): Observable<WorkflowTemplateModel> {
        return this.httpclient.get<any>('/api/v1/workflow/templates/' + id+'/filter/'+entityId +'/')
            .pipe(map(res => res))
    }

    public getWorkflowQuestions(id): Observable<RiskFactorQuestionModel> {
        return this.httpclient.get<any>('/api/v1/workflow/questions')
            .pipe(map(res => res))
    }

    async getWorkflowTemplatesPreload(workflowName) {
        var workflowTemplates
        var templatesId
        await firstValueFrom(this.getWorkflowTemplates()).then((templatetypes:any) => {
            templatesId = templatetypes.find(i => i.name == workflowName).id;        
        });
        if (templatesId != null) {
            workflowTemplates = await this.getWorkflowTemplatePreload(templatesId)
        }else{
            workflowTemplates = null
        }
        return workflowTemplates
    }
    
    async getWorkflowTemplatePreload(templatesId) {
        var workflowTemplate
        await firstValueFrom(this.getWorkflowTemplate(templatesId)).then((template:any) => {
            workflowTemplate =  template
        });
        return workflowTemplate
    }
}
