import { Component, Inject, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { LoginCustom } from './_helpers/login-custom';
import { Helpers } from '../helpers';
import { AuthService } from './_services/auth0.service';
import { Subject } from 'rxjs';
import { LoggingService } from '@app/services/logging.service';
import { environment } from '@env/environment';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    styleUrls: ['./templates/login-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AuthComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    authProvider: string;
    loginDisplay = false;
    @ViewChild('alertSignin',
        { read: ViewContainerRef }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup',
        { read: ViewContainerRef }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass',
        { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;
        private readonly _destroying$ = new Subject<void>();

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        private loggingService: LoggingService, 
        private authService: AuthService) {
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

        this.authProvider  = this._route.snapshot.queryParams['Auth'];
        var host = window.location.host;
        var subdomain = host.split('.')[0];
        if(environment.aadTenants.includes(subdomain))
        {
            this.authProvider = "AAD";
        }
                
        localStorage.setItem('authProvider', this.authProvider);

        this.authService.handleAuthentication();

        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',  // deleted TW 20180910
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
        
        this._router.events.subscribe(() => {
            this.checkParameterValue()
        });
    }

    checkParameterValue() {
        this._route.fragment.subscribe((fragment: string) => {
            const params = new URLSearchParams(fragment);
            const accessToken = params.get('access_token');
            if(accessToken) {
                this.loading = true
            } else {
                this.loading = false
            }
        });
    }

    signin() {
 
        this.loading = true;
 
        if(this.authProvider !== "AAD")
            this.authService.login();
        else
            this.authService.aadLogin();
    }

    logout() {
        this.authService.logout();
    }

    get authenticated(): boolean {
        return this.authService.isAuthenticated();
    }
}