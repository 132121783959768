import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { DashboardGuard } from '../auth/_guards/dashboard.guard';
import { MaintenanceComponent } from '../auth/maintenance/maintenance.component';
import { AccountsService } from '@app/services/accounts.service';
import { takeUntil } from 'rxjs/operators';
import { TenancyGuard } from '../auth/_guards/tenancy.guard';
import { OrganisationTenancyType } from '@app/constants';
import { EntityDescriptions } from '@app/constants';

const routes: Routes = [
    {
        'path': '',
        'component': ThemeComponent,
        'canActivate': [AuthGuard],
        'canActivateChild': [AuthGuard],
 
        'children': [
            {
                'path': 'index',
                'loadChildren': () => import('./pages/default/home/home.module').then(m => m.HomeModule),
                title: "Dashboard",
                'canActivate': [DashboardGuard],
            },
            {
                'path': '',
                'redirectTo': 'index',
                'pathMatch': 'full',
            },
            {
                'path': 'assets',
                'loadChildren': () => import('./pages/default/assets/assets.module').then(m => m.AssetsModule),
                title: "Asset Register",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Assets}
            },
            {
                'path': 'assets/home',
                'redirectTo': 'assets',
                'pathMatch': 'full',
            },
            {
                'path': 'projects',
                'loadChildren': () => import('./pages/default/projects/projects.module').then(m => m.ProjectsModule),
                title: "Projects",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Projects}
            },
            {
                'path': 'portfolios',
                'loadChildren': () => import('./pages/default/portfolios/portfolios.module').then(m => m.PortfolioModule),
                title: "Portfolios",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Portfolios}
            },
            { 
                'path': 'contracts',
                'loadChildren': () => import('./pages/default/contracts/contracts.module').then(m => m.ContractsModule),
                title: "Projects",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient]}
            },
            {
                'path': 'grants',
                'loadChildren': () => import('./pages/default/grants/grants.module').then(m => m.GrantsModule),
                title: "Grants",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium], description: EntityDescriptions.Grants}
            },
            {
                'path': 'applications',
                'loadChildren': () => import('./pages/default/grants/grants.module').then(m => m.GrantsModule),
                title: "Applications",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientFreemium], description: EntityDescriptions.Applications}
            },
            {
                'path': 'contractors',
                'loadChildren': () => import('./pages/default/contractors/contractors.module').then(m => m.ContractorsModule),
                title: "Manage Contractors",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Contractors}
            },
            {
                'path': 'quotes',
                'loadChildren': () => import('./pages/default/quotes/quotes.module').then(m => m.QuotesModule),
                title: "Quotes",
            },
            {
                'path': 'training',
                title: "Training",
                'loadChildren': () => import('./pages/default/training/training.module').then(m => m.TrainingModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium]}
            },
            {
                'path': 'library',
                title: "Document Library",
                'loadChildren': () => import('./pages/default/library/library.module').then(m => m.LibraryModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Documents}
            },
            {
                'path': 'repository',
                title: "Repository",
                'loadChildren': () => import('./pages/default/documentrepository/documentrepository.module').then(m => m.DocumentRepositoryModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Documents}
            },
            {
                'path': 'training',
                title: "Training",
                'loadChildren': () => import('./pages/default/training/training.module').then(m => m.TrainingModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBody]}
            },
            {
                'path': 'user',
                title: "User",
                'loadChildren': () => import('./pages/default/user/user.module').then(m => m.UserModule),
            },
            {
                'path': 'delegation',
                title: "Delegation",
                'loadChildren': () => import('./pages/default/delegation/delegation.module').then(m => m.DelegationModule),
            },
            {
                'path': 'users',
                title: "Manage Users",
                'loadChildren': () => import('./pages/default/admin/users/users.module').then(m => m.UsersModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Users}
            },            
            {
                'path': 'prefix',
                title: "Funding Streams",
                'loadChildren': () => import('./pages/default/admin/prefixes/prefixes.module').then(m => m.PrefixesModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBodyRecipient,OrganisationTenancyType.FundingBody]}
            },            
            {
                'path': 'import',
                title: "Data Import",
                'loadChildren': () => import('./pages/default/admin/import/import.module').then(m => m.ImportModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Import}
            } ,
            {
                'path': 'export',
                title: "Data Export",
                'loadChildren': () => import('./pages/default/admin/export/export.module').then(m => m.ExportModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Export}
            } ,
            {
                'path': 'businessunits',
                title: "Business Units",
                'loadChildren': () => import('./pages/default/admin/businessunits/businessunits.module').then(m => m.BusinessUnitsModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.BusinessUnits}
            } ,
            {
                'path': 'departments',
                title: "Departments",
                'loadChildren': () => import('./pages/default/admin/departments/departments.module').then(m => m.DepartmentsModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Departments}
            } ,
            {
                'path': 'organisations',
                title: "Organisations",
                'loadChildren': () => import('./pages/default/admin/organisations/organisations.module').then(m => m.OrganisationsModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Organisations}
            } ,
            {
                'path': 'terminologies',
                title: "Terminology",
                'loadChildren': () => import('./pages/default/admin/terminologies/terminologies.module').then(m => m.TerminologiesModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Terminology}
            },
            {
                'path': 'formsconfiguration',
                title: "Template Builder",
                'loadChildren': () => import('./pages/default/admin/formsconfiguration/formsconfiguration.module').then(m => m.FormsConfigurationModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.FormsConfiguration}
            },
            {
                'path': 'reviewcriteria',
                title: "Review Criteria",
                'loadChildren': () => import('./pages/default/admin/reviewcriteria/reviewcriteria.module').then(m => m.ReviewCriteriaModule),
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.ReviewCriteria}
            },
            {
                'path': 'fundingprograms',
                'loadChildren': () => import('./pages/default/fundingprograms/fundingprograms.module').then(m => m.FundingProgramsModule),
                title: "Funding Programs",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.FundingPrograms}
            },
            {
                'path': 'opportunities',
                'loadChildren': () => import('./pages/default/opportunities/opportunities.module').then(m => m.OpportunitiesModule),
                title: "Funding Opportunities",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.FundingOpportunities}
            },
            {
                'path': 'recipients',
                'loadChildren': () => import('./pages/default/recipientusers/recipientusers.module').then(m => m.RecipientUsersModule),
                title: "Recipient Users",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Recipients}
            },
            {
                'path': 'dashboard',
                'loadChildren': () => import('./pages/default/dashboard/homepage.module').then(m => m.HomepageModule),
                title: "Homepage",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.RecipientFreemium,OrganisationTenancyType.RecipientPremium,OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], currentPage:'dashboard'}
            },
            {
                'path': 'funding-projects',
                'loadChildren': () => import('./pages/default/fundingprojects/fundingprojects.module').then(m => m.FundingProjectsModule),
                title: "Projects",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient], description: EntityDescriptions.Projects}
            },
            {
                'path': 'analytics',
                'loadChildren': () => import('./pages/default/analytics/analytics.module').then(m => m.AnalyticsModule),
                title: "Analytics",
                'canActivate': [TenancyGuard],
                data: {tenancy: [OrganisationTenancyType.FundingBody,OrganisationTenancyType.FundingBodyRecipient,OrganisationTenancyType.RecipientPremium], description: EntityDescriptions.Analytics}
            },
        ],
    },
    { 
        'path': 'maintenance', 
        'component': MaintenanceComponent 
    },
     {
        'path': '**',
        'redirectTo': 'index',
        'pathMatch': 'full',
    },
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule { }
