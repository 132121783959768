
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AccountsService } from '@app/services/accounts.service';
import { Subject } from 'rxjs';
import { UserProfileModel } from '@app/models/userprofilemodel';
import { TerminologytranslatorService } from '@app/services/TerminologyTranslator.service';

declare let mLayout: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    styleUrls: ['./aside-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {

    private componentDestroyed$: Subject<boolean> = new Subject();
    myUserProfile: UserProfileModel = null;
    porfolioModuleDefault: string = "ganttview";
    @ViewChild('sidebarTraining', { static: false }) sidebarTraining!: ElementRef;
    @ViewChild('sidebarDocumentManagement', { static: false }) sidebarDocumentManagement!: ElementRef;
    @ViewChild('sidebarAdministration', { static: false }) sidebarAdministration!: ElementRef;
    @ViewChild('sidebarData', { static: false }) sidebarData!: ElementRef;
    @ViewChild('sidebarHelp', { static: false }) sidebarHelp!: ElementRef;
    @ViewChild('sidebarPortfolio', { static: false }) sidebarPortfolio!: ElementRef;
    @ViewChild('sidebarPortfolioStandard', { static: false }) sidebarPortfolioStandard!: ElementRef;
    @ViewChild('sidebaPortfolioDelivery', { static: false }) sidebaPortfolioDelivery!: ElementRef;
    @ViewChild('sidebaProjectDelivery', { static: false }) sidebaProjectDelivery!: ElementRef;
    @ViewChild('sidebaContractDelivery', { static: false }) sidebaContractDelivery!: ElementRef;
    @ViewChild('sidebaGrantManagement', { static: false }) sidebaGrantManagement!: ElementRef;
    @ViewChild('sidebarContractors', { static: false }) sidebarContractors!: ElementRef;
    activeOrganisation:string = '';

    constructor(  public terminologytranslatorService: TerminologytranslatorService)
    {
    }
    ngOnInit() {
        this.myUserProfile = JSON.parse(sessionStorage.getItem("myUserProfile"))
        this.activeOrganisation = sessionStorage.getItem("organisationTenancyType")
        if(this.myUserProfile.portfolioModuleEnabled == true)
        {
            this.porfolioModuleDefault = "portfolios";
        }
        this.runAfterLoad()
    }

    showItem(x:string[]){
        if(this.activeOrganisation == "FundingBody" && x.includes("FundingBody")){
            return true
        }
        if(this.activeOrganisation == "FundingBodyRecipient" && x.includes("FundingBodyRecipient")){
            return true
        }
        if(this.activeOrganisation == "RecipientFreemium" && x.includes("RecipientFreemium")){
            return true
        }
        if(this.activeOrganisation == "Standard" && x.includes("Standard")){
            return true
        }
        if(x.includes("all")){
            return true
        }
        return false
    }

    getViewChildAssociate(index: number){
        switch(index) {
            case 1:
                return this.sidebarTraining.nativeElement.querySelector(".collapse")
            case 2:
                return this.sidebarDocumentManagement.nativeElement.querySelector(".collapse")
            case 3:
                return this.sidebarAdministration.nativeElement.querySelector(".collapse")
            case 4:
                return this.sidebarData.nativeElement.querySelector(".collapse")
            case 5:
                return this.sidebarHelp.nativeElement.querySelector(".collapse")
            case 6:
                return this.sidebarPortfolio.nativeElement.querySelector(".collapse")
            case 7:
                return this.sidebarPortfolioStandard.nativeElement.querySelector(".collapse")
            case 8:
                return this.sidebaPortfolioDelivery.nativeElement.querySelector(".collapse")
            case 9:
                return this.sidebaProjectDelivery.nativeElement.querySelector(".collapse")
            case 10:
                return this.sidebaContractDelivery.nativeElement.querySelector(".collapse")
            case 11:
                return this.sidebaGrantManagement.nativeElement.querySelector(".collapse")
            case 12:
                return this.sidebarContractors.nativeElement.querySelector(".collapse")
            default:
              return false
          }
    }

    toggleChildItem(index:number){
        var element = this.getViewChildAssociate(index)

        if(element.classList.contains("show")){
            element.classList.remove('show');
        }else{
            this.removeAllActiveSubMenu()
            element.classList.add('show');
        }
    }

    toggleChildItemOfChild(index:number){
        var element = this.getViewChildAssociate(index)

        if(element.classList.contains("show")){
            element.classList.remove('show');
        }else{
            element.classList.add('show');
        }
    }

    changeSideCondensed(){
        var element = document.getElementsByTagName("body");
        if(element[0].hasAttribute("data-leftbar-compact-mode")){
            element[0].removeAttribute("data-leftbar-compact-mode");
        }
        else{
            element[0].setAttribute("data-leftbar-compact-mode", "condensed");
        }
    }

    removeAllActiveSubMenu(){
        var element = document.getElementsByClassName("collapse");
        for (var i = 0; i < element.length; i++) {
            element[i].classList.remove("show");
        }
        var element2 = document.getElementsByClassName("side-nav-link");
        for (var i = 0; i < element2.length; i++) {
            element2[i].setAttribute("aria-expanded", "false");
            element2[i].classList.add("collapsed");
        }
    }

    runAfterLoad(){
        setTimeout(() => {
            $(".side-nav > .side-nav-item:not(:has(.collapse)) > a.side-nav-link:not(.collapsed)").on( "click", () => {
                this.removeAllActiveSubMenu()
            });

            $(window).on('resize',function() {
            var $window = $(window)
            var $html = $('body');
            if ($window.width() < 768) {
                    $html.attr('data-leftbar-compact-mode','condensed');
                }
                else{
                    $html.removeAttr('data-leftbar-compact-mode');
                }
            });
        }, 100);
    }

    ngAfterViewInit() {
        mLayout.initAside();

        var elements = $(".m-menu__toggle");

        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', this.subMenuClick, false);
        }
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    getAsset(file) {
        var url = ('/assets/training/' + file);
        window.open(url);
    }

    subMenuClick(e): void {
        var item = e ? $(e.currentTarget) : $(this);
        var li = item.closest('li');
        var submenu = li.children('.m-menu__submenu, .m-menu__inner');

        if (item.closest('.m-menu__item').hasClass('m-menu__item--open-always')) {
            return;
        }

        if (submenu.length > 0) {
            e.preventDefault();
            var speed = 200;
            var hasClosables = false;

            if (li.hasClass('m-menu__item--open') === false) {
                // hide other accordions
                var closables = item.closest('.m-menu__nav, .m-menu__subnav').find('> .m-menu__item.m-menu__item--open.m-menu__item--submenu:not(.m-menu__item--expanded):not(.m-menu__item--open-always)');
                closables.each(function () {
                    $(this).children('.m-menu__submenu').slideUp(speed, function () {
                        //Plugin.scrollToItem(item);
                    });
                    $(this).removeClass('m-menu__item--open');
                });

                if (closables.length > 0) {
                    hasClosables = true;
                }

                if (hasClosables) {
                    submenu.slideDown(speed, function () {
                        //Plugin.scrollToItem(item);
                    });
                    li.addClass('m-menu__item--open');
                } else {
                    submenu.slideDown(speed, function () {
                        //Plugin.scrollToItem(item);
                    });
                    li.addClass('m-menu__item--open');
                }
            } else {
                submenu.slideUp(speed, function () {
                    //Plugin.scrollToItem(item);
                });
                li.removeClass('m-menu__item--open');
            }
        }
    }
    

}