import { Component, Input, OnInit } from '@angular/core';
import { ChartsService } from '@app/services/charts.service';
import { Chart } from 'chart.js';
//checkout ngx - charts


@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
})
export class ChartComponent implements OnInit {


    @Input() id: string;            // Chart Id
    @Input() type: string = '';     // Contract, Project etc.
    @Input() source: string = '';   // ContractId, ProjectId etc.

    chartData: {};
    chart = [];
    chartId = null;
    _chartId = null;
    myChart: Chart = null;


    constructor(
        private chartsService: ChartsService,
    ) {
    }



    ngOnInit() {

        this._chartId = this.GetRandomId();
        this.chartId = this.id;
        this.getData();

    }

    ngOnChanges() {

        this.chartId = this.id;

    }


    ngAfterViewInit() {

        this.drawChart();

    }






    getData() {

        if (this.id !== null) {

            if (this.type.length > 0 && this.chartId.length > 0) {
                this.chartsService.getChartByTypeAndId(this.id, this.type, this.source).subscribe(dbData => {
                    this.chartData = dbData;
                    this.drawChart();
                });

            }

            else {
                this.chartsService.getChart(this.chartId).subscribe(dbData => {
                    this.chartData = dbData;
                    this.drawChart();
                });

            }
        }

    }







    GetRandomId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    };







    drawChart() {

        var canvas = <HTMLCanvasElement>document.getElementById(this._chartId);

        if (canvas !== null) {

            var ctx = canvas.getContext("2d");
           // this.myChart = new Chart(ctx, this.chartData);

        };
    }








}
