<div class="leftside-menu">
    <a href="javascript:void(0)" class="logo text-center logo-light">
        <span class="logo-lg">
            <img src="assets/app/media/img/Surepact_Logo-Reversed.svg" alt="" height="35">
            <button class="button-menu-mobile open-left n-brand__icon" (click)="changeSideCondensed()">
                <i class="icon-funder-chevron-contract-horizontal" aria-hidden="true"></i>
            </button>
        </span>
        <span class="logo-sm button-menu-mobile open-left" (click)="changeSideCondensed()">
            <img src="assets/app/media/img/surepact-logo-sm.png" alt="" height="35">
        </span>
    </a>
    <div class="lds-dual-ring" *ngIf="myUserProfile==null"></div>
    <div id="leftside-menu-container" data-simplebar *ngIf="myUserProfile">
        <ul class="side-nav side-nav-top">

            <li class="side-nav-item " routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/dashboard" class="side-nav-link">
                    <i class="icon-funder-home-plus" aria-hidden="true"></i>
                    <span> Homepage </span>
                </a>
            </li>
            
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active"  [ngClass]="{ 'disabled-link': true }">
                <i class="fa fa-bolt color-yellow-left" aria-hidden="true"></i>
                <a routerLink="/{{porfolioModuleDefault}}" class="side-nav-link">
                    <i class="icon-funder-layers-3" aria-hidden="true"></i>
                    <span> Portfolios </span>
                    <i class="icon-funder-lock side-right" aria-hidden="true"></i>
                </a>
            </li>
            
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active"  [ngClass]="{ 'disabled-link': true }">
                <i class="fa fa-bolt color-yellow-left" aria-hidden="true"></i>
                <a routerLink="/projects" class="side-nav-link">
                    <i class="icon-funder-add-layer" aria-hidden="true"></i>
                    <span> Projects </span>
                    <i class="icon-funder-lock side-right " aria-hidden="true"></i>
                </a>
            </li>
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/opportunities" class="side-nav-link">
                    <i class="icon-funder-bullseye" aria-hidden="true"></i>
                    <span> Funding Opportunities </span>
                </a>
            </li>

            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/applications" class="side-nav-link">
                    <i class="icon-funder-list-unchecked" aria-hidden="true"></i>
                    <span> Applications </span>
                </a>
            </li>


            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active"  [ngClass]="{ 'disabled-link': true }">
                <i class="fa fa-bolt color-yellow-left" aria-hidden="true"></i>
                <a routerLink="/assets" class="side-nav-link ">
                    <i class="icon-funder-pantone" aria-hidden="true"></i>
                    <span> Assets </span>
                    <i class="icon-funder-lock side-right" aria-hidden="true"></i>
                </a>
                
            </li>
             

            <li class="seperation-line-li" *ngIf="showItem(['all'])">
                <div class="seperation-line"></div>
            </li>
        </ul>

 
        <ul class="side-nav side-nav-bottom">
            <li class="seperation-line-li" *ngIf="showItem(['FundingBody'])">
                <div class="seperation-line"></div>
            </li>
            <li> 
                <div class="help-box text-white text-center">
                    <a href="javascript: void(0);" class="float-end close-btn text-white">
                        <i class="mdi mdi-close"></i>
                    </a>
                    <h4 class="box-header"><i class="fa fa-bolt color-yellow-normal" aria-hidden="true"></i> Upgrade Premium</h4>
                    <p class="box-body">Unlimited access to useful features</p>
                    <button class="btn btn-sm background-yellow">Upgrade Now</button>
                </div>
            </li>

          
            <li class="side-nav-item menuitem" *ngIf="showItem(['all'])">
                <a href="https://surepact.com/privacy-policy/" target="_blank" class="side-nav-link">
                    <i class="icon-funder-lock" aria-hidden="true"></i>
                    <span> Privacy Policy </span>
                </a>
            </li>

        </ul>

      
    </div>
</div>
