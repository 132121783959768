
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from "@app/auth/_services/auth0.service";
import { BusinessUnitModel } from '@app/models/BusinessUnitModel';
import { DepartmentModel } from '@app/models/DepartmentModel';
import { ModulePermissionsModel } from '@app/models/ModulePermissionsModel';
import { RoleModel } from '@app/models/RoleModel';
import { UserModel } from '@app/models/UserModel';
import { UserRoleModel } from '@app/models/UserRoleModel';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { UserProfileModel } from '@app/models/userprofilemodel';

const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AccountsService {
    handleError(arg0: any): any {
        throw new Error("AccountsService Method not implemented.");
    }

    private cacheUserProfilePicUrl = new BehaviorSubject<string>('');
    cacheUserProfilePicUrl$: Observable<string> = this.cacheUserProfilePicUrl.asObservable();

    private cacheOrganisationLogoPicUrl = new BehaviorSubject<string>('');
    cacheOrganisationLogoPicUrl$: Observable<string> = this.cacheOrganisationLogoPicUrl.asObservable();

    private cacheUserFullName = new BehaviorSubject<string>('');
    cacheUserFullName$: Observable<string> = this.cacheUserFullName.asObservable();


    private cacheUser = new BehaviorSubject<UserModel>(null);
    cacheUser$: Observable<UserModel> = this.cacheUser.asObservable();


    private cacheUsers$: Observable<Array<UserModel>>;
    private reload$ = new Subject<void>();


    private ProfileUrl: any;
    private OrganisationLogoUrl: any ;
    private departments: Observable<DepartmentModel[]>;
    private roles: Observable<RoleModel[]>;
    private users: Observable<UserModel[]> ;
    private businessUnits: Observable<BusinessUnitModel[]>;
    private allUsers: Observable<UserModel[]> ;
    private recipientUsers: Observable<UserModel[]> ;
    private recipientUsersWithPhoto: Observable<UserModel[]> ;
    private fundingBodies: Observable<UserModel[]> ;
    private myUser: Observable<UserModel>

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {
      this.ProfileUrl = this.userProfilePictureURL();
      this.OrganisationLogoUrl = this.userOrganisationPictureURL();
      this.roles = this.httpclient.get<any>('/api/v1/accounts/roles')
      .pipe(map(res => res));
      this.departments = this.httpclient.get<any>('/api/v1/accounts/departments')
      .pipe(map(res => res));
      this.businessUnits = this.httpclient.get<any>('/api/v1/accounts/businessunits')
      .pipe(map(res => res));
      this.users = this.httpclient.get<any>('/api/v1/accounts/users')
      .pipe(map(res => res));
       this.allUsers = this.httpclient.get<any>('/api/v1/accounts/users?includeInactive=true')
      .pipe(map(res => res));
       this.recipientUsers = this.httpclient.get<any>('/api/v1/accounts/users?includeInactive=true&isRecipientProcess=true')
      .pipe(map(res => res));
      this.recipientUsersWithPhoto = this.httpclient.get<any>('/api/v1/accounts/users?includeInactive=true&isRecipientProcess=true&gatherDisplayPhoto=true')
     .pipe(map(res => res));
      this.fundingBodies = this.httpclient.get<any>('/api/v1/accounts/users?isFunderBodyProcess=true')
      .pipe(map(res => res));
    }



  public getBusinessUnits(): Observable<BusinessUnitModel[]> {
      return this.businessUnits.pipe(shareReplay(1));
  }


    public getDepartments(): Observable<DepartmentModel[]> {
        return this.departments.pipe(shareReplay(1));
    }

    public getUsers(includeInactive: boolean = false): Observable<UserModel[]> {
        return includeInactive
            ? this.allUsers.pipe(shareReplay(1))
            : this.users.pipe(shareReplay(1));
    }

    public getRoles(): Observable<RoleModel[]> {
        return this.roles.pipe(shareReplay(1));
    }

    public deleteUser(id:string): Observable<UserModel> {
        return this.httpclient.delete<any>('/api/v1/accounts/users/delete/' + id)
            .pipe(map(res => res))
    }

    public getUserRoles(id): Observable<UserRoleModel> {
        return this.httpclient.get<any>('/api/v1/accounts/' + id + '/roles')
            .pipe(map(res => res))
    }

    public updateUserRoles(id, formData) {
        return this.httpclient.post('/api/v1/accounts/' + id + '/roles', formData).subscribe(
            res => {
                this.toastr.success('User Roles Updated!');

            },
            err => {
                // this.toastr.error('Error updating User Roles!');
            }
        );
    }

    public getRecipientUsersWithPhoto(): Observable<UserModel[]>  {
        return this.recipientUsersWithPhoto.pipe(shareReplay(1));
    }

    public getRecipientUsers(): Observable<UserModel[]>  {
        return this.recipientUsers.pipe(shareReplay(1));
    }

    public getFundingBodies(): Observable<UserModel[]>  {
        return this.fundingBodies.pipe(shareReplay(1));
    }

    userProfilePictureURL() {

        let objectUrl: string = null;

        return this.httpclient.get('/api/v1/images/profile', { responseType: "blob" })
            .subscribe(m => {
                objectUrl = URL.createObjectURL(m);
                this.cacheUserProfilePicUrl.next(objectUrl);
                return objectUrl;
            });

    }

    public getModulePermissions(): Observable<ModulePermissionsModel> {
        return this.httpclient.get<any>('/api/v1/accounts/modulePermissions/')
            .pipe(map(res => res))
    }
 
    getUserProfilePictureURL(id): any {

        return this.httpclient.get('/api/v1/images/profile/' + id , { responseType: "blob" })
        .pipe(map(res => res));
    }

    userOrganisationPictureURL() {

        let objectUrl: string = null;

        return this.httpclient.get('/api/v1/images/logo', { responseType: "blob" })
            .subscribe(m => {
                objectUrl = URL.createObjectURL(m);
                this.cacheOrganisationLogoPicUrl.next(objectUrl);
                return objectUrl;
            });

    }

    // Gets user details
    get User(): Observable<UserModel> {
        if (!this.cacheUser$) {

            // wait for Auth) to populate local items.
            this.cacheUser$ = this.getUser().pipe(
                shareReplay(CACHE_SIZE)
            );
        }
        return this.cacheUser$;
    }

    getUser(): Observable<any> {
        if(!this.myUser){
            return this.httpclient.get<any>('/api/v1/accounts/myuser')
                .pipe(
                tap(res => {
                    // Updating the UserFullName Cached                 
                    this.cacheUserFullName.next(res.firstname + ' ' + res.lastname);
                    this.myUser = of(res)
                })
            );
        } else {
            return this.myUser
        }
    }

    myProfile(): Observable<any> {
        return this.httpclient.get('/api/v1/accounts/myprofile')
            .pipe(res => res);

    }

    UserClearCache() {
        this.cacheUser$ = null;
        this.User;
    }

    UpdateMyProfle(body): Observable<any> {
        return this.httpclient.post<UserModel>('/api/v1/accounts/myprofie/update', body)
            .pipe(
            tap(p => {
                this.toastr.success('User Details Updated!');

                // Updating the User Cached details
                this.cacheUserFullName.next(p.firstname + ' ' + p.lastname);
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    UpdateRecipientUser(body): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/accounts/users/updaterecipient`, body)
            .pipe(
            tap(p => {
                this.toastr.success('Users Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    AddRecipientUser(body): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/accounts/users/addrecipient`, body)
            .pipe(
            tap(p => {
                this.toastr.success('Users Added!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    UpdateUser(body, isRecipientProcess : boolean): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/accounts/users/update?isRecipientProcess=${isRecipientProcess}&isRecipientProcess=${isRecipientProcess}&includeInactive=true`, body)
            .pipe(
            tap(p => {
                this.toastr.success('Users Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    AddUser(body, isRecipientProcess : boolean): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/accounts/users/add?isRecipientProcess=${isRecipientProcess}&isRecipientProcess=${isRecipientProcess}&includeInactive=true`
        ,body)
            .pipe(
            tap(p => {
                this.toastr.success('Users Added!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getDefaultMembers(): Observable<UserModel[]> {
        return this.httpclient.get<any>(`/api/v1/Accounts/members/default`)
            .pipe(map(res => res))
    }

    async setActiveOrganisation(){
        await firstValueFrom(this.myProfile()).then((res:UserProfileModel) => {
            const myUserProfile = res;
            sessionStorage.setItem('myUserProfile', JSON.stringify(myUserProfile));
            sessionStorage.setItem("organisationTenancyType",myUserProfile.organisationTenancyType);
        },
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }
    
    public acceptEULA(id, optInToMarketing): Observable<UserModel[]> {
        return this.httpclient.post<any>(`/api/v1/Accounts/users/accepteula/${id}/${optInToMarketing}`,{})
            .pipe(map(res => res))
    }

    errorHandler(error: any): void {
    }
}
