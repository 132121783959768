import { throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BusinessUnitModel } from '@app/models/BusinessUnitModel';


@Injectable()
export class BusinessUnitsService {
  constructor(private httpclient: HttpClient,
    private toastr: ToastrService) {
  }

  compareFn = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase())
      return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase())
      return 1;
    return 0;
  };

  public getAllBusinessUnits(): Observable<BusinessUnitModel[]> {
    return this.httpclient.get<any>(`/api/v1/businessunits`)
      .pipe(map(res => res.sort(this.compareFn)))
  }

  public addOrUpdateBusinessUnit(businessUnit, isUpdated): Observable<BusinessUnitModel> {
    return this.httpclient.post<any>('/api/v1/businessunits', businessUnit)
      .pipe(
        tap(c => {
          if (isUpdated)
            this.toastr.success('Business Unit: ' + c.name + ' Updated!');
          else
            this.toastr.success('Business Unit: ' + c.name + ' Added!');
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
      );
  }

  public deleteBusinessUnit(id): Observable<any> {
    return this.httpclient.delete(`/api/v1/businessunits/${id}`)
        .pipe(
        tap(c => {
            this.toastr.success("Business Unit deleted", "Deleted");
        }),
        catchError((err: any) => {        
            return observableThrowError(this.errorHandler(err))
        }));
}

  errorHandler(error: any): void {  
  }
}
