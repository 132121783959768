
import {of as observableOf,  Observable ,  timer } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { AUTH_CONFIG } from './auth0-variables';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { mergeMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LoggingService } from '@app/services/logging.service';
import { UserProfileModel } from '@app/models/userprofilemodel';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, PopupRequest, RedirectRequest } from '@azure/msal-browser';



@Injectable()
export class AuthService {
    refreshSubscription: any;
    auth0 = new auth0.WebAuth({
        clientID: AUTH_CONFIG.clientID,
        domain: AUTH_CONFIG.domain,
        responseType: 'token id_token',
        audience: `https://${AUTH_CONFIG.domain}/userinfo`,
        redirectUri: window.location.origin + '/login',
        scope: 'openid profile' 
    });

    myUserProfile: UserProfileModel = null;

    constructor(public router: Router, private loggingService: LoggingService,   
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,        
        private AADAuthService: MsalService,) { }

    public login(): void {
        this.auth0.authorize();
    }

    public aadLogin(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.authRequest) {
            this.AADAuthService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    localStorage.setItem('id_token', response.accessToken);
                    localStorage.setItem('expires_at', response.expiresOn.getTime().toString());
                    this.AADAuthService.instance.setActiveAccount(response.account);
                    this.loggingService.addUserLog();
                });
        } else {
            this.AADAuthService.loginPopup(userFlowRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.AADAuthService.instance.setActiveAccount(response.account);
                });
        }
    }

    public setToken (token , validTo) {
        localStorage.setItem('id_token', token);
        localStorage.setItem('access_token', token);
        localStorage.setItem('authProvider',"undefined");   
        const expiresAt = JSON.stringify((9999 * 1000) + new Date().getTime());     
        localStorage.setItem('expires_at', expiresAt);

    }

    public handleAuthentication(): void {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
        
                if(!authResult.idTokenPayload.name.includes('surepact') && environment.showMaintenance)
                {
                    this.router.navigate(['/maintenance']);
                }
                else{
                    this.setSession(authResult);
                    this.loggingService.addUserLog();
                }
             
            } else if (err) {
                this.router.navigate(['/home']);
                alert(`Error: ${err.error}. Check the console for further details.`);
            }
        });
    }

    private setSession(authResult): void {
        // Set the time that the access token will expire at
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        //localStorage.setItem('user_id', authResult.u);
      this.auth0.client.userInfo(authResult.accessToken, function(err, user) {

            // isntead of doing this do a call to api/v1/accounts/myprofile and get the user_id this way ([useroid])
            localStorage.setItem('user_id', user['https://api.surepact.com/surepactid']);

        });
        this.scheduleRenewal();

    }

    public renewToken(): void {
        this.auth0.checkSession({}, (err, result) => {
       
            if (!err) {
                this.setSession(result);
            }
        });
    };

    public scheduleRenewal() {
        if (!this.isAuthenticated()) { return; }
        this.unscheduleRenewal();

        const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));

        const expiresIn$ = observableOf(expiresAt).pipe(
            mergeMap(
                expiresAt => {
                    const now = Date.now();
                    // Use timer to track delay until expiration
                    // to run the refresh at the proper time
                    return timer(Math.max(1, expiresAt - now));
                }
            )
        );
        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSubscription = expiresIn$.subscribe(
            () => {
                this.renewToken();
                this.scheduleRenewal();
            }
        );
    }

    public unscheduleRenewal() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }
    public logout(): void {
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_id');
        localStorage.removeItem('pendo_data');
        // Go back to the home route
        this.router.navigate(['/login?returnUrl=%2Findex']);
    }

    public isAuthenticated(): boolean {

        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

}
