//place future contants class here
export abstract class CharacterKeyCodes{
    public static backSpaceCode = 8;  
    public static  arrowKeyStartRangeCode = 37;
    public static  arrowKeyEndRangeCode = 40;
}
export abstract class SessionCode {
    public static eulaAccepted='eulaAccepted';
    public static yes='yes';
    public static no='no';
}
export abstract class DocumentTypes {
    public static eots = 'eots';
    public static internalDeliveryTrackings = 'internalDeliveryTrackings';
    public static grantTasks = 'granttasks';
    public static progressClaims = 'progressClaims';
    public static plannedActivities = 'plannedActivities';
    public static acquittals = 'acquittals';
    public static variations = 'variations';
    public static defects = 'defects';
    public static activities = 'activities';
    public static milestones = 'milestones';
}

export abstract class AssessableEntityEntityTypes {
    public static portfolios = 'portfolios';
    public static projects = 'projects';
    public static contracts = 'contracts';
    public static grants = 'grants';
    public static applications = 'applications';
    public static fundingprograms = 'fundingprograms';
}

export abstract class EntityTypes {
    public static portfolio = 'portfolio';
    public static project = 'project';
    public static contract = 'contract';
    public static contractAward = 'contractAward';
    public static grant = 'grant';
    public static application = 'application';
    public static businessCase = 'businessCase';
    public static expressionOfInterest = 'expressionOfInterest';
}

export abstract class FundingProgamAssessmentType
{
    public static allocationRecipient = "allocationrecipient";
    public static businessCaseResponse = "businesscaseresponse";
    public static businessCaseResponseCriteria = "businesscaseresponsecriteria";
    public static expressionOfInterestResponse = "expressionofinterestresponse";
    public static expressionOfInterestResponseCriteria = "expressionofinterestresponsecriteria";
    public static fundingAgreementResponse = "fundingagreementresponse";
    public static fundingAgreementSubmittedToRecipient = "fundingagreementsubmittedtorecipient";
    public static fundingAgreementRequestApproval = "fundingagreementrequestapproval";
    public static fundingAgreementRequestApproved = "fundingagreementrequestapproved";
    public static fundingAgreementReceived= "fundingagreementrequestreceived";
    public static fundingAgreementRequestRejected= "fundingagreementrequestrejected";
}

export abstract class EntityTypeTitle {
    public static portfolio = 'Portfolio';
    public static project = 'Project';
    public static contract = 'Contract';
    public static grant = 'Grant';
    public static expressionOfInterest = 'Expression of Interest';
    public static businessCase = 'Business Case';
}

export abstract class SummaryTableActions{
    public static updating = 'updating';
    public static archiving = 'archiving';
}

export abstract class DeliveryTrackingEntityTypes {
    public static milestones = 'milestones';
    public static plannedactivities = 'plannedactivities';
    public static acquittals = 'acquittals';
    public static progressclaims = 'progressclaims';
    public static defects = 'defects';
    public static internalDeliveryTracking = 'internalDeliveryTracking';
    public static opportunityProjects = 'opportunityProjects';
}

export abstract class WorkflowDefaultTypes {
    public static grant = 'Grant';
    public static fundingProgram = 'FundingProgram';
}

export const DefaultColDef = {    
        sortable: true,
        resizable: true  
};

export abstract class TureORFalseInNum {    
    public static trueVal = 1;
    public static falseVal = 0;
};

export abstract class FormConfigurationTypes {
    public static fundingProgram = 'Funding Program';
    public static expressionOfInterest = 'Expression Of Interest';
    public static businessCase = 'Business Case';
    public static fundingAgreement = 'Funding Agreement';
}

export abstract class RecipientStatuses {
    public static Submitted = "Submitted";
    public static Received = "Received";
    public static Opened = "Opened";
    public static Responded = "Responded";
    public static Appealed = "Appealed";
    public static Approved = "Approved";
    public static Rejected = "Rejected";
    public static Shortlisted = "Shortlisted";
    public static Signed = "Signed";
    public static Sent = "Sent";
    public static ChangeRequest = "Change Request";
    public static NotStarted = "Not Started";
    public static Resubmit = "Re-submit";
}

export abstract class TemplateStatuses{
    public static ReadyToUse = "Ready to Use"
    public static Draft = "Draft"
}

export abstract class ResponseState
{
   public static SaveChanges = "saveChanges";   
}

export abstract class ResponseEmitterValues
{
    public static EOIResponse = "EOIResponse"; 
    public static BCResponse = "BCResponse";    
    public static RecipientBusinessCase = "RecipientBusinessCase";
}

export abstract class FormDefaultField{
    public static Signature = "Signature"
}

export abstract class EntityWorkflowMenu{
    public static InformationId = 'information';
    public static ManagementId = 'management';
    public static InformationName = 'information';
    public static ManagementName = 'management';
    public static InformationDescription = 'Information';
    public static ManagementDescription = 'Management';
    public static RecipientFundingAgreement = 'Agreement';
}
    //TODO if Business case need special status then add new static class

export abstract class ProjectAndContractValidationMessage {
    public static estimatedBuget = 'The contract budget is greater than the project budget. A project budget variation will be required.';
    public static estimatedDate = 'The contract dates are outside the date range of the project. A project dates variation will be required.';
    public static estimatedDateAndEstimatedBuget = 'The contract budget and dates are in conflict with the project budget and dates. Variation to the project budget and dates will be required.';
    public static contractAwardValueGreaterThanContractBudget = 'The contract award value is greater that the contract budget. The award will be saved and not committed. Do you want to update the contract budget?';
}

export abstract class EmulateCondition {
    public static Unassigned = "Unassigned"
}


export abstract class OrganisationTenancyType
{
    public static RecipientPremium = "RecipientPremium"; 
    public static FundingBodyRecipient = "FundingBodyRecipient";    
    public static RecipientFreemium = "RecipientFreemium"; 
    public static FundingBody = "FundingBody";    
}

export abstract class EntityDescriptions
{
    public static signup = "Registration Form";
    public static FundingOpportunities = "Here are the available Funding Opportunities.";
    public static Projects = "Here are the Projects you're working on."
    public static Grants = "Here are the Grants you're working on."
    public static Applications = "Here are the Applications you're working on.";
    public static Assets = "These are the Assets connected to the work you're doing.";
    public static FundingPrograms = "These are the Funding Programs you're a member of.";
    public static Analytics = "Discover insights of your SurePact data.";
    public static Portfolios = "Here are the Portfolios you're a member of.";
    public static Documents = "Manage all documents uploaded to SurePact.";
    public static Users = "Manage users for your organisation.";
    public static BusinessUnits = "Manage the business units within your organisation.";
    public static Departments = "Manage the departments within your organisation.";
    public static Organisations = "Manage the organisations you want to use in SurePact.";
    public static Recipients = "Manage the recipient organisations you want to use in SurePact.";
    public static Contractors = "Manage the contractors you want to use in SurePact.";
    public static FormsConfiguration = "Add and manage forms and templates.";
    public static ReviewCriteria = "Create and group review criteria for your funding programs.";
    public static Terminology = "Define terms and labels in your SurePact instance.";
    public static Import = "Bring data into your SurePact instance.";
    public static Export = "Select data to export from SurePact.";
}

export abstract class SignalrGroupNames{
    public static InformationBar = "information-bar-{id}"; 
}

export abstract class FundingApplicationGroupDescriptions{
    public static Submission = "Welcome to the Submission stage, where you'll find all the required application forms and application updates. Make sure to complete all the required fields, and click the 'Submit' button when ready. Please note, you can only submit once. " +
    "<br/><strong>Important:</strong> Remember to save your changes as you go using the 'Save Changes' button at the bottom of the form. The form won't auto-save.";
    public static FundingAgreement = "Here, you can sign and accept the Funding Agreement. Please review the agreement details, sign in the designated panel, and select 'Accept Agreement' when ready.";
    public static Reporting = "In Reporting, you can create and submit reports to the Funding Body and view a record of the funds disbursed as per your funding agreement. In Projects, you can record basic information about the works carried out for the funding, if required.";
}

export abstract class FundingAgreementMessages{
    public static Disclamer = '<p>SurePact recommends that you seek independent legal advice before drafting or entering any documents including through the SurePact software. SurePact is not a party to any document drafted or executed using the SurePact software.</p>'
                              +'<p>To the fullest extent permitted by law, SurePact expressly disclaims any and all liability for any loss or damage associated or in connection with the drafting or execution of documents using the SurePact software, including in connection with any document executed that is deemed unenforceable or invalid, or from any failure to authenticate the identity or authority of any signatory to execute such documents.</p>'
                              +'<p>SurePact makes no representations and gives no warranties as to the validity or enforceability of any documents prepared using the SurePact software or the validity of any execution performed using the SurePact software.</p>'
    
}

export abstract class DefaultCountry{
    public static Australia = "Australia"; 
}
