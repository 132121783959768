
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, catchError, tap, share, delay } from 'rxjs/operators';

import * as Rx from 'rxjs';


import { AuthService } from "@app/auth/_services/auth0.service";
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { ViewContainerRef } from '@angular/core';





const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class ReportsService {
    handleError(arg0: any): any {
        throw new Error("Reports Method not implemented.");
    }



    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }





    public getDocumentFromURL(url): Observable<any> {

        return this.httpclient.get<any>(url)
            .pipe(map(res => res))

    }


    public setDocumentFromURL(url, formData): Observable<any> {
        return this.httpclient.post<any>(url, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Document Generated!');
                return p;
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }



    errorHandler(error: any): void {
    }
}
