<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin" id="m_login">
    <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
        <div class="m-stack m-stack--hor m-stack--desktop">
            <div class="m-stack__item m-stack__item--fluid">
                <div class="m-login__wrapper">
                    <div class="m-login__logo">
                        <a href="#/">
                            <img src="./assets/app/media/img/Surepact_Logo-Reversed.svg" class="surepact-logo">
                        </a>
                    </div>
                    <div class="m-login__signin">
                        <div class="m-login__head">
                            <p class="side-description">
                                Know it all - from funding to outcome.
                            </p>
                            
                        </div>
                        <form (ngSubmit)="f.form.valid && signin()" #f="ngForm" class="m-login__form m-form" action="">
                            <ng-template #alertSignin></ng-template>
                            <div>
                                <button [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light m-loader-bg': loading}" id="m_login_signin_submit" class="login-button">
                                    Sign In
                                </button>
                            </div>
                            <br/><br/>
                            <div class="row centre m-login__form-sub">
                                <span>
                                    All information, materials and other content (''Content'') you may access through this platform are proprietary Content of SurePact (or its licensors or customers, as applicable) (''Protected Content''). 
                                    You must not copy or extract Protected Content, or otherwise distribute or communicate Protected Content to any third party, unless as expressly authorised by SurePact.
                                    &nbsp;
                                    <span>
                                        <a href="https://surepact.com/privacy-policy/" target="_blank"  class="login-privacy_policy">                                                                           
                                           Privacy Policy
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </form>
                    </div>
                    <div>
                        <br/><br/>
                        <p class="m-login__msg">
                            Supported by: 
                        </p>
                        <a href="https://advance.qld.gov.au/">
                            <img src="./assets/demo/default/media/img/QueenslandGovernment.png" class="partner-logos">
                        </a>
                        <a href="https://advance.qld.gov.au/">
                            <img src="./assets/demo/default/media/img/AdvanceQueensland.png" class="partner-logos margin-top5">
                        </a>
                        <br/><br/>
                        <p class="m-login__msg">Global partner of: </p>
                        <p>
                            <a href="https://www.microsoft.com">
                                <img src="./assets/demo/default/media/img/MicrosoftLogo.png" class="partner-logos">
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="login-bg m-grid__item m-grid__item--fluid ">
        <img src="./assets/app/media/img/bg/vertical-edge.png" class="vertical-edge">
        <div class="m-grid__item m-grid__item--middle space">
        </div>
    </div>
</div>
