
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AccountsService } from '@app/services/accounts.service';
import { NotificationsService } from '@app/services/notifications.service';
import { UserModel } from '@app/models/UserModel';
import { Observable ,  Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NotificationModel } from '@app/models/NotificationModel';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserProfileModel } from '@app/models/UserProfileModel';
import { TerminologytranslatorService } from '@app/services/TerminologyTranslator.service';
import { NavigationHelperService } from '@app/services/navigation.helper.service';

import { SimpleModalService } from "ngx-simple-modal";
import { Optional } from "@angular/core";
import { HeaderStore } from '@app/auth/_helpers/headerstore';
import { SignalRService } from '@app/services/signalr.service';
import Utils from '@app/utils';
import { ChangeDetectorRef } from '@angular/core';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ['./header-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, OnDestroy, AfterViewInit {

    user: {};


    private componentDestroyed$: Subject<boolean> = new Subject();
    
    myUserProfile: UserProfileModel = null;
    usr$: Observable<UserModel> = null;
    isTestSite = false;
    imageUserPic: any;
    imageOrgLogoPic: any;
    fullName: string = 'unknkown';
    //notifications: {};
    notifications: NotificationModel[] = [];
    notifications$: Observable<Array<NotificationModel>>;
    //notificationsUnread: {};
    notificationsUnread: NotificationModel[] = [];

    notificationsUnreadCount: number;

    //forceReload$ = new Subject<void>();
    //update$ = new Subject<void>();


    constructor(
        private signalRService: SignalRService,   
        private accountsService: AccountsService, 
        private notificationsService: NotificationsService, 
        private sanitizer: DomSanitizer, private route: ActivatedRoute, 
        private router: Router,public topMenu:NavigationHelperService, 
        @Optional() public simpleModalService: SimpleModalService,
        public store:HeaderStore,
        private terminologytranslatorService: TerminologytranslatorService,
        private cd: ChangeDetectorRef) 
    {
        this.myUserProfile = JSON.parse(sessionStorage.getItem("myUserProfile"))
        this.isTestSite = this.myUserProfile.isTestSite;
    }

    convertUTCDateToLocalDate(date) {
        if (date == null) {
          return ''
        } else {
          return Utils.convertUTCDateToLocalDate(date)
        }
      }

    ngOnInit() {
        this.accountsService.getUser().pipe(takeUntil(this.componentDestroyed$)).subscribe(usr => {
            this.user = usr;
        });

        // Subscribe to User Profile Picture
        this.accountsService.cacheUserProfilePicUrl$.pipe(takeUntil(this.componentDestroyed$)).subscribe(pic => {
            this.imageUserPic = this.sanitizer.bypassSecurityTrustUrl(pic);
        });

        // Subscribe to Organisation Logo Picture
        this.accountsService.cacheOrganisationLogoPicUrl$.pipe(takeUntil(this.componentDestroyed$)).subscribe(pic => {
            this.imageOrgLogoPic = this.sanitizer.bypassSecurityTrustUrl(pic);
        });


        // Subscribe to Full Name
        this.accountsService.cacheUserFullName$.subscribe(res => {
            this.fullName = res;
        });



        // Subscribe to Notifications
        //this.notifications$ = this.notificationsService.notifications;

      //  this.notificationsService.notifications.pipe(takeUntil(this.componentDestroyed$)).subscribe(notif => {
        this.notificationsService.getNotifications().subscribe(notif => {
            this.notifications = notif;
            this.notificationsUnread = notif.filter(n => n.viewed === false)
            this.notificationsUnreadCount = notif.filter(n => n.viewed === false).length;
        });

        // local subscription
        //this.notifications$.subscribe(not => {
        //    this.notifications = not;
        //    this.notificationsUnread = not.filter(n => n.viewed === false);
        //    this.notificationsUnreadCount = not.filter(n => n.viewed === false).length;

        //})

        this.topMenu.topMenuLinks=[]
        //default link if refreshed
        this.topMenu.topMenuLinks.push({name:'Home',link:'/'})
        //initial state of menu links
        this.topMenu.setTopMenuLinks()
        this.router.events.subscribe((ev) => {
            //on route change
            if (ev instanceof NavigationEnd){
                this.topMenu.setTopMenuLinks()
            }
        });
        this.cd.detectChanges();
    }

    ngAfterViewInit() {
        mLayout.initHeader();
        this.signalRService.hubConnection.on("BroadcastEOIUpdate", () => {  
            console.log('Message Received - updating EOIs!');
            this.store.refreshRequired = true;
        });  

        this.signalRService.hubConnection.on("BroadcastBusinessCaseUpdate", () => {  
            console.log('Message Received - updating BCs!');
            this.store.refreshRequired = true;
        });  
    }

    ngOnDestroy(): void {
        localStorage.setItem('user_id', null);

        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
        this.store.remove();
    }

    onClickNotifications(id) {

        var notif: NotificationModel = {} as NotificationModel;

        var elementPos = this.notifications.map(function(n) { return n.id; }).indexOf(id);
        var notif = this.notifications[elementPos];

        var reference = notif.assessableEntity.linkedReference;
        var source = notif.assessableEntity.linkedSource;

        this.router.navigate([source + '/update/', reference])
    }


    onClickNotificationsAck(notification) {

        // Ack the notification.
        this.notificationsService.ackNotification(notification);


        // Delete from local que - server will update with normal refresh time
        var index: number = this.notificationsUnread.indexOf(notification);
        this.notificationsUnread[index].viewed = true;
        this.notificationsUnreadCount = this.notificationsUnread.filter(n => n.viewed === false).length;




    }




    onClickNotificationsAckAll() {

        // Ack the notifications.
        this.notificationsUnread.forEach((notif) => {

            if (notif.viewed === false) {
                this.onClickNotificationsAck(notif);
            }
        });


    }


    //forceReload() {
    //    this.notificationsService.forceReload();
    //    this.forceReload$.next();
    //}




    initHeader() {
        if (localStorage.getItem("user_id") === null) {
            setTimeout(this.ninitHeader, 1000); // setTimeout(func, timeMS, params...)
        }
        else {
            this.ninitHeader();
        }
    }


    ninitHeader() {
        if (localStorage.getItem('user_id') !== null) {
            //localStorage.setItem('user_id', user['https://api.surepact.com/surepactid']);
            localStorage.setItem('user_id', this.myUserProfile.userId);
        }

        this.accountsService.getUser().subscribe(usr => {
            this.user = usr;
        });

        // Subscribe to User Profile Picture
        this.accountsService.cacheUserProfilePicUrl$.pipe(takeUntil(this.componentDestroyed$)).subscribe(pic => {
            this.imageUserPic = this.sanitizer.bypassSecurityTrustUrl(pic);
        });

        // Subscribe to Organisation Logo Picture
        this.accountsService.cacheOrganisationLogoPicUrl$.pipe(takeUntil(this.componentDestroyed$)).subscribe(pic => {
            this.imageOrgLogoPic = this.sanitizer.bypassSecurityTrustUrl(pic);
        });


        // Subscribe to Full Name
        this.accountsService.cacheUserFullName$.subscribe(res => {
            this.fullName = res;
        });



        // Subscribe to Notifications
        //this.notifications$ = this.notificationsService.notifications;

       // this.notificationsService.notifications.pipe(takeUntil(this.componentDestroyed$)).subscribe(notif => {
        this.notificationsService.getNotifications().subscribe(notif => {
            this.notifications = notif;
            this.notificationsUnread = notif.filter(n => n.viewed === false)
            this.notificationsUnreadCount = notif.filter(n => n.viewed === false).length;
        });

        // local subscription
        //this.notifications$.subscribe(not => {
        //    this.notifications = not;
        //    this.notificationsUnread = not.filter(n => n.viewed === false);
        //    this.notificationsUnreadCount = not.filter(n => n.viewed === false).length;

        //})

    }

    notifTrim(desc:string){
        if(desc.length > 80) {
            return desc.substring(0,80) + '...';
        }
        return desc
    }

    translateByWord(text){
        var arr = text.split(' ');
        var result = [];
        arr.forEach(element => {
            if(this.terminologytranslatorService.fullyLoaded) {
                result.push(this.terminologytranslatorService.getTerminologyByProp(element))
            } else {
                this.terminologytranslatorService.loaded.subscribe(load=>{
                    result.push(this.terminologytranslatorService.getTerminologyByProp(element))
                });
            }
        });
        return result.join(' ');
    }
}