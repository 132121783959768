import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../_services/auth0.service";
import { resetColumns } from '@syncfusion/ej2-angular-grids';
import { OrganisationService } from '@app/services/organisation.service';

@Injectable()

export class AuthGuard implements CanActivate, CanActivateChild  {
    portfolioModuleEnabled: boolean = null;

    constructor(private _router: Router, private authService: AuthService, private organisationService: OrganisationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if(route.routeConfig.path != 'portfolios')
            return true;
        else if(this.portfolioModuleEnabled == null)
        {
            return new Promise((resolve, reject) => {
                this.organisationService.getModulePermissions().toPromise().then((response) => {

                        if(response.portfolioEnabled !== true)
                            this._router.navigate(['/ganttview']);
                        
                        this.portfolioModuleEnabled = response.portfolioEnabled;

                        return resolve(response.portfolioEnabled);
                    })
                })
        }
        else{
            if(this.portfolioModuleEnabled !== true)
                this._router.navigate(['/ganttview']);
                    
            return this.portfolioModuleEnabled;
        }
    }

}