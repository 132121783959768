import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable, Subject } from 'rxjs'
import { shareReplay, map, catchError, tap } from 'rxjs/operators';
import { publishReplay, refCount } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {throwError as observableThrowError} from 'rxjs';
import { GrantCostItemTypeModel } from '@app/models/GrantCostItemTypeModel';
import { GrantCostProjectModel } from '@app/models/GrantCostProjectModel';

@Injectable()
export class GrantCostService {
    handleError(): any {
        throw new Error("GrantCostService Method not implemented.");
    }

    constructor(private httpclient: HttpClient) {
        this.types = this.httpclient.get<any>('/api/v1/grantcost/types')
        .pipe(map(res => res));
    }

    private types: Observable<GrantCostItemTypeModel[]>;

    getProjects(id): Observable<GrantCostProjectModel[]> {
        return this.httpclient.get<any>('/api/v1/grantcost/'+id);
    }

    getTypes():Observable<GrantCostItemTypeModel[]> {
        return this.types.pipe(shareReplay(1));
    }
    
    public updateAll(formData,id): Observable<any> {
       return this.httpclient.post<any>('/api/v1/grantcost/'+id, formData);
    }

    errorHandler(err): void {
    }

}
