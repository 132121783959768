import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PrefixModel } from '@app/models/PrefixModel';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


@Injectable()
export class PrefixesService {
  constructor(private httpclient: HttpClient,
    private toastr: ToastrService) {
  }

  compareFn = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase())
      return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase())
      return 1;
    return 0;
  };

  public getPrefixes(entityType: string): Observable<PrefixModel[]> {
    return this.httpclient.get<any>(`/api/v1/${entityType}/prefixes`)
      .pipe(map(res => res.sort(this.compareFn)))
  }

  public getAllPrefixes(): Observable<PrefixModel[]> {
    return this.httpclient.get<any>(`/api/v1/prefixes`)
      .pipe(map(res => res.sort(this.compareFn)))
  }

  public addOrUpdatePrefix(prefix, isUpdated): Observable<PrefixModel> {
    return this.httpclient.post<any>('/api/v1/prefixes', prefix)
      .pipe(
        tap(c => {
          if (isUpdated)
            this.toastr.success('Funding Stream ' + c.name + ' Updated!');
          else
            this.toastr.success('Funding Stream ' + c.name + ' Added!');
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
      );
  }

  public deletePrefix(id): Observable<any> {
    return this.httpclient.delete(`/api/v1/prefixes/${id}`)
        .pipe(
        tap(c => {
            this.toastr.success("Prefix deleted", "Deleted");
        }),
        catchError((err: any) => {        
            return observableThrowError(this.errorHandler(err))
        }));
}

  errorHandler(error: any): void {  
  }
}
