
import {throwError as observableThrowError, Observable, Subject, throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as Rx from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { shareReplay, map, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from "@app/auth/_services/auth0.service";

import { ContractSettingsModel } from '@app/models/ContractSettingsModel';
import { ContractListModel, ContractModel } from '@app/models/ContractModel';
import { RiskFactorQuestionModel } from '@app/models/RiskFactorQuestionModel';
import { WorkflowModel } from '@app/models/WorkflowModel';
import { MemberModel } from '@app/models/MemberModel';
import { ContractAwardModel } from '@app/models/ContractAwardModel';
import { AssessmentModel } from '@app/models/assessmentmodel';
import { VariationModel } from '@app/models/VariationModel';
import { ContingencyModel } from '@app/models/ContingencyModel';
import { AwardTypeModel } from '@app/models/AwardTypeModel';
import { PriceBasisModel } from '@app/models/PriceBasisModel';
import { VariationCategoryModel } from '@app/models/VariationCategoryModel';
import { EOTCategoryModel } from '@app/models/EOTCategoryModel';
import { PerformanceQuestionModel } from '@app/models/PerformanceQuestionModel';
import { ExtensionOfTimeModel } from '@app/models/ExtensionOfTimeModel';
import { ReportTemplateModel } from '@app/models/ReportTemplateModel';
import { ContractCloseoutReportModel } from '@app/models/ContractCloseoutReportModel';
import { CloseoutDeliveryModel } from '@app/models/CloseoutDeliveryModel';
import { FileModel } from '@app/models/FileModel';
import { Router } from '@angular/router';
import { UserModel } from '@app/models/UserModel';
import { IEntityWithMemberFileService } from './interfaces/IEntityFile.service';
import { IWorkflowEntityService } from './interfaces/IWorkflowEntity.service';
import { TerminologytranslatorService } from './TerminologyTranslator.service';
import { WorkflowGroupModel } from '@app/models/WorkflowGroupModel';
import { WorkflowStageModel } from '@app/models/WorkflowStageModel';


@Injectable()
export class ContractsService implements IEntityWithMemberFileService , IWorkflowEntityService {
    handleError(arg0: any): any {
        throw new Error("ContractsService Method not implemented.");
    }

    public getWorkflowGroups(id): Observable<WorkflowGroupModel[]> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/workflowgroup/`)
        .pipe(map(res => res));
    }

    public getWorkflowStagesByGroupId(id,groupId): Observable<WorkflowStageModel[]> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/workflowstage/group/${groupId}`)
        .pipe(map(res => res));
    }


    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService,private router: Router,
        private terminologytranslatorService : TerminologytranslatorService) {
            this.contracts = this.httpclient.get<any>('/api/v1/contracts')
            .pipe(map(res => res))
            this.contracts2 = this.httpclient.get<any>('/api/v1/contracts')
            .pipe(map(res => res))
    }
    
    public performanceAnswersUpdated: Subject<any> = new Rx.Subject<any>();
    public shouldRedrawRiskFactorQuestions: Subject<any> = new Rx.Subject<any>();

    private contracts: Observable<ContractModel[]> ;
    private contracts2: Observable<ContractListModel[]> ;

    public getContracts(): Observable<ContractModel[]> {
        return this.contracts.pipe(shareReplay(1));
    }
    public getContracts2(): Observable<ContractListModel[]> {
        return this.contracts2.pipe(shareReplay(1));
    }
    public getContractsArchived(): Observable<ContractListModel[]> {
        return this.httpclient.get<any>('/api/v1/contracts/archived')
            .pipe(map(res => res))
    }

    public uploadEntityFile(formData, contractId): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/contracts/${contractId}/files`, formData).pipe(
            tap(res => {
                this.toastr.success(`A new document has been attached to ${res.name}!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public updateEntityFile(formData, contractId): Observable<FileModel> {
        return this.httpclient.post<any>(`/api/v1/contracts/${contractId}/files/edit`, formData).pipe(
            tap(res => {
                this.toastr.success(`Document Updated!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }


    public getEntityFile(contractId, id): Observable<any> {
        return this.httpclient.get(`/api/v1/contracts/${contractId}/files/${id}`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public deleteEntityFile(contractId, id): Observable<FileModel[]> {
        return this.httpclient.delete<FileModel[]>(`/api/v1/contracts/${contractId}/files/${id}`)
            .pipe(res => res)
    }


    public getContract(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/contracts/' + id)
            .pipe(map(res => res),
            catchError(err => 
            {
                return observableThrowError(this.errorHandler(err));      
            }),)
    }

    public getApprovedFiles(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/contracts/approvedFiles/' + id)
            .pipe(map(res => res),
            catchError(err => 
            {
                return observableThrowError(this.errorHandler(err));      
            }),)
    }
 
    public getAssociatedItemFiles(grantId,type, ids): Observable<any> {        
            if(type=='plannedActivitie')
                type= 'plannedActivity';
            return this.httpclient.post(`/api/v1/contracts/${grantId}/${type}list/`, ids,  { responseType: 'arraybuffer' })
            .pipe(res => res);      
    }

    public updateContract(formData): Observable<ContractModel> {
        return this.httpclient.post<any>('/api/v1/contracts', formData)
            .pipe(
            tap(p => {
                this.toastr.success(`${this.terminologytranslatorService.getTerminologyByProp("Contract")} ${p.name} Updated!`);
            }),
            catchError(err => this.errorHandler(err))
            )
    }

    // Add Contract
    public addContract(formData): Observable<ContractModel> {
        return this.httpclient.post<any>('/api/v1/contracts', formData)
            .pipe(
            tap(p => {
                this.toastr.success(`Contract ${p.name} Added!`);
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );

    }




    errorHandler(error: any): any {
        return Rx.throwError(error?.error);
    }



    public getContractSettings(id) {
        return this.httpclient.get<ContractSettingsModel>(`/api/v1/contracts/${id}/settings`).pipe(map(res => res));
    }



    public getContractQuestions(id, gateway) {

        if (gateway == null || gateway == '') {
            return this.httpclient.get<RiskFactorQuestionModel[]>(`/api/v1/contracts/${id}/questions`)
                .pipe(map(res => res))

        }
        else {
            return this.httpclient.get<RiskFactorQuestionModel[]>(`/api/v1/contracts/${id}/questions/` + gateway)
                .pipe(map(res => res))
        }
    }
    
    public setContractQuestions(id, formData, skipApproval) {
        return this.httpclient.post<any>(`/api/v1/contracts/${id}/questions/${skipApproval}`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Questions Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getWorkflow(id): Observable<WorkflowModel> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/workflow`)
            .pipe(map(res => res));
    }

    public setWorkflow(id, formData): Observable<WorkflowModel> {
        return this.httpclient.post<any>(`/api/v1/contracts/${id}/workflow`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Workflow Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }


    public getDefaultContractMembers(): Observable<UserModel[]> {
        return this.httpclient.get<any>(`/api/v1/contracts/members/default`)
            .pipe(map(res => res))
    }


    public getMembers(id): Observable<MemberModel[]> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/members`)
            .pipe(map(res => res))
    }
    public setMembers(id, formData): Observable<MemberModel> {
        return this.httpclient.post<any>(`/api/v1/contracts/${id}/members`, formData).pipe(
            tap(p => {
                this.toastr.success('Contract Members Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public uploadContractFile(formData, contractId): Observable<ContractModel>{

        return this.httpclient.post<any>(`/api/v1/contracts/${contractId}/files`, formData).pipe(
            tap(res => {
                this.toastr.success(`A new document has been attached to ${res.name}!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }


    
    public updateContractFile(formData, contractId): Observable<FileModel>{

        return this.httpclient.post<any>(`/api/v1/contracts/${contractId}/files/edit`, formData).pipe(
            tap(res => {
                this.toastr.success(`Document Updated!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public getContractFile(id, contractId): Observable<any> {
        return this.httpclient.get(`/api/v1/contracts//${contractId}/files/${id}`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public deleteContractFile(contractId, id): Observable<FileModel[]> {
        return this.httpclient.delete<FileModel[]>(`/api/v1/contracts/${contractId}/files/${id}`)
            .pipe(res => res)
    }

    public getContractApproval(id, formData) {

        return this.httpclient.post<any>(`/api/v1/contracts/${id}/approval/request`, formData).pipe(
            tap(res => {
                this.toastr.success('Contract Approval Requested!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }
    public setContractApproved(id, formData) {

        return this.httpclient.post<any>(`/api/v1/contracts/${id}/approval/process`, formData).pipe(
            tap(res => {
                this.toastr.success('Contract Response Submitted!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public getActualSpendInfoBar(contractId) : Observable<number>
    {
        return this.httpclient.get<any>(`/api/v1/contracts/getactualspendinfobar/${contractId}`)
            .pipe(map(res => res))
    }

    public getContractAward(id): Observable<ContractAwardModel> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/award`)
            .pipe(map(res => res))
    }
    
    public getContractAwardedValue(id): Observable<any> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/awarded/amount`)
            .pipe(map(res => res))
    }
    
    public setContractAward(id, formData): Observable<ContractAwardModel> {
        return this.httpclient.post<any>(`/api/v1/contracts/${id}/award`, formData).pipe(
            tap(p => {
                this.toastr.success('Contract Award Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }    

    public getContractApprovals(id): Observable<AssessmentModel[]> {
        return this.httpclient.get<any>('/api/v1/contracts/' + id + '/approvals')
            .pipe(map(res => res))
    }
    public setContractApprovals(id, formData) { 
        return this.httpclient.post<any>(`/api/v1/contracts/${id}/approvals`, formData).subscribe(
            res => {
                this.toastr.success('Contract Approvals Updated!');
            },
            err => {
                return (err.error);
            }
        );
    }

    public getApprovedEOT(id) : Observable<any[]> {
        return this.httpclient.get<any>('/api/v1/contracts/' + id + '/eots/approved')
            .pipe(map(res => res))
    }

    public getVariations(id, type) {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/${type}`)
            .pipe(map(res => res))
    }

    public getApprovedVariations(id) {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/variations/approved/`)
            .pipe(map(res => res))
    }

    public getContractVariations(id): Observable<VariationModel[]> {
        return this.getVariations(id, 'variations');
    }

    public getContractEOTs(id): Observable<ExtensionOfTimeModel[]> {
        return this.getVariations(id, 'eots');
    }

    public saveVariation(id, type, formData, isApprovalOperation): Observable<any> {             
        return this.httpclient.post<any>(`/api/v1/contracts/${id}/${type}`, formData)
            .pipe(
                tap(p => {
                    if(!isApprovalOperation)
                    {
                        this.toastr.success('Contract Variations Updated!');
                    }                                       
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public saveContractVariation(id, formData): Observable<VariationModel> {
        return this.saveVariation(id, 'variations', formData, false);
    }

    public saveContractEOT(id, formData): Observable<ExtensionOfTimeModel> {
        return this.saveVariation(id, 'eots', formData, false);
    }

    public deleteContract(id): Observable<any> {
        return this.httpclient.delete(`/api/v1/contracts/${id}`)
            .pipe(
            tap(c => {
                this.toastr.success("Contract deleted", "Deleted");
            }),
            catchError((err: any) => {
                this.toastr.error("There was an issue during the contract deletion", "not able to delete");
                return observableThrowError(this.errorHandler(err))
            }));
    }

    public deleteVariations(id, type: string, deletedVariations, entityType): Observable<any[]> {
        return this.httpclient.post<any>(`/api/v1/${entityType}/${id}/${type}/delete`, deletedVariations)
            .pipe(
                tap(p => {
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );   
    }

    public deleteContractVariations(id, deletedVariations,entityType): Observable<VariationModel[]> {
        return this.deleteVariations(id, 'variations', deletedVariations,entityType);
    }

    public deleteContractEOTs(id, deletedVariations,entityType): Observable<ExtensionOfTimeModel[]> {
        return this.deleteVariations(id, 'eots', deletedVariations,entityType);
    }

    public setVariationApprovers(id, type, approverIds): Observable<VariationModel> {
        return this.httpclient.post<any>(`/api/v1/contracts/${type}/${id}/approvers`, approverIds)
            .pipe(
                tap(p => {
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    // public setEotApprovers(id, approverIds): Observable<ExtensionOfTimeModel> {
    //     return this.httpclient.post<any>(`/api/v1/contracts/eots/${id}/approvers`, approverIds)
    //         .pipe(
    //         tap(p => {
    //         }),
    //         catchError((err: any) => observableThrowError(this.errorHandler(err)))
    //         );
    // }

    public submitApproval(id, type: string, approval: any): Observable<VariationModel> {
        return this.httpclient.post<any>(`/api/v1/${type}/${id}/approval/status`, {
            id: approval.id,
            comment: approval.comment,
            isApproved: approval.isApproved
        })
            .pipe(
            tap(p => {
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    // public requestApproval(id, type: string, approvalRequestComment: string): Observable<VariationModel> {
    //     return this.httpclient.post<any>(`/api/v1/contracts/${type}/${id}/approvals/requested`, { comment: approvalRequestComment })
    //         .pipe(
    //         tap(p => {
    //         }),
    //         catchError((err: any) => observableThrowError(this.errorHandler(err)))
    //         );
    // }

    public getAwardTypes(): Observable<AwardTypeModel[]> {
        return this.httpclient.get<any>('/api/v1/contracts/awardtypes')
            .pipe(map(res => res))
    }

    public getPriceBasis(): Observable<PriceBasisModel[]> {
        return this.httpclient.get<any>('/api/v1/contracts/pricebases')
            .pipe(map(res => res))
    }

    public getVariationCategories(): Observable<VariationCategoryModel[]> {
        return this.httpclient.get<any>('/api/v1/variations/categories')
            .pipe(map(res => res))
    }

    public getDocuments(): Observable<ReportTemplateModel[]> {
        return this.httpclient.get<any>('/api/v1/contracts/documents')
            .pipe(map(res => res))
    }


    public getContractPlan(id): Observable<ContingencyModel[]> {
        return this.httpclient.get<any>(`/api/v1/contracts/${id}/plan`)
            .pipe(map(res => res))
    }

    public getEOTCategories(): Observable<EOTCategoryModel[]> {
        return this.httpclient.get<any>('/api/v1/eot/categories')
            .pipe(map(res => res))
    }

    public isUnArchivable(id) : Observable<Boolean>{
        return this.httpclient.get<any>('/api/v1/contracts/'+id+'/isunarchivable')
            .pipe(map(res => res))
    }

    public isContractorArchived(id): Observable<string>{
        return this.httpclient.get<any>('/api/v1/contracts/'+id+'/contractor/archived')
            .pipe(map(res => res))
    }

    public getContractPerformanceQuestions(id): Observable<PerformanceQuestionModel[]> {
        return this.httpclient.get<any>('/api/v1/contracts/' + id + '/performance')
            .pipe(map(res => res))
    }

    public setContractPerformanceQuestions(id, formData): Observable<any[]> { 
        return this.httpclient.post<any[]>('/api/v1/contracts/' + id + '/performance', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Performance Questions Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getContractCloseout(id): Observable<ContractCloseoutReportModel> {
        return this.httpclient.get<any>('/api/v1/contracts/' + id + '/closeout')
            .pipe(map(res => res))
    }
    public setContractCloseout(id, formData): Observable<CloseoutDeliveryModel> { 

        return this.httpclient.post<CloseoutDeliveryModel>('/api/v1/contracts/' + id + '/closeout', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Closeout Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public setContractArchive(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/contracts/' + id + '/archive', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Archived!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public setContractUnarchive(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/contracts/' + id + '/unarchive', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Un-Archived!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public setContractCloseInDelivery(id, formData): Observable<any[]> {
        return this.httpclient.post<any>('/api/v1/contracts/' + id + '/closeindelivery', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Close In Delivery Complete!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }


    public setContractCloseToPC(id, formData): Observable<any[]> {
        return this.httpclient.post<any>('/api/v1/contracts/' + id + '/closeouttopc', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Practical Completion Complete');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public setContractMaitenanceCloseout(id, formData): Observable<any[]> {
        return this.httpclient.post<any>('/api/v1/contracts/' + id + '/maintenancecloseout', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Contract Maintenance Closeout Complete!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public inlineUpdateContract(formData): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/contracts/updatecontractsummary`, formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

}
