import { Injectable } from '@angular/core';
import { Router, } from '@angular/router';
import { TerminologytranslatorService } from '@app/services/TerminologyTranslator.service';
import {Title} from "@angular/platform-browser";

@Injectable()
export class NavigationHelperService {
    
    topMenuLinks:object[]=[]
    pageDescription:string = "Overview"
    pageDescOptional = ''

    constructor(private titleService: Title, private router: Router,private terminologytranslatorService: TerminologytranslatorService) {
        if(this.terminologytranslatorService.fullyLoaded) {
            this.titleService.setTitle(this.terminologytranslatorService.getTerminologyByProp(document.title));
        } else {
            this.terminologytranslatorService.loaded.subscribe(load=>{
                this.titleService.setTitle(this.terminologytranslatorService.getTerminologyByProp(document.title));
            });
        }
    }

    async setTopMenuLinks(name:string='',position:number = 1,pageDescription:string = '',pageDescOptional:string = '',link:string=null){
        this.pageDescription = pageDescription
        this.pageDescOptional = pageDescOptional
        //get first instance of the route
        var extractedChild
        for (var i = 0; i < this.router.config.length; i++) {
            if(this.router.config[i].path == '')
                if(extractedChild = this.extractRouteChildren(this.router.config[i].children)){
                    break
                }
        }

        if(position == 1){
            if(name != ''){
                this.topMenuLinks[position] = {
                    name: name,
                    link: link ? link:window.location.pathname
                }
                this.titleService.setTitle(this.terminologytranslatorService.getTerminologyByProp(document.title));
            }else{
                this.setTopMenuLinksArray(1,extractedChild)
            }
            this.topMenuLinks.length = 2;
        }
        //end of getting first instance
        if(position > 1){
            this.pageDescOptional = pageDescOptional
            this.topMenuLinks[position] = {
                name: name,
                link: link ? link:window.location.pathname
            }
            this.titleService.setTitle(name);
        }
        //get second instance of the route
        var secondExtractedChild
        if(position > 2){
            if(secondExtractedChild = this.extractRouteChildren(extractedChild._loadedRoutes[0].children)){
                this.setTopMenuLinksArray(2,secondExtractedChild)
                this.titleService.setTitle(name);
            }
        }
        //end of getting second instance
    }

    setTopMenuLinksArray(pos:number,child){
        this.topMenuLinks[pos] = {
            name: child?.title,
            link: '/'+child.path
        }
    }
    
    extractRouteChildren(children){
        if(children){
            for (var i = 0; i < children.length; i++) {
                let checkRoute = window.location.pathname.split("/")
                if(checkRoute.includes(children[i].path)&&children[i].path!=''){
                    if(children[i].title){
                        children[i].title = this.terminologytranslatorService.getTerminologyByProp(children[i].title)
                        if(children[i].data?.description){
                            this.pageDescOptional = children[i].data.description
                        }
                        return children[i]
                    }
                }
            }
        }
        return null
    }

    setNavigationBreadcrumb(position,accessEntityID,accessEntityName,previousEntity){
        const entityID = sessionStorage.getItem(accessEntityID)
        const entityName = sessionStorage.getItem(accessEntityName)
        
        this.setTopMenuLinks(
            entityName,
            position++,
            null,
            null,
            `/${previousEntity.toLowerCase()}/update/${entityID}`
        )
        return position
    }    
}
