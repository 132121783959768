import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
//import * from './polyfills';

//import { LicenseManager } from "ag-grid-enterprise"
//LicenseManager.setLicenseKey("Evaluation_License_Valid_Until__16_September_2018__MTUzNzA1MjQwMDAwMA==b7ecc78983c1bf33b5eb3682cc62cfaa");

// Old Registering Syncfusion license key
//Fix Syncfusion license Key as of June 29, 2022 with Details : Platform = Javascript; Version = 20.1.0.60; 
//   registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFacF9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjUH1dcHNRRGhfUkU=')


// Registering Syncfusion license key
//Fix Syncfusion license Key as of February 10, 2023 with Details : Platform = Javascript; Version = 20.4.0.48; 
  registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjUH1ddHFWQ2lcVUQ=')

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
