
<div *ngIf="activeOrganisation=='FundingBodyRecipient'">
    <app-fundingbodyrecipientmenu></app-fundingbodyrecipientmenu>
</div>

<div *ngIf="activeOrganisation=='RecipientFreemium'">
    <app-recipientfreemiummenu></app-recipientfreemiummenu>
</div>

<div *ngIf="activeOrganisation=='RecipientPremium'">
    <app-recipientpremiummenu></app-recipientpremiummenu>
</div>


<div *ngIf="activeOrganisation!='FundingBodyRecipient' && activeOrganisation!='RecipientFreemium'&& activeOrganisation!='RecipientPremium'">
<div class="leftside-menu">
    <!-- LOGO -->
    <a href="javascript:void(0)" class="logo text-center logo-light">
        <span class="logo-lg">
            <img src="assets/app/media/img/Surepact_Logo-Reversed.svg" alt="" height="35">
            <button class="button-menu-mobile open-left n-brand__icon" (click)="changeSideCondensed()">
                <i class="icon-funder-chevron-contract-horizontal" aria-hidden="true"></i>
            </button>
        </span>
        <span class="logo-sm button-menu-mobile open-left" (click)="changeSideCondensed()">
            <img src="assets/app/media/img/surepact-logo-sm.png" alt="" height="35">
        </span>
    </a>
    <div class="lds-dual-ring" *ngIf="myUserProfile==null"></div>
    <div id="leftside-menu-container" data-simplebar *ngIf="myUserProfile">
        <!--- Sidemenu -->
        <ul class="side-nav side-nav-top">

            <li class="side-nav-item " routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['RecipientPremium'])">
                <a routerLink="/index" class="side-nav-link">
                    <i class="icon-funder-home-plus" aria-hidden="true"></i>
                    <span> Dashboard </span>
                </a>
            </li>

            <li class="side-nav-item " routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['RecipientFreemium'])">
                <a routerLink="/dashboard" class="side-nav-link">
                    <i class="icon-funder-home-plus" aria-hidden="true"></i>
                    <span> Dashboard </span>
                </a>
            </li>

            <li class="side-nav-item " routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['FundingBody'])">
                <a routerLink="/dashboard" class="side-nav-link">
                    <i class="icon-funder-home-plus" aria-hidden="true"></i>
                    <span> Homepage </span>
                </a>
            </li>

            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['FundingBody'])">
                <a routerLink="/fundingprograms" class="side-nav-link">
                    <i class="icon-funder-layers-1" aria-hidden="true"></i>
                    <span> Funding Programs </span>
                </a>
            </li>

            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['FundingBody'])">
                <a routerLink="/funding-projects" class="side-nav-link">
                    <i class="icon-funder-add-layer" aria-hidden="true"></i>
                    <span> Projects </span>
                </a>
            </li>
            
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['FundingBody'])">
                <a routerLink="/analytics" class="side-nav-link">
                    <i class="icon-funder-bars" aria-hidden="true"></i>
                    <span> Analytics </span>
                </a>
            </li>

            <li class="side-nav-item" *ngIf="showItem(['RecipientFreemium'])" #sidebarPortfolio>
                <a data-bs-toggle="collapse" href="#" aria-expanded="false" class="side-nav-link" (click)="toggleChildItem(6)">
                    <i class="icon-funder-layers-1" aria-hidden="true"></i>
                    <span> Portfolio Management </span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li class="side-nav-item" routerLinkActive="second-active" [ngClass]="{ 'disabled-link': true }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a data-bs-toggle="collapse"  href="#sidebaPortfolioDelivery" aria-expanded="false" aria-controls="sidebaPortfolioDelivery">
                                <a routerLink="/{{porfolioModuleDefault}}" class="support-a">
                                    <span> Portfolio Delivery </span>
                                    <span class="menu-arrow"></span>
                                    <i class="icon-funder-lock side-right side-right-child" aria-hidden="true"></i>
                                </a>
                            </a>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" [ngClass]="{ 'disabled-link': true }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a data-bs-toggle="collapse"  href="#sidebaProjectDelivery" aria-expanded="false" aria-controls="sidebaProjectDelivery">
                                <a routerLink="/projects" class="support-a">
                                    <span> Project Delivery </span>
                                    <span class="menu-arrow"></span>
                                    <i class="icon-funder-lock side-right side-right-child" aria-hidden="true"></i>
                                </a>
                            </a>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" [ngClass]="{ 'disabled-link': true }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a data-bs-toggle="collapse"  href="#sidebaContractDelivery" aria-expanded="false" aria-controls="sidebaContractDelivery">
                                <a routerLink="/contracts" class="support-a">
                                    <span> {{terminologytranslatorService.getTerminologyByProp('Contract')}} Delivery </span>
                                    <span class="menu-arrow"></span>
                                    <i class="icon-funder-lock side-right side-right-child" aria-hidden="true"></i>
                                </a>
                            </a>
                        </li>
                        <li  class="side-nav-item"  routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': false }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a routerLink="/opportunities">Funding Opportunities <i class="icon-funder-lock side-right" aria-hidden="true"></i></a>
                        </li>
                        <li  class="side-nav-item"  routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': false }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a routerLink="/grants">Applications <i class="icon-funder-lock side-right" aria-hidden="true"></i></a>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" [ngClass]="{ 'disabled-link': true }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a data-bs-toggle="collapse"  href="#sidebarContractors" aria-expanded="false" aria-controls="sidebarContractors">
                                <a routerLink="/contractors" class="support-a">
                                    <span> Contractors </span>
                                    <span class="menu-arrow"></span>
                                    <i class="icon-funder-lock side-right side-right-child" aria-hidden="true"></i>
                                </a>
                            </a>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" [ngClass]="{ 'disabled-link': true }">
                            <i class="fa fa-bolt color-yellow" aria-hidden="true"></i>
                            <a data-bs-toggle="collapse"  href="#assetRegister" aria-expanded="false">
                                <a routerLink="/contracts" class="support-a">
                                    <span>Asset Register </span>
                                    <span class="menu-arrow"></span>
                                    <i class="icon-funder-lock side-right side-right-child" aria-hidden="true"></i>
                                </a>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="side-nav-item" *ngIf="showItem(['RecipientPremium'])" #sidebarPortfolioStandard>
                <a data-bs-toggle="collapse" href="#" aria-expanded="false" class="side-nav-link" (click)="toggleChildItem(7)">
                    <i class="icon-funder-layers-1" aria-hidden="true"></i>
                    <span> Portfolio Management </span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li class="side-nav-item" routerLinkActive="second-active" #sidebaPortfolioDelivery>
                            <a data-bs-toggle="collapse"  href="#" aria-expanded="false" (click)="toggleChildItemOfChild(8)">
                                <a routerLink="/{{porfolioModuleDefault}}" class="support-a">
                                    <span> Portfolio Delivery </span>
                                    <span class="menu-arrow"></span>
                                </a>
                            </a>
                            <div class="collapse">
                                <ul class="side-nav-third-level">
                                    <li routerLinkActiveOptions="{ exact: true }" routerLinkActive="second-active">
                                        <a routerLink="/ganttview">Gantt</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" #sidebaProjectDelivery>
                            <a data-bs-toggle="collapse"  href="#" aria-expanded="false" (click)="toggleChildItemOfChild(9)">
                                <a routerLink="/projects" class="support-a">
                                    <span> Project Delivery </span>
                                    <span class="menu-arrow"></span>
                                </a>
                            </a>
                            <div class="collapse">
                                <ul class="side-nav-third-level">
                                    <li routerLinkActiveOptions="{ exact: true }" routerLinkActive="second-active">
                                        <a  routerLink="/projects/archived">Archived</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" #sidebaContractDelivery>
                            <a data-bs-toggle="collapse"  href="#" aria-expanded="false" (click)="toggleChildItemOfChild(10)">
                                <a routerLink="/contracts" class="support-a">
                                    <span> {{terminologytranslatorService.getTerminologyByProp('Contract')}} Delivery </span>
                                    <span class="menu-arrow"></span>
                                </a>
                            </a>
                            <div class="collapse">
                                <ul class="side-nav-third-level">
                                    <li routerLinkActiveOptions="{ exact: true }" routerLinkActive="second-active">
                                        <a  routerLink="/contracts/archived">Archived</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" #sidebaGrantManagement>
                            <a data-bs-toggle="collapse"  href="#" aria-expanded="false" (click)="toggleChildItemOfChild(11)">
                                <a routerLink="/grants" class="support-a">
                                    <span> {{terminologytranslatorService.getTerminologyByProp('Grant Management')}} </span>
                                    <span class="menu-arrow"></span>
                                </a>
                            </a>
                            <div class="collapse">
                                <ul class="side-nav-third-level">
                                    <li routerLinkActiveOptions="{ exact: true }" routerLinkActive="second-active">
                                        <a  routerLink="/grants/archived">Archived</a>
                                    </li>
                                    <li routerLinkActiveOptions="{ exact: true }" routerLinkActive="second-active">
                                        <a  routerLink="/grants/approved">Approved</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="side-nav-item" routerLinkActive="second-active" #sidebarContractors>
                            <a data-bs-toggle="collapse"  href="#" aria-expanded="false" (click)="toggleChildItemOfChild(12)">
                                <a routerLink="/contractors" class="support-a">
                                    <span> Contractors </span>
                                    <span class="menu-arrow"></span>
                                </a>
                            </a>
                            <div class="collapse">
                                <ul class="side-nav-third-level">
                                    <li routerLinkActiveOptions="{ exact: true }" routerLinkActive="second-active">
                                        <a  routerLink="/contractors/archived">Archived</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/assets">Asset Register</a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="seperation-line-li" *ngIf="showItem(['RecipientFreemium','RecipientPremium'])">
                <div class="seperation-line"></div>
            </li>
            
            <li class="side-nav-item" *ngIf="showItem(['RecipientFreemium','RecipientPremium'])" #sidebarTraining>
                <a data-bs-toggle="collapse" href="#"  aria-expanded="false" class="side-nav-link" (click)="toggleChildItem(1)">
                    <i class="icon-funder-academic-cap" aria-hidden="true"></i>
                    <span> Training </span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/training/userguide">User Guide</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/training/library">Training Library</a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="seperation-line-li" *ngIf="showItem(['RecipientFreemium','RecipientPremium'])">
                <div class="seperation-line"></div>
            </li>

            <li class="side-nav-item" *ngIf="showItem(['RecipientPremium'])" #sidebarDocumentManagement>
                <a data-bs-toggle="collapse" href="#" aria-expanded="false" class="side-nav-link"  (click)="toggleChildItem(2)">
                    <i class="icon-funder-document-double" aria-hidden="true"></i>
                        <span> Documents</span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/library">Library</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/repository">Repository</a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="seperation-line-li" *ngIf="showItem(['RecipientPremium'])">
                <div class="seperation-line"></div>
            </li>
        </ul>
        
        <ul class="side-nav side-nav-bottom">
            <li class="seperation-line-li" *ngIf="showItem(['FundingBody'])">
                <div class="seperation-line"></div>
            </li>
            <li *ngIf="showItem(['RecipientFreemium'])">
                <div class="help-box text-white text-center">
                    <a href="javascript: void(0);" class="float-end close-btn text-white">
                        <i class="mdi mdi-close"></i>
                    </a>
                    <h4 class="box-header"><i class="fa fa-bolt color-yellow-normal" aria-hidden="true"></i> Upgrade Premium</h4>
                    <p class="box-body">Unlimited access to useful features</p>
                    <button class="btn btn-sm background-yellow">Upgrade Now</button>
                </div>
            </li>
            <li class="side-nav-item" *ngIf="showItem(['FundingBody'])" routerLinkActive="menuitem-active" routerLink="/library">
                <a data-bs-toggle="collapse" href="#" aria-expanded="false" class="side-nav-link">
                    <i class="icon-funder-document-double" aria-hidden="true"></i>
                    <span> Documents</span>
                </a>
            </li>
            <li class="seperation-line-li" *ngIf="showItem(['FundingBody'])">
                <div class="seperation-line"></div>
            </li>
            <li class="side-nav-item" *ngIf="(myUserProfile?.isAdmin || myUserProfile?.isCustomerAdmin || myUserProfile?.isTenantAdmin) && showItem(['FundingBody','RecipientFreemium'])" #sidebarAdministration>
                <a data-bs-toggle="collapse" href="#"  aria-expanded="false" class="side-nav-link" (click)="toggleChildItem(3)">
                    <i class="icon-funder-settings-3" aria-hidden="true"></i>
                    <span> Administration </span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse" id="sidebarAdministration">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/users">
                                {{terminologytranslatorService.getTerminologyByProp('Users')}} 
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/businessunits">
                                {{terminologytranslatorService.getTerminologyByProp('Business Units')}} 
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/departments">
                                {{terminologytranslatorService.getTerminologyByProp('Departments')}} 
                            </a>
                        </li>
                       
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/organisations">
                                {{terminologytranslatorService.getTerminologyByProp('Organisations')}} 
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/recipients">
                                {{terminologytranslatorService.getTerminologyByProp('Recipients')}} 
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/contractors">
                                {{terminologytranslatorService.getTerminologyByProp('Contractors')}} 
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }" *ngIf="showItem(['FundingBody'])">
                            <a routerLink="/formsconfiguration">
                                Template Builder
                             </a>
                         </li>
                         <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }" *ngIf="showItem(['FundingBody','FundingBodyRecipient'])">
                             <a routerLink="/reviewcriteria">
                                {{terminologytranslatorService.getTerminologyByProp('Review Criteria')}} 
                              </a>
                          </li>
                        <!-- <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/terminologies">
                                Terminology 
                            </a>
                        </li> -->
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }" *ngIf="showItem(['FundingBody'])">
                            <a routerLink="/import">Import</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }" *ngIf="showItem(['FundingBody'])">
                            <a routerLink="/export">Export</a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="side-nav-item" *ngIf="(myUserProfile?.isAdmin || myUserProfile?.isTenantAdmin || myUserProfile?.isCustomerAdmin) && showItem(['RecipientPremium'])" #sidebarData>
                <a data-bs-toggle="collapse" href="#" aria-expanded="false" class="side-nav-link" (click)="toggleChildItem(4)">
                    <i class="icon-funder-settings-3" aria-hidden="true"></i>
                    <span> Administration </span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/users"> Manage Users</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/prefix">  
                                <span>{{terminologytranslatorService.getTerminologyByProp('Funding Streams')}}</span>
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/businessunits"> Manage {{terminologytranslatorService.getTerminologyByProp('Business Unit')}}</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/departments"> Manage {{terminologytranslatorService.getTerminologyByProp('Department')}}</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/import"> Import</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/export"> Export</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/terminologies"> Terminology</a>
                        </li>
                    </ul>
                </div>
            </li>            
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" *ngIf="showItem(['RecipientPremium'])">
                <a href="https://www.tmr.qld.gov.au/" target="_blank" class="side-nav-link">
                    <i class="icon-funder-bars" aria-hidden="true"></i>
                    <span> DTMR </span>
                </a>
            </li>

            <li class="side-nav-item menuitem" *ngIf="showItem(['all'])">
                <a href="https://surepact.com/privacy-policy/" target="_blank" class="side-nav-link">
                    <i class="icon-funder-lock" aria-hidden="true"></i>
                    <span> Privacy Policy </span>
                </a>
            </li>
        </ul>
        <!-- End Sidebar -->
    </div>
    <!-- Sidebar -left -->
</div>

</div>
<!-- Left Sidebar End -->
