
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  timer ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GovernanceLogModel } from '../models/GovernanceLogModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, tap, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class GovernanceLogService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    private cache$: Observable<Array<GovernanceLogModel>>;
    private reload$ = new Subject<void>();

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }



    // Getting the governance log for the Project/Contract/Quote
    public getGovLog(type, id, pageSize = 20, pageNumber = 1, category = 3): Observable<GovernanceLogModel[]> {

        return this.httpclient.get<any>('/api/v1/governance/' + type + '/' + id + '/' + category + '/' + pageSize + '/' + pageNumber)
            .pipe(map(res => res))
    }

    public addGovLog(type, id, entry ,parentId ='') {
        return this.httpclient.post('/api/v1/governance/' + type + '/' + id + (parentId !=='' ? '/'+parentId : "" ), entry).pipe(
            tap(p => {
                this.toastr.success('Manual Entry Added!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );

    }

    public addGovLogSendNotification(type, id, item ,parentId ='') {
        return this.httpclient.post('/api/v1/governance/' + type + '/send/' + id + (parentId !=='' ? '/'+parentId : "" ), item).pipe(
            tap(p => {
                this.toastr.success('Manual Entry Added!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }


    errorHandler(error: any): void {
    }












    forceReload() {
        this.reload$.next();
        this.cache$ = null;
    }


}
