
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  timer ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileModel } from '../models/FileModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, tap, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class LibraryService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    private cache$: Observable<Array<FileModel>>;
    private reload$ = new Subject<void>();

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }

    public getLibrary(): Observable<FileModel[]> {
        return this.httpclient.get<any>('/api/v1/library')
            .pipe(map(res => res))
    } 
   
    public getFolders(): Observable<any> {
        return this.httpclient.get<any>('/api/v1/folders/')
            .pipe(map(res => res))
    }

    public downloadWidgetDocumentLibrary(id){
        return this.httpclient.get(`/api/v1/library/${id}/false`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public getLibraryFile(id): Observable<any> {
        return this.httpclient.get('/api/v1/library/' + id, { responseType: 'blob' })
            .pipe(res => res)
    }

    public moveToFolder(id, folderid): Observable<any> {
        return this.httpclient.get(`/api/v1/library/move/${id}/folder/${folderid}`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public saveFolder(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/folders/', formData).pipe(
            tap(res => {
                this.toastr.success('Folder Saved');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public editLibraryFile(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/library/repository/edit', formData).pipe(
            tap(res => {
                this.toastr.success('Document Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }
    
    public updateFile(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/library/update/', formData).pipe(
            tap(res => {
                this.toastr.success('Library file Saved');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public deleteFile(id): Observable<any> {

        return this.httpclient.delete(`/api/v1/library/${id}`).pipe(
            tap(res => {
                this.toastr.success('Document Successfully Deleted');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public deleteFolder(id): Observable<any> {

        return this.httpclient.delete(`/api/v1/folders/${id}`).pipe(
            tap(res => {
                this.toastr.success('Folder Deleted');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public uploadLibraryFile(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/library', formData).pipe(
            tap(res => {
                this.toastr.success('Library file Uploaded!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public uploadRepositoryFile(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/library/repository', formData).pipe(
            tap(res => {
                this.toastr.success('A new document has been attached to the Document Repository!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }


    errorHandler(error: any): void {
    }


public saveDocumentLink(formData): Observable<any> {

        return this.httpclient.post<any>('/api/v1/library/repository/documentlink', formData).pipe(
            tap(res => {
               // this.toastr.success('Library file Uploaded!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }







    forceReload() {
        this.reload$.next();
        this.cache$ = null;
    }


}
