import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { AccountsService } from '@app/services/accounts.service';
import { firstValueFrom } from 'rxjs';
import { OrganisationTenancyType, SessionCode } from '@app/constants';
import { UserModel } from '@app/models/UserModel';

@Injectable()
export class TenancyGuard implements CanActivate  {
    constructor(private accountsService: AccountsService, private _router: Router) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        let tenancy = route.data.tenancy;
        let currentPage = route.data.currentPage;
        if(!sessionStorage.getItem("organisationTenancyType")){
            await this.accountsService.setActiveOrganisation();
        }

        const activeOrg = sessionStorage.getItem("organisationTenancyType");
        if(tenancy.includes(activeOrg)){
            await firstValueFrom(this.accountsService.getUser()).then( (user: UserModel) =>{
                var eulaAccepted = sessionStorage.getItem(SessionCode.eulaAccepted)
                if(user.eulaAccepted) {
                    sessionStorage.setItem(SessionCode.eulaAccepted, SessionCode.yes)
                }
                if(!user.eulaAccepted && !(eulaAccepted == SessionCode.yes) && activeOrg == OrganisationTenancyType.RecipientFreemium) {
                    if(!currentPage) {
                        this._router.navigateByUrl('/dashboard');                     
                    }
                    sessionStorage.setItem(SessionCode.eulaAccepted, SessionCode.no)
                    return true
                }
            });
        }else {
            this._router.navigateByUrl('/index')
            return false;
        }
    }
}