
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, catchError, tap, share, delay } from 'rxjs/operators';
import * as Rx from 'rxjs';


import { AuthService } from "@app/auth/_services/auth0.service";
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { ViewContainerRef } from '@angular/core';
import { PrefixModel } from '@app/models/PrefixModel';
import { ContractorSummaryModel } from '@app/models/ContractorSummaryModel';
import { ABNResultModel, NameSearchResult } from '@app/models/ABNLookupModels';
import { FileModel } from '@app/models/FileModel';
import { NotifyContractorModel } from '@app/models/NotifyContractorModel';
import { AccreditationModel, BankGuaranteeModel, InstitutionModel, InsuranceModel, LiabilityModel, SubtradeModel } from '@app/models/ContractModel';
import { ACNResultModel } from '@app/models/ACNLookupModel';



const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class ContractorsService {
    handleError(arg0: any): any {
        throw new Error("ContractorsService Method not implemented.");
    }



    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {
        this.contractors= this.httpclient.get<any>('/api/v1/contractors')
        .pipe(map(res => res))
    }





    private contractors: Observable<ContractorSummaryModel[]>;

    public getContractors(): Observable<ContractorSummaryModel[]> {
        return this.contractors.pipe(shareReplay(1));
    }

    public getArchivedContractors(): Observable<ContractorSummaryModel[]> {     
        return this.httpclient.get<any>('/api/v1/contractors/archived')
        .pipe(map(res => res))      
    }

    public notifyContractor(model: NotifyContractorModel): Observable<any>{
        return this.httpclient.post<any>('/api/v1/contractors/notify', model)
        .pipe(
        tap(c => {
            this.toastr.success('Contractor Notified!');
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }




    public getContractor(id): Observable<ContractorSummaryModel> {
        return this.httpclient.get<any>('/api/v1/contractors/' + id)
            .pipe(map(res => res))
    }

    public deleteContractor(id): Observable<any> {
        return this.httpclient.delete(`/api/v1/contractors/${id}`)
            .pipe(
            tap(c => {
                this.toastr.success("Contractor successfully removed!", "Deleted");
            }),
            catchError((err: any) => {     
                return observableThrowError(this.errorHandler(err))
            }));
    }


    public updateContractor(formData): Observable<ContractorSummaryModel> {
        return this.httpclient.post<any>('/api/v1/contractors', formData)
            .pipe(
            tap(c => {
                this.toastr.success('Contractor ' + c.name + ' Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }



    public addContractor(formData): Observable<ContractorSummaryModel> {
        return this.httpclient.post<any>('/api/v1/contractors', formData)
            .pipe(
            tap(c => {
                this.toastr.success('Contractor ' + c.name + ' Added!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );

    }

    public archiveContractor(archiveData): Observable<boolean> {       
        return this.httpclient.post<any>('/api/v1/contractors/archive', archiveData)
            .pipe(
            tap(c => {
                this.toastr.success("Contractor Archived!");           
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );

    }

    public unarchiveContractor(archiveData): Observable<boolean> {       
        return this.httpclient.post<any>('/api/v1/contractors/unarchive', archiveData)
            .pipe(
            tap(c => {
                this.toastr.success("Contractor Un-Archived!");           
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    errorHandler(error: any): void {
    }

    public updateEntityFile(formData, assetId): Observable<FileModel> {

        return this.httpclient.post<any>(`/api/v1/contractors/${assetId}/files/edit`, formData).pipe(
            tap(res => {
                this.toastr.success(`Document Updated!`)
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public uploadEntityFile(formData, contractorId): Observable<ContractorSummaryModel> {

        return this.httpclient.post<any>(`/api/v1/contractors/${contractorId}/files`, formData).pipe(
            tap(res => {
                this.toastr.success(`A new document has been attached to ${res.name}!`);
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public getApprovedFiles(id): Observable<any> {
        return null;
    }

    public lookupACN(acn): Observable<ACNResultModel> {
        return this.httpclient.get<any>('/api/v1/contractors/acn/' + acn)
            .pipe(map(res => res))
    }

    public lookupABN(abn): Observable<ABNResultModel> {
        return this.httpclient.get<any>('/api/v1/contractors/abn/' + abn)
            .pipe(map(res => res))
    }

    public lookupCompany(company): Observable<NameSearchResult> {
        return this.httpclient.get<any>('/api/v1/contractors/abnbyname/' + company)
            .pipe(map(res => res))
    }

    public deleteEntityFile(contractorId, id): Observable<FileModel[]> {
        return this.httpclient.delete<FileModel[]>(`/api/v1/contractors/${contractorId}/files/${id}`)
            .pipe(res => res)
    }

    public getEntityFile(contractorId,id): Observable<any> {
        return this.httpclient.get(`/api/v1/contractors/files/${id}`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public getinstitutions(): Observable<InstitutionModel[]> {
        return this.httpclient.get<any>(`/api/v1/contractors/institutions`)
        .pipe(map(res => res));
    }

    public addUpdateContractorInsurance(formData): Observable<InsuranceModel> {
        return this.httpclient.post<any>('/api/v1/contractors/updateContractorInsurance', formData)
            .pipe(
                tap(c => {}),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public addUpdateContractorLiability(formData): Observable<LiabilityModel> {
        return this.httpclient.post<any>('/api/v1/contractors/updateContractorLiability', formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public addUpdateContractorSubtrade(formData): Observable<SubtradeModel> {
        return this.httpclient.post<any>('/api/v1/contractors/updateContractorSubtrade', formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public addUpdateContractorBankGuarantee(formData): Observable<BankGuaranteeModel> {
        return this.httpclient.post<any>('/api/v1/contractors/updateContractorBankGuarantee', formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public addUpdateContractorAccreditation(formData): Observable<AccreditationModel> {
        return this.httpclient.post<any>('/api/v1/contractors/updateContractorAccreditation', formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public inlineUpdateContractor(formData): Observable<ContractorSummaryModel> {
        return this.httpclient.post<any>(`/api/v1/contractors/updatecontractorsummary`, formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }
}
