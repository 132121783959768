import { Component, Input, OnInit } from '@angular/core';
import { TerminologytranslatorService } from '../../../services/TerminologyTranslator.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    // encapsulation: ViewEncapsulation.None,
    // styleUrls: ['./grantmanagement.component.css'],
})
export class HeaderComponent implements OnInit {
    @Input() headerText: string = '';
    @Input() homeLink: string = '';
    @Input() addButtonEnabled: boolean = false;
    @Input() addButtonLink: string = '#';

    constructor()
    {}

    ngOnInit(): void {
    }
}