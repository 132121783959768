import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  timer ,  Subject } from 'rxjs';
import { TrainingItemModel } from '../models/TrainingItemModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, tap, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class TrainingService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }



    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }







    public getTrainingItems(): Observable<TrainingItemModel[]> {
        return this.httpclient.get<any>('/api/v1/training')
            .pipe(map(res => res))
    }



}
