export enum FunderApprovalStatusEnum {
   Created = "Created",
   RequestApproval = "Pending Approval",
   Approved = "Approved",
   Rejected = "Rejected",
   Resubmit = "Re-submit",
   ApprovalSubmitted = "Approval Submitted",
   Submitted = "Submitted",
   Signed = "Signed",
   DisbursementRequested = "Disbursement Requested",
   FundsDisbursed = "Funds Disbursed",
   ChangeRequest = "Change Request",
   InProgress = "In Progress",
   RequiredReview = "Required Review",
   Requested = "Requested",
   Disbursed = "Disbursed"
}