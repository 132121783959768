import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  timer ,  Subject } from 'rxjs';
import { NotificationModel } from '../models/NotificationModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, startWith } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';


const REFRESH_INTERVAL = 30000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class NotificationsService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    private cache$: Observable<Array<NotificationModel>>;
    private reload$ = new Subject<void>();





    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }







    // get notifications() {
    //     if (!this.cache$) {

    //         const timer$ = timer(0, REFRESH_INTERVAL);

    //         this.cache$ = timer$.pipe(
    //             startWith(0),
    //             switchMap(() => this.getNotifications()),
    //             takeUntil(this.reload$),
    //             shareReplay(CACHE_SIZE)
    //         );
    //     }
    //     return this.cache$;
    // }



    forceReload() {

        this.cache$ = null;

    }





    // Getting the notifications for the user
    public getNotifications(): Observable<NotificationModel[]> {
        return this.httpclient.get<any>('/api/v1/notifications/my')
            .pipe(
            map(res => res))
    }



    // Getting the tasks for the user
    public getTasks(): Observable<NotificationModel[]> {
        return this.httpclient.get<any>('/api/v1/notifications/tasks')
            .pipe(
            map(res => res))
    }






    public ackNotification(formData) {
        return this.httpclient.post<any>('/api/v1/notifications/remove', formData).subscribe(
            res => {
                this.toastr.success('Notification Acknowledged!');
            },
            err => {
            }
        );
    }





}
