import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonCodeComponent } from '@app/components/commoncode/commoncode.component';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from '../helpers';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AccountsService } from '@app/services/accounts.service';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;
declare let pendo: any;
@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: "./theme.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
    loadbody = false

    constructor(
        private script: ScriptLoaderService, 
        private router: Router,
        private accountsService: AccountsService,
        private commonComponent: CommonCodeComponent,
    ) {
    }

    ngOnInit() {
        this.script.loadScripts('body',
            [
                'assets/vendors/base/vendors.bundle.js',
                'assets/demo/default/base/scripts.bundle.js'
            ], true)
            .then(result => {
                Helpers.setLoading(false);
                // optional js to be loaded once
                // this._script.loadScripts('head', ['assets/vendors/custom/fullcalendar/fullcalendar.bundle.js'], true);    // deleted TW 20180910
            });
        this.router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                (<any>mLayout).closeMobileAsideMenuOffcanvas();
                (<any>mLayout).closeMobileHorMenuOffcanvas();
                Helpers.setLoading(true);
                // hide visible popover
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // init required js
                (<any>mApp).init();
                (<any>mUtil).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                let animation = 'm-animate-fade-in-up';
                $('.m-wrapper').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e) {
                    $('.m-wrapper').removeClass(animation);
                }).removeClass(animation).addClass(animation);
            }
        });
        this.setOrganisation()
        this.loadZenDesk()
    }

    loadZenDesk(){
        let url = "https://static.zdassets.com/ekr/snippet.js?key=3ede9aca-a6b7-4695-b744-6254915b4ffb"
        const body = <HTMLDivElement> document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.id = 'ze-snippet';
        script.async = true;
        script.defer = true;
        body.appendChild(script);
        
        let pendoData = JSON.parse(localStorage.getItem('pendo_data'))
        if(pendoData != null){
            pendo.initialize(pendoData);
        }
    }

    async setOrganisation(){
        this.commonComponent.blockPage(true);
        this.commonComponent.blockRegion('body');
        await this.accountsService.setActiveOrganisation()
        this.loadbody = true;
        this.commonComponent.unblockRegion('body');
        this.commonComponent.unblockPage();
    }
}