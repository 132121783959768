
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Injectable } from '@angular/core';
import { UserModel } from '@app/models/UserModel';
import { AccountsService } from '@app/services/accounts.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [],
})

@Injectable()
export class UserComponent implements OnInit {
    user: UserModel = null;

    constructor(
        private accountsService: AccountsService) {
    }

    ngOnInit() {
        // Force Clear Cache
        this.accountsService.UserClearCache();
        // Subscribe to User Details
        this.accountsService.getUser().subscribe(usr => {
            this.user = usr;
        });
    } 
}
