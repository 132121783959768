import { Component, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class HeaderStore {
    
    header:any = {
        addItem: false,
        addItemLinkButtonTitle: null,
        button: false,
        buttonTitle: null,
        refresh: false,
        filter:false,
        info: false,
        infoText: '',
        exportData: false,
        print: false,
        clone: false,
        cloneDisabled: false,
        delete: false,
        download: false,
        folder: false,
        downloadDisabled: false,
        deleteDisabled: false,
        description: '',
        addButtonArray: false,
        buttonArray: [],
    }

    private refreshButton = new Subject<any>();
    private filterButton = new Subject<any>();
    private addButton = new Subject<any>();
    private addButtonArray = new Subject<any>();
    private infoButton = new Subject<any>();
    private exportDataButton = new Subject<any>();
    private cloneButton = new Subject<any>();
    private deleteButton = new Subject<any>();
    private downloadButton = new Subject<any>();
    private folderButton = new Subject<any>();
    private button = new Subject<any>();
    refreshRequired: boolean = false;

    constructor() {
    }

    enableInfo(infoText:string = ''){
        this.header.info = true;
        this.header.infoText = infoText;
    }

    enablePrint(){
        this.header.print = true;
    }

    runEvent(temp:string) {
        switch(temp) {
            case "refresh":
                this.refreshRequired = false;
                this.refreshButton.next(true);
                break
            case "addItem":
                this.addButton.next(true);
                break
            case "info":
                this.infoButton.next(true);
                break
            case "exportData":
                this.exportDataButton.next(true);
                break
            case "clone":
                this.cloneButton.next(true);
                break
            case "delete":
                this.deleteButton.next(true);
                break
            case "download":
                this.downloadButton.next(true);
                break
            case "button":
                this.button.next(true);
                break
            case "filter":
                this.filterButton.next(true);
                break
            case "addTemplate":
                this.addButtonArray.next('addTemplate')
                break
            case "addCriterion":
                this.addButtonArray.next('addCriterion')
                break
            default:
                this.folderButton.next(true);
        }
    }

    refreshEvent(): Observable<any>{ 
        this.header.refresh = true;
        return this.refreshButton.asObservable();
    }

    filterEvent(): Observable<any>{ 
        this.header.filter = true;
        return this.filterButton.asObservable();
    }

    deleteEvent(enabled = true): Observable<any>{ 
        this.header.delete = true;
        this.header.deleteDisabled = enabled;
        return this.deleteButton.asObservable();
    }

    cloneEvent(disabled:boolean = false): Observable<any>{
        this.header.cloneDisabled = disabled;
        this.header.clone = true;
        return this.cloneButton.asObservable();
    }

    exportDataEvent(): Observable<any>{ 
        this.header.exportData = true;
        return this.exportDataButton.asObservable();
    }

    infoEvent(): Observable<any>{ 
        this.header.info = true;
        return this.infoButton.asObservable();
    }

    addItemEvent(buttonText:string = 'Add item', customClass: string = 'primary-sp-button'): Observable<any>{ 
        this.header.addItemLinkButtonTitle = buttonText;
        this.header.addItem = true;
        this.header.addItemButtonCustomClass = customClass;
        return this.addButton.asObservable();
    }

    addButtonArrayEvent(object:{}): Observable<any>{ 
        this.header.addButtonArray = true;
        object['buttonArray'].forEach(button =>{
            this.header.buttonArray.push(
                {
                    event: button.event,
                    addItemLinkButtonTitle: button.buttonText,
                    addItemButtonCustomClass: button.customClass
                }
            )
        })
        
        return this.addButtonArray.asObservable();
    }

    buttonEvent(buttonText:string): Observable<any>{ 
        this.header.buttonTitle = buttonText;
        this.header.button = true;
        return this.button.asObservable();
    }

    downloadEvent(enabled = true): Observable<any>{ 
        this.header.download = true;
        this.header.downloadDisabled = enabled;
        return this.downloadButton.asObservable();
    }

    folderEvent(): Observable<any>{ 
        this.header.folder = true;
        return this.folderButton.asObservable();
    }

    removeAddItem (){
        this.addButton = new Subject<any>();
        this.header.addItem = false;
    }

    remove(){
        //reset On destroy
        this.refreshButton = new Subject<any>();
        this.addButton = new Subject<any>();
        this.infoButton = new Subject<any>();
        this.exportDataButton = new Subject<any>();
        this.cloneButton = new Subject<any>();
        this.deleteButton = new Subject<any>();
        this.downloadButton = new Subject<any>();
        this.folderButton = new Subject<any>();
        this.button = new Subject<any>();
        this.header = {
            addItem: false,
            addItemLinkButtonTitle: null,
            button: false,
            buttonTitle: null,
            refresh: false,
            info: false,
            infoText: '',
            exportData: false,
            print: false,
            clone: false,
            delete: false,
            deleteDisabled: false,
            downloadDisabled: false,
            description: '',
            download: false,
            folder: false,
            addButtonArray: false,
            buttonArray: []
        }
    }
}
