import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ChartComponent } from './chart.component';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
    ],
    declarations: [
        ChartComponent
    ],
    providers: [

    ],
    exports: [
        ChartComponent
    ]
})
export class ChartModule { }
