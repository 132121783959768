import { MemberModel } from '@app/models/MemberModel';
import { DropdownModel } from '@app/components/reusable/labeledDropdown/labeledDropdown.component';
import { DatepickerOptionModel } from './models/DatepickerOptionModel';
import { EntityTypes } from './constants';
import { EmulateCondition } from '@app/constants';
import { RiskLevelEnum } from '@app/models/RiskLevelEnum';
import { ProjectModel } from './models/ProjectModel';
import { ContractModel } from './models/ContractModel';
import { GrantModel } from './models/GrantModel';
import { PortfolioModel } from './models/PortfolioModel';
import { ContractAwardModel } from './models/ContractAwardModel';
import { FunderApprovalStatusEnum } from './models/FunderApprovalStatusEnum';
import { RiskFactorAnswerSetOptionModel } from './models/RiskFactorAnswerSetOptionModel';
import { FormIOModel } from './models/FormIOModel';
import * as moment from 'moment';

export default class Utils {

    private static dpOption: any = { name: "", dateFormat: 'dd/mm/yyyy', disableUntil: { year: 0, month: 0, day: 0, fullDate: null }, disableSince: { year: 0, month: 0, day: 0, fullDate: null } };   

    static compareFn(item1: any, item2: any): boolean {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }

    public static subtractWorkingDays(dateTime, numToSubtract){      
      for (let i = 1; i <= numToSubtract; i++) {
          dateTime.setDate(dateTime.getDate() - 1);
          if (dateTime.getDay() === 6) {
              dateTime.setDate(dateTime.getDate() -1 );
          }
          else if (dateTime.getDay() === 0) {
              dateTime.setDate(dateTime.getDate() -2);
          }
      }
    }

    public static addWorkingDays(dateTime, numToAdd){      
      for (let i = 1; i <= numToAdd; i++) {
          dateTime.setDate(dateTime.getDate() + 1);
          if (dateTime.getDay() === 6) {
              dateTime.setDate(dateTime.getDate() +2);
          }
          else if (dateTime.getDay() === 0) {
              dateTime.setDate(dateTime.getDate() +1);
          }
      }
    }

    public static deepClone(obj,clone) {

        // return value is input is not an Object or Array.
        if (typeof(obj) !== 'object' || obj === null) {
          return obj;    
        }  
        
    
    
        let keys = Object.keys(clone);
    
        for (let i=0; i<keys.length; i++) {
          obj[keys[i]] = clone[keys[i]];
          
          Utils.deepClone(obj[keys[i]],clone[keys[i]]); // recursively unlink reference to nested objects.
        }
    
        return obj; // return unlinked clone.
    
    }

    public static filterDeliverable(name: string, deliverable: any[]) {
      var filtered = [];
      var letterMatch = new RegExp(name, 'i');
       
      for (var i = 0; i < deliverable.length; i++) {
          var item = deliverable[i];
          let isMatch = (val) => letterMatch.test(val?.toLowerCase().substring(0, val.length));
          let isManagerMatch = (manager) => manager && isMatch(`${item.manager.firstname} ${item.manager.lastname}`);
          let isOwnerMatch = (owner) => owner && isMatch(`${item.owner.firstname} ${item.owner.lastname}`);
          let score = item.overallRiskScore ? item.overallRiskScore : item.overallRiskScoreCalculated;
          let riskInfo = Utils.RiskLevels(Math.round(score));  
          let riskLevel = riskInfo.state;
          
          if ((item.awardedTo && isMatch(item.awardedTo)) ||(item.currentWorkflowStepDescription && isMatch(item.currentWorkflowStepDescription)) || isMatch(item.name) || isMatch(riskLevel)  || isMatch(item.reference) || (item.prefixName && isMatch(item.prefixName)) || isManagerMatch(item.manager) || isOwnerMatch(item.owner)) {
              filtered.push(item);
          }
      }

      return filtered;
  }

  public static filterSummaryTable(filterTerm: string, data: any[], exclusionList: any[]) {
    var filtered = [];
    var subfiltered = [];
    var letterMatch = new RegExp(filterTerm.replace(/[+?^()|[\]\\]/g, '\\$&'), 'i');
    let isMatch = (val) => letterMatch.test(val.toLowerCase().substring(0, val.length));
    if(!exclusionList.some(x=>x=='id')){
      exclusionList.push('id');
      exclusionList.push('levelRank');
      exclusionList.push('levelRankColor');
      exclusionList.push('workflowRank');
      exclusionList.push('overallRiskScore');
      exclusionList.push('overallRiskScoreCalculated');
    }
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        var matchFound = false;

        Object.entries(item).forEach(([key, value]) => {
          if(value instanceof Array && !exclusionList.includes(key)){
            var subitems = Utils.filterSummaryTable(filterTerm, value, exclusionList) ;
            if(subitems.length > 0){            
              subfiltered.push(item);      
              matchFound = true;      
            }
          } else if(value instanceof Object && !exclusionList.includes(key)){ 
            var subitems = Utils.filterSummaryTable(filterTerm, [value], exclusionList) ;
            if(subitems.length > 0){            
              subfiltered.push(item);      
              matchFound = true;      
            }
          }
          else if(value && !exclusionList.includes(key) && isMatch(value.toString()))
          { 
              if(!matchFound) {
                filtered.push(item);
              }
              matchFound = true;
          }
        });
    }
    subfiltered = subfiltered.filter(x=> !filtered.some(y=>x.id==y.id));
    filtered = [...new Map(filtered.concat(subfiltered).map(item => [item.id, item])).values()];
    return filtered.sort((a,b)=> a.level-b.level);
  }
  
    public static RiskLevels(score){
      var state = EmulateCondition.Unassigned;
      var riskColor: string = '#fffffff';
      var riskFontColor: string = '#00000';

        switch (true) {
            case (score >= 67 && score <= 100):
                state = RiskLevelEnum.VeryHigh;
                riskColor = '#dc3545';
                riskFontColor = '#fffffff';
                break;
            case (score >= 49 && score < 67):
                state = RiskLevelEnum.High;
                riskColor = '#fc7303';
                riskFontColor = '#fffffff';
                break;
            case (score >= 26 && score < 49):
                state = RiskLevelEnum.Medium;
                riskColor = '#ffc107';
                riskFontColor = '#00000';
                break;
            case (score !== -1 && score >= 0 && score < 26):             
                state = RiskLevelEnum.Low;
                riskColor = '#28a745';
                riskFontColor = '#fffffff';
                break;
            case (score == -1):
                state = EmulateCondition.Unassigned;
                riskColor = '#f2f2f2';
                riskFontColor = '#00000';
                break;
            case (score < 0):                         
                state = EmulateCondition.Unassigned;
                riskColor = '#f2f2f2';
                riskFontColor = '#00000';
                break;
        }

        return { state, riskColor, riskFontColor };

    }

    public static deepEquals(x, y) {
        if (x === y) {
          return true; // if both x and y are null or undefined and exactly the same
        } else if (!(x instanceof Object) || !(y instanceof Object) &&  (x!== null  && y !==null)) {
          // Get the local time and utc different
          var z = new Date();
          var z1 = new Date();
          if(x instanceof Date || y instanceof Date ){
              if(!(x instanceof Date )){
                var txt = x != null ? x.replace('Z','') :  null;
                z1=new Date(txt+ 'Z');
                // back to the local time
                
              }
              else {
                z1= new Date(x);
              }
              if(!(y instanceof Date )){
                var txt = y!= null? y.replace('Z','') : null;
                 z=new Date(txt+'Z');
                  // back to the local time
                //y.setHours(hoursDiff);
                //y.setMinutes(minutesDiff);
              }
              else{
                z= new Date (y);
              }
              return JSON.stringify(z1).toLocaleLowerCase()===JSON.stringify(z).toLocaleLowerCase();
          }
       
          return false; // if they are not strictly equal, they both need to be Objects
        } else if ( (x!== null  && y !==null) && x.constructor !== y.constructor ) {
          // they must have the exact same prototype chain, the closest we can do is
          // test their constructor.
        
          return false;
        } else {          
            if ( (x instanceof Date && y ==null) || (x== null  && y instanceof Date)){
                return false;
            }
            else if ( (x instanceof Date && y instanceof Date)){
              //x = Utils.recreateLocalDate(x);
            //  y = Utils.recreateLocalDate(y);
              return JSON.stringify(x).toLocaleLowerCase()===JSON.stringify(y).toLocaleLowerCase();
            }
            for (const p in x) {
                if (x==null||!x.hasOwnProperty(p)) {
                continue; // other properties were tested using x.constructor === y.constructor
                }
                if ( y==null||!y.hasOwnProperty(p) ) {   
                continue
                }
                if ((x[p] === null && y[p] === undefined )||
                (x[p] === undefined && y[p] === null) ||
                (x[p] === undefined && y[p] === "0001-01-01T00:00:00") ||
                (x[p] === "0001-01-01T00:00:00"&& y[p] === undefined  )||
                (x[p] === null && y[p] === "0001-01-01T00:00:00") ||
                (x[p] === "0001-01-01T00:00:00"&& y[p] === null  )                               
                ) {
                  continue; // if they have the same strict value or identity then they are equal
                }
                if (x[p] === y[p]) {
                continue; // if they have the same strict value or identity then they are equal
                }
                if (typeof (x[p]) !== 'object'&& x[p]!= undefined) {
                return false; // Numbers, Strings, Functions, Booleans must be strictly equal
                }
                if (!Utils.deepEquals(x[p], y[p]) ) {
                return false;
                }
            }
            for (const p in y) {
                if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
        
                continue;
                }
            }
            return true;
            }
      }

    public static equalsNoOfProperties(x, y) {
      return JSON.stringify(x) === JSON.stringify(y);
    }

    public static sortMembers(members: MemberModel[]) {
        members.sort(function (a, b) {
            var nameA = a.user.firstname.toUpperCase();
            var nameB = b.user.firstname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    public static sortDropdownList(list: DropdownModel[]) {
        list.sort(function (a, b) {
            var nameA = a.displayValue.toUpperCase();
            var nameB = b.displayValue.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    public static convertDate(selectedDate: any): Date {
      if(selectedDate){
        var date = new Date(selectedDate);
        if(date.getFullYear() != 1 && date.getFullYear() != 1901){
          return date;
        }
      }         
      return undefined;
   }

   public static convertUTC(selectedDate: any): Date {
      return !selectedDate ? undefined : new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));
   }
    
  public static onStartDatePickerSelected(selectedDate: Date, optionName: string, disableSince: any = null ): DatepickerOptionModel {
    var copy: any = JSON.parse(JSON.stringify(this.dpOption));
    copy["name"] = optionName;

    if (!selectedDate) {
      copy.disableUntil = { day: 1, month: 1, year: 1900, fullDate: new Date(1900, 1, 1) };
      if(disableSince!= null){
        copy.disbleSince = disableSince;
      }
      selectedDate = null;
    }
    else {
      //set temp variable to avoid circular ref
      var dateTemp = new Date(selectedDate.toDateString());

      copy.disableUntil = { day: selectedDate.getDate() + 1, month: selectedDate.getMonth(), year: selectedDate.getFullYear(), fullDate: new Date(dateTemp.setDate(dateTemp.getDate() + 1)) };
      if(disableSince!= null){
        copy.disbleSince = disableSince;
      }
      selectedDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));
    }

    var datepickerOptionModel : DatepickerOptionModel = {convertedDate: selectedDate, dpOption: copy}
    return datepickerOptionModel;
  }

  public static onEndDatePickerSelected(selectedDate: Date, optionName: string, entity?: any, prop?: string, disableUntil: any = null): any {

    var copy: any = JSON.parse(JSON.stringify(this.dpOption));
    copy["name"] = optionName;

    if (!selectedDate) {
      copy.disableSince = { day: 1, month: 1, year: 1900, fullDate: new Date(2199, 12, 31) };
      if(disableUntil!= null){
        copy.disableUntil = disableUntil;
      }
      selectedDate = null;
    }
    else {
      //set temp variable to avoid circular ref
      var dateTemp = new Date(selectedDate.toDateString());

      copy.disableSince = { day: selectedDate.getDate() - 1, month: selectedDate.getMonth(), year: selectedDate.getFullYear(), fullDate: new Date(dateTemp.setDate(dateTemp.getDate() - 1)) };
      if(disableUntil!= null){
        copy.disableUntil = disableUntil;
      }
      selectedDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));
    }

    var datepickerOptionModel : DatepickerOptionModel ={convertedDate: selectedDate, dpOption: copy}
    return datepickerOptionModel;
  }
     
  public static validateDateRequired(entity: object, entityType: string): boolean {

    switch (entityType) {

      case EntityTypes.project:
        return this.isProjectContactDatesValid(entity, entityType);

      case EntityTypes.contract:
        return this.isProjectContactDatesValid(entity, entityType);

      case EntityTypes.grant:
        return this.isGrantDatesValid(entity);

      case EntityTypes.portfolio:
        return this.isPortfolioDatesValid(entity);

      case EntityTypes.contractAward:
        return this.isContractAwardDatesValid(entity);

      default:
        return false;
    }

  }

  public static isProjectContactDatesValid(entity: object, entityType: string) : boolean
  {
    var entityTemp = entityType == EntityTypes.project ? entity as ProjectModel : entity as ContractModel;
    return !entityTemp.commencementEstimate || !entityTemp.pcEstimate;
  }

  public static isGrantDatesValid(entity: object) : boolean
  {
    var entityTemp = entityTemp = entity as GrantModel;
    return !entityTemp.openDate || !entityTemp.closeDate;
  }
  
  public static isPortfolioDatesValid(entity: object) : boolean
  {
    var entityTemp = entityTemp = entity as PortfolioModel;
    return !entityTemp.estimateStartDate || !entityTemp.estimateEndDate;
  }

  public static isContractAwardDatesValid(entity: object) : boolean
  {
    var entityTemp = entityTemp = entity as ContractAwardModel;
    return !entityTemp.awardedOn || !entityTemp.contractStartDate || !entityTemp.contractEndDate;
  }

  public static removeListObjectFromListOfObject(array :any[] , itemsToRemove:any[]):any[]
  {
        return array.filter(v => { 
          return !itemsToRemove.includes(v); 
        });
  }
  
  public static removeObjectFromListOfObject(array :any[] , itemToRemove:any):any[]
  {
        return array.filter(v => { 
          return v != itemToRemove; 
        });
  }

  public static isEntityRegistered(id: string) {
      return id != undefined && id != '00000000-0000-0000-0000-000000000000';
  }
  
  public static dateFormat(date,withTime = false){
    if(date != undefined)
    {
      if(date == "0001-01-01T00:00:00"){ //blank dates that has been inserted in the database
        return ''
      }
      if(date && typeof date == 'string'){
        var convertedDate = this.convertDate(date)?.toLocaleDateString('en-AU') // "en-AU": "dd/MM/yyyy" Format
        if(withTime){
          convertedDate = convertedDate + ' ' +this.convertDate(date)?.toLocaleTimeString('en-AU').toUpperCase();
        }
        if(convertedDate=="Invalid Date"||convertedDate==undefined){
          return date
        }
        return convertedDate.replace(/:\d{2}(?=\s[APM]+)/, '');
      }
    }
    return ''
  }

  public static formatApprovalDate (date) 
  {
    return new Date(new Date(date).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toLocaleDateString()
  }
  public static formatApprovalTime (date) {
    return new Date(new Date(date).getTime() - new Date().getTimezoneOffset() * 60 * 1000).toLocaleTimeString();
  } 

  public static currencyFormat(num){
    try { 
      if(typeof num == 'string'){
        num = parseFloat(num)
      }
      if(isNaN(num)) {
        num = 0;
      }
      return '$' + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
    }
    catch { 
      return ''
    }
  }

  public static getEnumKeyByEnumValue(enumObject: any ,enumValue: string) {
    const indexOfS = Object.values(enumObject).indexOf(enumValue as unknown as typeof enumObject);
    return Object.keys(FunderApprovalStatusEnum)[indexOfS];
  }

  //TODO: Refactor that into the base class
  public static checkFundingAmount(){        
    const fundingAllocationAmountElement = document.querySelector("input[name='data[fundingAllocationAmount]']") as any;;
    const totalFundingAvailableElement= document.querySelector("input[name='data[totalFundingAvailable]']") as any;
    const messageContent = document.querySelector(".formio-component-error") as any;
    var $buttonSubmit = $("button[name='data[submit]']");
    var totalFundingAvailableVal = totalFundingAvailableElement.value;
    var fundingAllocationAmountVal = fundingAllocationAmountElement.value;        
    var $html =$("input[name='data[totalFundingAvailable]']");
    messageContent.setAttribute("style", "color:Red");  
    if(fundingAllocationAmountVal > totalFundingAvailableVal ){           
        $html.addClass('is-invalid');  
        $buttonSubmit.addClass('btnDisabled') 
        messageContent.textContent = "Total Funding Amount must be greater than the Funding Allocation Amount";
    }
    else{
        $html.removeClass('is-invalid');   
        $buttonSubmit.removeClass('btnDisabled');
        messageContent.textContent = '';
    }
 }

  public static selectDefaultBudget(baseBudget: number, answerSetList: Array<RiskFactorAnswerSetOptionModel>): RiskFactorAnswerSetOptionModel {
    var riskFactorAnswerSetOptionModel: RiskFactorAnswerSetOptionModel;

    for (var i = 0; i < answerSetList.length; i++) {
      var isMillionRange = answerSetList[i].answer.includes('m');
      var answerBudgetRange = Number(answerSetList[i].answer.split('to')[1]?.replace(/[$,m, ]/g, ''));

      //if the range is in million multiply answerBudgetRange into  1000000
      answerBudgetRange = isMillionRange ? answerBudgetRange * 1000000 : answerBudgetRange;

      if (baseBudget < answerBudgetRange || answerSetList[i].answer.includes('More than')) {
        riskFactorAnswerSetOptionModel = answerSetList[i];
        break;
      }
    }

    return riskFactorAnswerSetOptionModel;
  }

  public static selectDefaultTerm(startDate: Date, endDate: Date, answerSetList : Array<RiskFactorAnswerSetOptionModel>): RiskFactorAnswerSetOptionModel {
    var riskFactorAnswerSetOptionModel: RiskFactorAnswerSetOptionModel;

    for (var i = 0; i < answerSetList.length; i++) {      
      var fromDate = new Date(startDate);
      var toDate = new Date(endDate);
  
      var yearsComputed = Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())
       - Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()) ) /(1000 * 60 * 60 * 24))/365;
       
      var isMonthRange =  answerSetList[i].answer.includes('months');
      var answerTermRange = Number(answerSetList[i].answer.split('to')[1]?.replace(/[ months, Months, Years, years]/g, ''));

      //set answerTermRange into 1yr once option is using 12 months
      answerTermRange = answerTermRange == 12 && isMonthRange ? 1 : answerTermRange;

      if (yearsComputed < answerTermRange || answerSetList[i].answer.includes('over')) {
        riskFactorAnswerSetOptionModel = answerSetList[i];
        break;
      }
    }

    return riskFactorAnswerSetOptionModel;
  }
  
  public static workflowRank(template,step){
    var workflowOrder = template.steps.find(i => i.stage.description == step);
    if(workflowOrder != null){
      return workflowOrder.order
    }else{
      return -1
    }
  }

  public static getRiskScores(riskScore,riskScoreMax) {
    var score = Math.round((riskScore / riskScoreMax) * 100.0);
    const status:any = this.getStateValue(score);
    return { state:status.state, projectRiskColor: status.projectRiskColor };
  }

  public static getStateValue(score){
    let state = EmulateCondition.Unassigned;
    let projectRiskColor = '#f2f2f2';
    switch (true) {
      case (score >= 67 && score <= 100):
          state = RiskLevelEnum.VeryHigh;
          projectRiskColor = '#dc3545';
          break;
      case (score >= 49 && score < 67):
          state = RiskLevelEnum.High;;
          projectRiskColor = '#fc7303';
          break;
      case (score >= 26 && score < 49):
          state = RiskLevelEnum.Medium;
          projectRiskColor = '#ffc107';
          break;
      case (score !== -1 && score >= 0 && score < 26):
          state = RiskLevelEnum.Low;
          projectRiskColor = '#28a745';
          break;
      case (score == -1):
          state = EmulateCondition.Unassigned;
          projectRiskColor = '#f2f2f2';
          break;
      case (score < 0):
        state = EmulateCondition.Unassigned;
          projectRiskColor = '#f2f2f2';
          break;
    }
    return { state: state, projectRiskColor: projectRiskColor };
  }

  public static abbreviateNumber(number){
      var SI_SYMBOL = ["", "k", "m", "G", "T", "P", "E"];
      var tier = Math.log10(Math.abs(number)) / 3 | 0;
      if(tier == 0) return "$"+number;
      var suffix = SI_SYMBOL[tier];
      var scale = Math.pow(10, tier * 3);
      var scaled = number / scale;
      return "$"+scaled.toFixed(1) + suffix;
  }

  public static lettersOnlyValidator(event: KeyboardEvent) {
    const input = event.key;
    if (!/[a-zA-Z\s]/.test(input)) {
      event.preventDefault();
    }
  }

  public static  numbersOnlyValidator(event: KeyboardEvent) {
    const input = event.key;
    if (!/[0-9]/.test(input)) {
      event.preventDefault();
    }
  }
  

  public static timeSince(date:any) {
    // functon to display mins/hrs ago or Just now if date is within 24hrs, else display the date
    const datePassed = new Date(date).toISOString().toLocaleString();
    var dateNow = new Date().toUTCString().split("GMT");
    var seconds = Math.floor((Date.parse(dateNow[0])-Date.parse(datePassed)) / 1000);
    var interval = seconds / 86400;
    if (interval > 1) {
      return this.dateFormat(date)
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hrs ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " mins ago";
    }
    return "Just now";
  }

  public static capitalizeFirstLetter(str) {
    return  str.split(' ')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  }

  public static preventApiCall(params = []) {
    for (let i = 0; i < params.length; i++) {
      if (!params[i]) {
        return false
      }
    }
    return true
  }

  public static convertUTCDateToLocalDate(dateString) {
    if(dateString instanceof Date) 
    {
      const tempDate = new Date(Date.UTC(dateString.getFullYear(), dateString.getMonth(), dateString.getDate()));
      return moment(tempDate).format('DD/MM/YYYY')
    }
    
    const date = new Date(dateString);
    return moment(date).format('DD/MM/YYYY')
  }

  public static convertUTCDateToLocalDateWithTime(dateString: string): string {
    const date = new Date(dateString + 'Z'); //added Z to know that datetime is in UTC. 
    const options:object = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // use 24-hour format
    };
    const localDate = date.toLocaleString('en-AU', options); //'en-AU' format for: "dd/MM/yyyy hh:mm am/pm" Format);

    // Split the date and time parts
    const [datePart, timePart] = localDate.split(', ');
    const [day, month, year] = datePart.split('/');

    // Reconstruct the date in "dd/MM/yyyy hh:mm" format
    return `${day}/${month}/${year} ${timePart.slice(0, 5)}`;
  }

  public static removeFragment(document:any)
  {
    if(document.location.hash != '')
    {
      document.location.hash = '';
    }
  }

  public static AddReturnMissingRequiredData(lookUpComponent:any,formIOModel:FormIOModel):string {
    //check the component if required and not disabled
    if (lookUpComponent['validate'].required == true && lookUpComponent['disabled'] == false && lookUpComponent['attributes']['disabled'] == undefined) {

      //check the data of the required enable component field if has a value, if does not have data included this in list
      if (formIOModel['data']['data'][lookUpComponent['key']] == '' || formIOModel['data']['data'][lookUpComponent['key']] == undefined) {
        return '"' + lookUpComponent['label'] + '"';
      }
    }
  }

  public static checkFormioComponentRequiredData(formIOModel: FormIOModel) : string[]
  {
    var fieldMissingData = [];
    formIOModel.components.forEach(component => {
      var missingComponentData = '';

      if(component['type'] == 'columns') {
        component['columns'].forEach(column => {
          let accepted = this.AddReturnMissingRequiredData(column['components'][0],formIOModel);
          if(accepted != '' && accepted != undefined) {
            missingComponentData = accepted;
          }
        })
      } else if(component['type'] == 'panel') {
        component['components'].forEach(childComponent => {
          let accepted = this.AddReturnMissingRequiredData(childComponent,formIOModel);
          if(accepted != '' && accepted != undefined) {
            missingComponentData = accepted;
          }
        })
      } else {
        missingComponentData = this.AddReturnMissingRequiredData(component,formIOModel);
      }

      if(missingComponentData != '' && missingComponentData != undefined) {
        fieldMissingData.push(missingComponentData);
      }

    });

    return fieldMissingData; 
  }

  public static hideFormioInternalComponents(formIOModel: FormIOModel)
  {
    formIOModel.components?.forEach(component => {
      if(component['customClass'].match("internal-fields-only"))
        {
          component['hidden'] = true;
        }        
    });

    return formIOModel
  }  
  
  public static hideFormioComponentByListComponent(formIOModel: FormIOModel, keys: string[]): FormIOModel 
  {
    //we are only disable Template default fields
    formIOModel.components.forEach(component => {
      if (component['key'] == 'columns') {
        component['columns'].forEach(column => {
          column['components'].forEach(columnComponent => {

            var componentFiltered = keys.filter(x => x == columnComponent['key']);

            if (componentFiltered.length != 0) {
              columnComponent['hidden'] = true;
            }
          })
        });
      }
      else {
       
        var componentFiltered = keys.filter(x => x == component['key']);

        if (componentFiltered.length != 0) {
          component['hidden'] = true;
        }
      }
    });

    return formIOModel;
  }

  
  public static showFormioComponentByListComponent(formIOModel: FormIOModel, keys: string[]): FormIOModel 
  {
    //we are only disable Template default fields
    formIOModel.components?.forEach(component => {
      if (component['key'] == 'columns') {
        component['columns'].forEach(column => {
          column['components'].forEach(columnComponent => {

            var componentFiltered = keys.filter(x => x == columnComponent['key']);

            if (componentFiltered.length != 0) {
              columnComponent['hidden'] = false;
            }
          })
        });
      }
      else {
       
        var componentFiltered = keys.filter(x => x == component['key']);

        if (componentFiltered.length != 0) {
          component['hidden'] = false;
        }
      }
    });

    return formIOModel;
  }

  public static disableFormioChildComponents(component, includeUserFields: boolean) {
    //disable columns component
    component['components'].forEach(component => {
      if (!includeUserFields && component['customClass'].includes('template-default-field')) {
        //disable only default fields
        component['disabled'] = true;
        component['attributes'] = { disabled: true };
        component['customClass'] = component['customClass'] + ' formio-disabled';
      }
      else {
        //disable only all fields including user fields, this is use on review modal
        component['disabled'] = true;
        component['attributes'] = { disabled: true };
        component['customClass'] = component['customClass'] + ' formio-disabled';
      }
    })
  }

  public static disableFormioComponent(formIOModel: FormIOModel,includeUserFields:boolean): FormIOModel {
    //TODO look for discussion with product team, regarding on sectioning Funder, Opp and permanent disable/enable fields to make logical codes more simplier
    //TODO after discussion with product team and agreed on sectioning fields do Refactore on this method

    if (formIOModel.components != null) {
      formIOModel.components.forEach(component => {
        if (component['type'] == 'columns') {
          component['columns'].forEach(column => {
            this.disableFormioChildComponents(column, includeUserFields);
          });
        }
        else if (component['type'] == 'panel') {
          this.disableFormioChildComponents(component, includeUserFields);
        }
        else {

          //avoid disabling label url link
          if (component['customClass'].includes('formio-link')) {
            // remove formio text color class to show real font color of link text
            component['customClass'] = component['customClass'].replace('formio-template-text-color', '');
          }
          else {
            if (!includeUserFields && component['customClass'].includes('template-default-field')) {
              //disable only default fields
              component['disabled'] = true;
              component['attributes'] = { disabled: true };
            }
            else {
              //disable only all fields including user fields, this is use on review modal
              component['disabled'] = true;
              component['attributes'] = { disabled: true };
            }
            component['customClass'] = component['customClass'] + " formio-disabled"
          }
        }
      });
    } 

    return formIOModel;
  }
  
  public static disableFormioComponentByKeys(formIOModel: FormIOModel, keys: string[]): FormIOModel {
    //TODO look for discussion with product team, regarding on sectioning Funder, Opp and permanent disable/enable fields to make logical codes more simplier
    //TODO after discussion with product team and agreed on sectioning fields do Refactore on this method

    //we are only disable Template default fields
    keys.forEach(key =>{

      if (key == 'saveChanges' || key == 'submit') {
        formIOModel.components.filter(x => x['key'] == 'columns')?.forEach( column =>{
          column['columns'].forEach( components => {
            if (components['components'].filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0] != undefined) {
              components['components'].filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['attributes'] = { disabled: '' };
              components['components'].filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['disabled'] = true;
            }
          });
        });
      } else {
        if (formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field')) != undefined) {
          formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['attributes'] = { disabled: '' };
          formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['disabled'] = true;
        }
      }
    })

    return formIOModel;
  }

  public static removeValidClassFormioHtmlElementById(component:FormIOModel) {
    var element = document.getElementById(component['id']);
    if (element != null) { 
      element['className'] = element['className'].replace(' formio-valid ', ''); 
    }
  }

  public static setValidClassFormioHtmlElementById(component:any) {
    var element = document.getElementById(component['id']);
    if(element != null)
    {
      element['className'] = element['className'].replace(' formio-invalid ', '');

      if (!element['className'].match('formio-valid')) {
        element['className'] += ' formio-valid ';
      }
    }
  }
  
  public static setValidClassFormioHtmlElementByKeys(formIOModel: FormIOModel, keys: string[]):FormIOModel{

    // setting formio component as valid in initial view when field has a value , this will remove the red border line
    keys.forEach(key => {
      
      if (key.includes('columns')) {
        var columnComponentKeys =  JSON.parse(key);
        var columnKey= Object.keys(columnComponentKeys)[0];
        
        columnComponentKeys[columnKey].forEach(itemKey =>{

          var columnsComponents = formIOModel.components.filter(x=> x['key'] == columnKey)[0]['columns'];
          var iscomponentRequired = false;

          columnsComponents.forEach(columnsComponent => {
            if(columnsComponent['components'][0].key == itemKey && columnsComponent['components'][0].validate?.required)
            {
              document.getElementById(columnsComponent['components'][0].id).className += ' formio-valid ';
            }
          })
        })
      }else
      {
        //TODO place here the code for setting  formio base component(not inside of columns component) as valid when required and has value fields in initial view  
      }
    })
    return formIOModel;
  }

  public static checkFormioComponentInvalid(args)
  {
    if(args['changed']!= undefined )
    {
      var componentType = args['changed'].component['type'];

      if (componentType != 'selectboxes' && args['changed']?.value != undefined && args['changed']?.value.toString().trim() != '') {
        //implement class for text input, textarea, number input
        this.setValidClassFormioHtmlElementById(args['changed'].component);
      }
      else if (componentType == 'selectboxes' && args['changed']?.value != undefined) {

        //filter if there is a option selected
        var optionSelected = false;
        Object.keys(args['changed'].value).forEach(function (key, index) {
          if (args['changed'].value[key] == true) {
            optionSelected = true;
          }
        });

        if (optionSelected) {
          this.setValidClassFormioHtmlElementById(args['changed'].component);
        }
        else {
          this.removeValidClassFormioHtmlElementById(args['changed'].component);
        }

      }
      else {
        this.removeValidClassFormioHtmlElementById(args['changed'].component);
      }
    }
  }

  public static enableFormioComponentKeys(formIOModel: FormIOModel, keys: string[]): FormIOModel {
    //TODO look for discussion with product team, regarding on sectioning Funder, Opp and permanent disable/enable fields to make logical codes more simplier
    //TODO after discussion with product team and agreed on sectioning fields do Refactore on this method
    
    //we are only enable Template default fields
    keys.forEach(key =>{
      //columns components
      if (key.includes('columns')) {
        var columnsComponentKeys = JSON.parse(key);
        var columnKey= Object.keys(columnsComponentKeys)[0];
        var columnComponent = formIOModel.components.filter(x => x['key'] == columnKey )[0];

        if (columnComponent && columnComponent['type'] == 'columns') {
          //disable columns component
          columnComponent['columns'].forEach(column => {
              column['components'].forEach(component => {
                var isComponentExist = columnsComponentKeys[columnKey].includes(component['key']);

                if (isComponentExist) {
                  component['disabled'] = false;
                  component['attributes'] = {};
                  component['customClass'] = component['customClass'].replace(' formio-disabled ', ' ');
                }
              })
            });
          }
      }
      else if (key == 'saveChanges' || key == 'submit') {
        // base butons
        if (formIOModel.components != null) {
          formIOModel.components.filter(x => x['key'] == 'columns')?.forEach(column => {
            column['columns'].forEach(components => {
              if (components['components'].filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0] != undefined) {
                components['components'].filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['attributes'] = {};
                components['components'].filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['disabled'] = false;
              }
            });
          });
        }
      } else {
        //normal components
        if (formIOModel.components?.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0] != undefined) {
          formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['attributes'] = {};
          formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['disabled'] = false;   
          var trimmedCustomClass = formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['customClass'].replace("formio-disabled","");    
          formIOModel.components.filter(x => x['key'] == key && x['customClass'].includes('template-default-field'))[0]['customClass'] = trimmedCustomClass;
        }
      }
    })

    return formIOModel;
  }

  public static copyMessage(val: string){
    const hiddentTextBox = document.createElement('textarea');
    hiddentTextBox.style.position = 'fixed';
    hiddentTextBox.style.left = '0';
    hiddentTextBox.style.top = '0';
    hiddentTextBox.style.opacity = '0';
    hiddentTextBox.value = val;
    document.body.appendChild(hiddentTextBox);
    hiddentTextBox.focus();
    hiddentTextBox.select();
    document.execCommand('copy');
    document.body.removeChild(hiddentTextBox);
  }

  public static getTotalDays(starteDate: Date, endDate: Date)
  {
    var Time = endDate.getTime() - starteDate.getTime();
    var totalDays = (Time / (1000 * 3600 * 24));
    return totalDays;
  }

  public static returnYouIfUserEqualToCurrentUser(currentUser,compareToUser):string{
      return currentUser?.displayName == compareToUser?.displayName ? 'You' : compareToUser.displayName ;
  }

  public static getHalfSizeOfTheScreen()
  {
    return (window.innerWidth/2);
  }     

  public static disableBaseHtmlScroll()
  {
    document.getElementsByTagName('html')[0].classList.add('disable-scroll');
  }

  public static enableBaseHtmlScroll()
  {
    document.getElementsByTagName('html')[0].classList.remove('disable-scroll');
  }
}