<div class="m-portlet m-portlet--head-sm">
    <div>
        <div class="m-stack m-stack--ver m-stack--general">
            <div class="m-stack__item m-stack__item--left" style="width: 30px;">
                <div class="m-stack__item m-stack__item--center m-stack__item--middle">
                    <div mat-icon-button [matMenuTriggerFor]="menu">
                        <i class="la la-cog"></i>
                    </div>
                    <mat-menu #menu="matMenu">
                        <div mat-menu-item (click)="getData()">
                            <i class="la la-refresh"></i>
                            <span class="content">
                                Refresh
                            </span>
                        </div>
                        <div mat-menu-item disabled>
                            <span>Filter</span>
                        </div>
                        <button mat-menu-item disabled>
                            <span>Export</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="m-stack__item m-stack__item--center m-stack__item--middle">
                <div>
                    <!-- <small>{{ chartId }}</small> 
                    <br />-->
                    <small>{{ type }}</small>
                    <br />
                    <small>{{ source }}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">
        <div class="m-stack__item m-stack__item--center m-stack__item--middle">
            <div *ngIf="chart; then showChart; else showNoChart">
            </div>
            <ng-template #showChart>
                <div *ngIf="chart">
                    <canvas id="{{_chartId}}" width="1" height="1" style="height:1px;width:1px;">{{ chart }} </canvas>
                </div>
            </ng-template>
            <ng-template #showNoChart>
                <p>
                    No Chart Data
                </p>
            </ng-template>
        </div>
    </div>
</div>



