
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { GlobalErrorHandler } from '../_services/error-handler.service';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {


    constructor(
        public errorHandler: GlobalErrorHandler,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
        if (request.url.startsWith('/api/')) {
            // DEV
            //request = request.clone({ url: `http://localhost:14602${request.url}` });
            //request = request.clone({ url: `http://10.1.1.24:8080${request.url}` });
            //        request = request.clone({ url: `https://surepactapi-dev.azurewebsites.net${request.url}` });
            //request = request.clone({ url: `https://surepactgw.azure-api.net/dev${request.url}` });
          
            //    request = request.clone({ url: `https://surepactapi-red.azurewebsites.net${request.url}` });
            //request = request.clone({ url: `https://spapitst.azurewebsites.net${request.url}` });
            // Pilot
            //request = request.clone({ url: `https://surepactgw.azure-api.net/prod${request.url}` });
            if(request.url.indexOf("/public/") ==-1){
                request = request.clone({ url: `${environment.apiUrl}${request.url}` });
                request = request.clone({
                    setHeaders: {
                        'Ocp-Apim-Subscription-Key': `${environment.apiKey}`,
                        'Authorization': `Bearer ${localStorage.getItem("id_token")}`,
                        'Provider':  localStorage.getItem("authProvider")

                    },
                });
            }else{
                console.log ("Public URL:" + `${environment.directApiUrl}${request.url}`)
                request = request.clone({ url: `${environment.directApiUrl}${request.url}` });
            }

       }
        //return next.handle(request);
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                this.errorHandler.handleError(err);
            }
        }));
    }
}
