import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { Observable, Subject } from 'rxjs'
import { shareReplay, map, catchError, tap } from 'rxjs/operators';
import { publishReplay, refCount } from 'rxjs/operators';

import { AuthService } from "@app/auth/_services/auth0.service";
import { MemberModel } from '@app/models/MemberModel';
import { ToastrService } from 'ngx-toastr';
import { GrantModel, GrantBudgetModel } from '@app/models/GrantModel';
import { GrantProfileModel } from '@app/models/GrantProfileModel';
import { WorkflowModel } from '@app/models/WorkflowModel';
import {throwError as observableThrowError, throwError} from 'rxjs';
import { RiskFactorQuestionModel, GrantRiskFactorQuestionModel } from '@app/models/RiskFactorQuestionModel';
import { AssessmentModel } from '@app/models/assessmentmodel';
import { ContractModel } from '@app/models/ContractModel';
import { ProjectModel } from '@app/models/ProjectModel';
import { ReportTemplateModel } from '@app/models/ReportTemplateModel';
import { FileModel } from '@app/models/FileModel';
import { GrantLinkModel } from '@app/models/GrantLinkModel';
import { Router } from '@angular/router';
import { IEntityWithMemberFileService } from '../interfaces/IEntityFile.service';
import { TerminologytranslatorService } from '../TerminologyTranslator.service';
import { WorkflowGroupModel } from '@app/models/WorkflowGroupModel';
import { WorkflowStageModel } from '@app/models/WorkflowStageModel';

@Injectable()
export class GrantsService implements IEntityWithMemberFileService{
    
    
    public gridUpdateStream: Subject<any> = new Subject();

    handleError(): any {
        throw new Error("GrantsService Method not implemented.");
    }

    constructor(private httpclient: HttpClient, private toastr: ToastrService,private router: Router,public terminologytranslatorService:TerminologytranslatorService) {
        this.grants = this.httpclient.get<any>('/api/v1/grants')
        .pipe(map(res => res));
    }

    private grants: Observable<GrantModel[]> ;

    public getGrants(): Observable<GrantModel[]> {
        return this.grants.pipe(shareReplay(1));
    }

    public deleteGrant(id: string): Observable<any> {
        return this.deleteItem(`/api/v1/grants/${id}`, 'Grant');
    }

    public deleteGrantTask(id: string, taskId: string): Observable<any> {
        return this.deleteItem(`/api/v1/granttasks/grant/${id}/task/${taskId}`, this.terminologytranslatorService.getTerminologyByProp('Grant Task'));
    }

    public getApprovedVariations(id) {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/variations/approved/`)
            .pipe(map(res => res))
    }

    private deleteItem(url: string, typeDisplayValue: string) {
        return this.httpclient.delete(`${url}`)
        .pipe(
            tap(() => {
                this.toastr.success(`${typeDisplayValue} deleted`, "Deleted");
            }),
            catchError((err: any) => { 
                return throwError(this.errorHandler(err))
            })
        );
    }

    public setPortfolioLinkToGrant(portfolioId,grantId): Observable<MemberModel> {        
        return this.httpclient.get<any>(`/api/v1/grants/${grantId}/linkPortfolio/${portfolioId}`).pipe(
            tap(p => {
                this.toastr.success(`Portfolio ${p.portfolio.name} linked to the Grant!`);
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }  

    public deleteGrantPortfolioLink(id, portfolioId): Observable<any> {
        return this.httpclient.delete(`/api/v1/grants/${id}/deletePortfolio/${portfolioId}`)
            .pipe(
            tap(c => {
                this.toastr.success("Portfolio unlinked from the Grant!");
            }),
            catchError((err: any) => {
                this.toastr.error("There was an issue during the portfolio link deletion");
                return observableThrowError(this.errorHandler(err))
            }));;
    }

    public getGrantsArchived(): Observable<GrantModel[]> {
        return this.httpclient.get<any>('/api/v1/grants/archived')
            .pipe(map(res => res))
    }

    public getGrantsApproved(): Observable<GrantModel[]> {
        return this.httpclient.get<any>('/api/v1/grants/approved')
            .pipe(map(res => res))
    }
    
    public getGrant(id,isApplication=false): Observable<any> {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/${isApplication}`)
            .pipe(map(res => res),
            catchError(err => 
                {
                    return observableThrowError(this.errorHandler(err));      
                }),)
    }

    public getGrantCloseout(id: string) {
        return this.httpclient.get<any>('/api/v1/grants/' + id +'/grantCloseout')
            .pipe(map(res => res))
      }


    // public getGrantTasks(id): Observable<any> {
    //     return this.httpclient.get<any>(`/api/v1/granttasks/grant/${id}`)
    //         .pipe(map(res => res))
    // }

    public getApprovedFiles(id: any): Observable<any> {
        return this.httpclient.get<any>(`/api/v1/grants/approvedFiles/${id}`)
            .pipe(map(res => res));
    }
    
    public getGrantTasks(id: any): Observable<any> {
        return this.httpclient.get<any>(`/api/v1/granttasks/grant/${id}`)
            .pipe(map(res => res));
    }

    public getGrantProfile(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/grantprofile/' + id)
            .pipe(map(res => res))
    }

    public getFundingProgramGrantDefaultFields(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/grants/fundingprogram/' + id)
            .pipe(map(res => res))
    }

    public updateGrant(formData,isApplication=false): Observable<GrantModel> {
        return this.httpclient.post<any>(`/api/v1/grants/${isApplication}`, formData)
            .pipe(
                tap(p => {
                    this.toastr.success('Grant ' + p.name + ' Updated!');
                }),
                catchError((err: any) => throwError(this.errorHandler(err)))
            );
    }

    public updateGrantProfile(grant, grantProfileModel): Observable<GrantProfileModel> {
        return this.httpclient.post<any>('/api/v1/grantprofile/' + grant.id, grantProfileModel)
            .pipe(
                tap(p => {
                    this.toastr.success('Grant ' + grant.name + ' Updated!');
                }),
                catchError((err: any) => throwError(this.errorHandler(err)))
            );
    }

    public addGrant(formData,isApplication=false): Observable<GrantModel> {
        return this.httpclient.post<any>(`/api/v1/grants/${isApplication}`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Grant ' + p.name + ' Added!');
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
            );
    }

    public getMembers(id): Observable<MemberModel[]> {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/members`);//.pipe(publishReplay(),refCount());
    }

    public setMembers(id, formData): Observable<MemberModel> {
        return this.httpclient.post<any>('/api/v1/grants/' + id + '/members', formData).pipe(
            tap(() => {
                this.toastr.success('Grant Members Updated!');
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public getWorkflow(id): Observable<WorkflowModel> {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/workflow`)
            .pipe(map(res => res));
    }

    public setWorkflow(id, formData): Observable<WorkflowModel> {
        return this.httpclient.post<any>(`/api/v1/grants/${id}/workflow`, formData)

            .pipe(
            tap(p => {
                this.toastr.success('Grant Workflow Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getWorkflowGroups(id): Observable<WorkflowGroupModel[]> {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/workflowgroup/`)
        .pipe(map(res => res));
    }

    public getWorkflowStagesByGroupId(id,groupId): Observable<WorkflowStageModel[]> {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/workflowstage/group/${groupId}`)
        .pipe(map(res => res));
    }

    public setGrantBudget(id, grantBudget): Observable<GrantBudgetModel> {
        return this.httpclient.post<any>(`/api/v1/grants/${id}/grantBudget`, grantBudget)
            .pipe(map(res => res));
    }

    public returnToTaskDelivery(id,): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/grants/' + id + '/returnToTaskDelivery', id)
        .pipe(
        tap(p => {
            this.toastr.success('Grant returned to Task Delivery');
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public setGrantArchive(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/grants/' + id + '/archive', formData)
        .pipe(
        tap(p => {
            this.toastr.success('Grant Archived!');
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public setGrantUnarchive(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/grants/' + id + '/unarchive', formData)
        .pipe(
        tap(p => {
            this.toastr.success('Grant Response Submitted!');
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }


    public getGrantQuestions(id, gateway) {
       gateway = gateway ? `/${gateway}` : '';
        return this.httpclient.get<GrantRiskFactorQuestionModel>(`/api/v1/grants/${id}/questions${gateway}`)
            .pipe(map(res => res))
    }

    public setGrantQuestions(id, formData, skipApproval) {
        return this.httpclient.post<any>(`/api/v1/grants/${id}/questions/${skipApproval}`, formData)
            .pipe(
                tap(p => {
                this.toastr.success('Grant Questions Saved!');
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getGrantApproval(id, formData: AssessmentModel) {

        return this.httpclient.post<any>(`/api/v1/grants/${id}/approval/request`, formData).pipe(
            tap(res => {
                this.toastr.success('Grant Approval Requested!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public setGrantApproved(id, formData) {

        return this.httpclient.post<any>(`/api/v1/grants/${id}/approval/process`, formData).pipe(
            tap(res => {
                this.toastr.success('Grant Response Submitted!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public setGrantEligibilityApproved(id, formData) {

        return this.httpclient.post<any>(`/api/v1/grants/${id}/grantApproval/process`, formData).pipe(
            tap(res => {
                this.toastr.success('Grant Response Submitted!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public requestGrantEligibiltyApproval(id, approvalRequestComment) {
       
        return this.httpclient.post<any>(`/api/v1/grants/${id}/grantApproval/request`, { Comment: approvalRequestComment } )
            .pipe(
                tap(p => {
                    this.toastr.success('Grant Approval Requested!');
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getGrantApprovals(id): Observable<AssessmentModel[]> {
        return this.httpclient.get<any>(`/api/v1/grants/${id}/approvals`)
            .pipe(map(res => res))
    }

    public getGrantSettings() {
        return this.httpclient.get(`/api/v1/grants/grantsSettings`).pipe(res => res);
    }
    
    public getGrantProfileSettings() {
        return this.httpclient.get(`/api/v1/grants/settings`).pipe(res => res);
    }

    public getOrganisation() {
        return this.httpclient.get(`/api/v1/grants/organisation`).pipe(res => res);
    }

    public LinkEntityToGrant(grantId: any, entityToLink: any, entityType: string, showMessage = true, sourceType='grant'): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/${entityType}/${sourceType}`, entityToLink)
            .pipe(
            tap(p => {
                if(showMessage){
                    this.toastr.success(`${ entityType.charAt(0).toUpperCase() + entityType.slice(1) } ${p.item.name} linked to the grant`);
                }
            }),
            tap(p => {
                this.gridUpdateStream.next({
                    grant: p.grant,
                    entityType: entityType,
                    type: 'update'
                });
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
            );
    }

    public GetGrantsFromEntity(id: any,  entityType: string): Observable<GrantLinkModel[]> {
        return this.httpclient.get<GrantLinkModel[]>(`/api/v1/grants/${id}/${entityType}`);            
    }
    
    public getAvailableGrantsFromEntity(id: any ,entityType: string): Observable<GrantModel[]> {
        return this.httpclient.get<GrantModel[]>(`/api/v1/grants/${id}/availableGrants/${entityType}`);
    }

    public getAvailableContracts(id: any): Observable<ContractModel[]> {
        return this.httpclient.get<ContractModel[]>(`/api/v1/grants/${id}/availableContracts`).pipe(shareReplay(1));
    }

    public getAvailableProjects(id: any): Observable<ProjectModel[]> {
        return this.httpclient.get<ProjectModel[]>(`/api/v1/grants/${id}/availableProjects`).pipe(shareReplay(1));
    }

    public unlinkEntityFromGrant(grantId: any, entityId: any, entityType: string, showMessage = true, sourceType='grant'): Observable<any> {
        return this.httpclient.delete<any>(`/api/v1/grants/${grantId}/${entityType}/${entityId}/${sourceType}`).pipe(
            tap(p => {
                if(showMessage)
                this.toastr.success(`${entityType} ${p.removedItem.name} removed from grant`);
            }),
            tap(p => {
                this.gridUpdateStream.next({
                    grant: p.grant,
                    entityType: entityType,
                    type: 'update'
                });
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public uploadEntityFile(formData, grantId): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/files`, formData).pipe(
            tap(res => {
                this.toastr.success(`A new document has been attached to ${res.name}!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public updateEntityFile(formData, grantId): Observable<FileModel> {
        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/files/edit`, formData).pipe(
            tap(res => {
                this.toastr.success(`Document Updated!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public getEntityFile(grantId, id): Observable<any> {
        return this.httpclient.get(`/api/v1/grants/${grantId}/files/${id}`, { responseType: 'blob' })
            .pipe(tap(res => {
                this.toastr.success(`Document Generated!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public deleteEntityFile(grantId, id): Observable<FileModel[]> {
        return this.httpclient.delete<FileModel[]>(`/api/v1/grants/${grantId}/files/${id}`)
            .pipe(res => res)
    }

    public getDocuments(): Observable<ReportTemplateModel[]> {
        return this.httpclient.get<any>('/api/v1/grants/documents')
            .pipe(map(res => res))
    }

    public getGrantFile(grantId,id): Observable<any> {
        return this.httpclient.get(`/api/v1/grants/${grantId}/files/${id}`, { responseType: 'blob' })
            .pipe(tap(res => {
                this.toastr.success(`Document Generated!`);
            }),)
    }

    public getGrantFiles(grantId,submissionSelectedDoc) : Observable<any> {
        return this.httpclient.post(`/api/v1/grants/${grantId}/filelist/`,  submissionSelectedDoc, { responseType: 'arraybuffer' })
            .pipe(res => res)
    }

    public getAssociatedItemFiles(grantId,type, ids): Observable<any> {       
            if(type=='plannedActivitie')
                type= 'plannedActivity';
            return this.httpclient.post(`/api/v1/grants/${grantId}/${type}list/`, ids,  { responseType: 'arraybuffer' })
                .pipe(res => res);
      
    }

    public postSubmission(grantId, grantSubmissionModel): Observable<any>{

        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/submitGrant`, grantSubmissionModel).pipe(
            tap(res => {
                this.toastr.success(`Grant Submission Lodged!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }
    
    public postSubmissionResult(grantId, grantSubmissionModel): Observable<any>{

        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/submitGrantResult`, grantSubmissionModel).pipe(
            tap(res => {
                this.toastr.success(`Grant Response Submitted!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public postSubmissionExternally(grantId, grantSubmissionModel): Observable<any>{

        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/submitGrantExternally`, grantSubmissionModel).pipe(
            tap(res => {
                this.toastr.success(`Grant Submission Lodged!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public getGrantByFundingProgramId(fundingProgramId: string): Observable<GrantModel> {
        return this.httpclient.get<any>(`/api/v1/grants/fundingprogramgrant/${fundingProgramId}`).pipe(map(res => res));
      }
      
    public getGrantCurrentSpend(grantId: string): Observable<number> {
        return this.httpclient.get<any>(`/api/v1/grants/getGrantCurrentSpend/${grantId}`)
            .pipe(map(res => res));
    }

    public getLinkedProjectsBudget(grantId: string): Observable<number> {
        return this.httpclient.get<any>(`/api/v1/grants/linkedprojectsbudget/${grantId}`)
            .pipe(map(res => res));
    }

    saveCloseout(grantId, grantCloseout) {
        return this.httpclient.post<any>(`/api/v1/grants/${grantId}/updateCloseout`, grantCloseout).pipe(
            tap(res => {
                this.toastr.success(`Grant Closeout Saved!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        ); 
    }

    public getApprovedEOT(id) : Observable<any[]> {
        return this.httpclient.get<any>('/api/v1/grants/' + id + '/eots/approved')
            .pipe(map(res => res))
    }
    
    inlineUpdateGrants(formData): Observable<GrantModel> {
        return this.httpclient.post<any>(`/api/v1/grants/updategrantsummary`, formData)
            .pipe(
                tap(c => { }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public grantSummaryListRefresh(): Observable<any> 
    {
        return this.httpclient.get<any>('/api/v1/grants')
        .pipe(map(res => res))
    }
    
    public addFundingApplicaton(fundingProgramId) {

        return this.httpclient.post<any>(`/api/v1/grants/addDefaultApplication/${fundingProgramId}`,null).pipe(
            tap(res => {
                console.log('Funding application created.');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    errorHandler(err): void {
    }

}
