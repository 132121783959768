<div class="leftside-menu">
    <a href="javascript:void(0)" class="logo text-center logo-light">
        <span class="logo-lg">
            <img src="assets/app/media/img/Surepact_Logo-Reversed.svg" alt="" height="35">
            <button class="button-menu-mobile open-left n-brand__icon" (click)="changeSideCondensed()">
                <i class="icon-funder-chevron-contract-horizontal" aria-hidden="true"></i>
            </button>
        </span>
        <span class="logo-sm button-menu-mobile open-left" (click)="changeSideCondensed()">
            <img src="assets/app/media/img/surepact-logo-sm.png" alt="" height="35">
        </span>
    </a>
    <div class="lds-dual-ring" *ngIf="myUserProfile==null"></div>
    <div id="leftside-menu-container" data-simplebar *ngIf="myUserProfile">
        <ul class="side-nav side-nav-top">

            <li class="side-nav-item " routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }">
                <a routerLink="/dashboard" class="side-nav-link">
                    <i class="icon-funder-home-plus" aria-hidden="true"></i>
                    <span> Homepage </span>
                </a>
            </li>

         
          
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/{{porfolioModuleDefault}}" class="side-nav-link">
                    <i class="icon-funder-layers-3" aria-hidden="true"></i>
                    <span> Portfolios </span>
                </a>
            </li>
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/projects" class="side-nav-link">
                    <i class="icon-funder-add-layer" aria-hidden="true"></i>
                    <span> Projects </span>
                </a>
            </li>
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/opportunities" class="side-nav-link">
                    <i class="icon-funder-bullseye" aria-hidden="true"></i>
                    <span> Funding Opportunities </span>
                </a>
            </li>
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/grants" class="side-nav-link">
                    <i class="icon-funder-coins-1" aria-hidden="true"></i>
                    <span> Grants </span>
                </a>
            </li>
           
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/assets" class="side-nav-link">
                    <i class="icon-funder-pantone" aria-hidden="true"></i>
                    <span> Assets </span>
                </a>
            </li>
            <li class="side-nav-item menuitem" routerLinkActive="menuitem-active" routerLinkActiveOptions="{ exact: true }" >
                <a routerLink="/analytics" class="side-nav-link">
                    <i class="icon-funder-bars" aria-hidden="true"></i>
                    <span> Analytics </span>
                </a>
            </li>

            <li class="seperation-line-li" *ngIf="showItem(['all'])">
                <div class="seperation-line"></div>
            </li>
        </ul>

 
        <ul class="side-nav side-nav-bottom">
            <li class="side-nav-item"   #sidebarDocumentManagement>
                <a data-bs-toggle="collapse" href="#" aria-expanded="false" class="side-nav-link"  (click)="toggleChildItem(2)">
                    <i class="icon-funder-document-double" aria-hidden="true"></i>
                        <span> Documents</span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/library">Library</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }">
                            <a routerLink="/repository">Repository</a>
                        </li>
                    </ul>
                </div>
            </li>
                
            <li class="seperation-line-li" *ngIf="showItem(['all'])">
                <div class="seperation-line"></div>
            </li>

            <li class="side-nav-item" *ngIf="(myUserProfile?.isAdmin || myUserProfile?.isCustomerAdmin || myUserProfile?.isTenantAdmin)" #sidebarAdministration>
                <a data-bs-toggle="collapse" href="#"  aria-expanded="false" class="side-nav-link" (click)="toggleChildItem(3)">
                    <i class="icon-funder-settings-3" aria-hidden="true"></i>
                    <span> Administration </span>
                    <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                </a>
                <div class="collapse" id="sidebarAdministration">
                    <i class="fa fa-caret-left arrow-to-side-menu" aria-hidden="true"></i>
                    <ul class="side-nav-second-level">
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/users">
                                {{terminologytranslatorService.getTerminologyByProp('Users')}}
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/prefix">  
                                <span>{{terminologytranslatorService.getTerminologyByProp('Funding Streams')}}</span>
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/businessunits">
                                {{terminologytranslatorService.getTerminologyByProp('Business Units')}}
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/departments">
                                {{terminologytranslatorService.getTerminologyByProp('Departments')}}
                            </a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/contractors"  >
                                <span> Contractors </span>
                            </a>
                        </li>
                        <!-- <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/terminologies">
                                Terminology
                            </a>
                        </li> -->
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/import"> Import</a>
                        </li>
                        <li routerLinkActive="second-active" routerLinkActiveOptions="{ exact: true }" [ngClass]="{ 'disabled-link': !myUserProfile.isAdmin && !myUserProfile.isCustomerAdmin }">
                            <a routerLink="/export"> Export</a>
                        </li>
                     
                      
                    </ul>
                </div>
            </li>          
            <li class="side-nav-item menuitem" *ngIf="showItem(['all'])">
                <a href="https://surepact.com/privacy-policy/" target="_blank" class="side-nav-link">
                    <i class="icon-funder-lock" aria-hidden="true"></i>
                    <span> Privacy Policy </span>
                </a>
            </li>

        </ul>

      
    </div>
</div>
