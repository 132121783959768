
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  Subject, ReplaySubject ,  Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, shareReplay, map, takeUntil, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ModulePermissionsModel } from '@app/models/ModulePermissionsModel';
import { Router } from '@angular/router';
import { AccountsService } from './accounts.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare let pendo: any;
declare let authenticateZendesk: any;

@Injectable()
export class LoggingService {
    handleError(arg0: any): any {
        throw new Error("LoggingService Method not implemented.");
    }

    public successLogin:boolean = false;

    constructor(private httpclient: HttpClient, private toastr: ToastrService,private router: Router ) {

    }

    public addUserLog() {

        return this.httpclient.post('/api/v1/accounts/users/addLogin', null).subscribe(
            res => {
                this.httpclient.get<any>('/api/v1/accounts/myprofile').subscribe(profile => {
                    const pendoData = {
                        visitor: {
                            id: profile.user.email,
                            email: profile.user.email,
                            firstname: profile.user.firstname,
                            lastname: profile.user.lastname,
                            ssoenabled: profile.user.ssoEnabled,
                            isadministrator: profile.user.isAdmin
                        },
                        account: {
                            id: profile.organisation
                        }
                    }

                    pendo.initialize(pendoData);
                    localStorage.setItem("pendo_data", JSON.stringify(pendoData))
                    authenticateZendesk(profile.zendeskToken)

                });

            

                this.successLogin = true;
                this.router.navigate(['/home']);


            },
            err => {
                this.errorHandler(err);

                // Remove tokens and expiry time from localStorage
                localStorage.removeItem('access_token');
                localStorage.removeItem('id_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('user_id');
                
                // Go back to the Log in page
                this.router.navigate(['/login']);
                this.toastr.error('Unable to log in to SurePact. Please contact your System Administrator or email support@surepact.com.');
            }
        );

    }

    errorHandler(error: any): void {
        console.error(error)
    }
}
