
<!-- BEGIN: Header New Design -->
<div class="navbar-custom navbar-custom-header">
	<div class="m-container m-container--fluid m-container--full-height">
		<div class="m-stack m-stack--ver m-stack--desktop">
			<div class="">							
                <!-- BEGIN: Topbar -->
                <div class="top-menu-link" *ngIf="myUserProfile">
                    <span *ngFor="let link of topMenu.topMenuLinks;let i = index">
                        <span *ngIf="i!=0" class="top-menu-arrow">></span> 
                        <a [routerLink]="link.link" class="top-link-a">
                            <span class="top-menu__link-text {{ (topMenu.topMenuLinks.length-1) == i ? 'bold-text':'' }}">
                                {{ link.name | translate }}
                            </span>
                        </a> 
                    </span>
                </div>
				<div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
					<div class="m-stack__item m-topbar__nav-wrapper">
                        <ul class="m-topbar__nav m-nav m-nav--inline">
                            <li class="header-small m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-dark" data-dropdown-toggle="click">
                                <a href="#" class="m-nav__link m-dropdown__toggle">
                                    <div id="testSiteDiv" *ngIf="isTestSite">TEST SITE</div>
                                </a>
                            </li>
                            <li class="header-small m-nav__item m-topbar__notifications m-topbar__notifications-custom m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width" data-dropdown-toggle="click" data-dropdown-persistent="true">
                                <a href="#" class="m-nav__link m-dropdown__toggle topbar_icon button-padding" id="zzm_topbar_notification_icon">
                                    <span class="m-nav__link-badge m-badge m-badge--success" *ngIf="notificationsUnreadCount>0">{{notificationsUnreadCount}}</span>
                                    <span class="m-nav__link-icon" [ngClass]="{' m-animate-shake': notificationsUnreadCount>0}" style="z-index:0">
                                        <i class="icon-funder-ringnotification-2" aria-hidden="true"></i>
                                    </span>
                                </a>
                                <div class="m-dropdown__wrapper" style="padding-top:10px">
                                    <div class="m-dropdown__inner">
                                        <div class="m-dropdown__header">
                                            <span class="notif_head">
                                                Notifications
                                            </span>
                                            <a href="#" class="notif_right {{notificationsUnread.length==0?'disabled':''}}" (click)="onClickNotificationsAckAll()" >
                                                <span>
                                                    <i class="fa fa-check" aria-hidden="true"></i> Mark All As Read!
                                                </span>
                                            </a>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-scrollable">
                                                <div class="m-list-timeline m-list-timeline--skin-light">
                                                    <div class="">
                                                        <div class="m-list-timeline__item {{!notification.viewed ? '':'notifread'}}" *ngFor="let notification of notificationsUnread; let j = index" (click)="onClickNotificationsAck(notification)">
                                                            <span class="notif_head-item-sub">
                                                                {{ notifTrim(notification.description) }}
                                                            </span>
                                                            <br  *ngIf="notification.created !== null">
                                                            <span class="notif_head-item-time" *ngIf="notification.created !== null">
                                                                <i class="fa fa-calendar-o fontsmall" aria-hidden="true"></i> {{ convertUTCDateToLocalDate(notification.created) }}
                                                            </span>
                                                            <a href="#" class="m-list-timeline__time" [ngClass]="{'disabled': notification.viewed===true}">
                                                                <span class="m-badge m-badge--success m-badge--wide m-badge-custom">
                                                                </span>
                                                            </a>                                                            
                                                        </div>
                                                        <div class="m-list-timeline__item no-notif notifread" *ngIf="notificationsUnread.length==0">
                                                            <span class="m-dropdown__header-title">
                                                                No active notification
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--TODO button for View all-->
                                            <div class="m-list-timeline__item item-view-all">
                                                <button class="view-all_button disabled" >
                                                    View all
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-dark" data-dropdown-toggle="click">
                                <div class="m-nav__link m-dropdown__toggle user-header">
                                    <span class="m-topbar__userpic">
                                        <img [src]="imageUserPic" alt="" class="m--img-rounded m--marginless m--img-centered shrank-pic">
                                   </span>
                                   <div class="topnav-right" *ngIf="user">
                                        <div class="topnav-right-upper">
                                            {{ fullName }}
                                        </div>
                                        <div class="topnav-right-lower">
                                            {{ user.businessUnit ? user.businessUnit.name : '-' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="m-dropdown__wrapper" style="width: 275px">
                                    <div class="m-dropdown__inner">
                                        <div class="m--align-center user-header" *ngIf="user">
                                            <div class="m-card-user__pic" *ngIf="imageUserPic">
                                                <img [src]="imageUserPic" alt="./assets/app/media/img/users/avatar.png" class="m--img-rounded m--marginless pic-user">
                                            </div>
                                            <span class="topnav-right-upper">
                                                {{ fullName }}
                                            </span>
                                            <br>
                                            <span class="topnav-right-lower">
                                                {{ user.email }}
                                            </span>
                                        </div>
                                        <div class="m-dropdown__body">
                                            <div class="m-dropdown__content">
                                                <div class="logoutspacing">
                                                    <button class="logout_button" routerLink="/logout">
                                                        Logout <i class="fa fa-sign-out" aria-hidden="true"></i>
                                                    </button>                                                    
                                                </div>
                                                <ul class="buttonlink-user">
                                                    <li>
                                                        <a routerLink="/user" class="m-nav__link">
                                                            <i class="m-nav__link-icon flaticon-profile-1"></i>
                                                            <span class="m-nav__link-title">
                                                                <span class="m-nav__link-wrap">
                                                                    <span class="m-nav__link-text">
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a routerLink="/delegation" class="m-nav__link" *ngIf="myUserProfile.organisationTenancyType != 'RecipientFreemium'">
                                                            <i class="m-nav__link-icon flaticon-share"></i>
                                                            <span class="m-nav__link-text">
                                                            </span>
                                                        </a> 
                                                    </li>
                                                    <li class="disabled">
                                                        <a routerLink="/header/profile" class="m-nav__link">
                                                            <i class="m-nav__link-icon flaticon-chat-1"></i>
                                                            <span class="m-nav__link-text">
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                         </ul>
					</div>
				</div>
				<!-- END: Topbar -->
			</div>
		</div>
	</div>
    <div class="navbar-bottom m-container m-container--fluid">
        <ul class="topbar_nav-horizontal" *ngIf="myUserProfile">
            <li>
                <div *ngIf="topMenu.topMenuLinks.length>1&&topMenu.topMenuLinks[topMenu.topMenuLinks.length-1].name=='Homepage'&&user">
                    <h3 class="header-title">Hi, {{ myUserProfile.user.firstname }}.</h3>
                    <p class="header-title-sub header-small">
                        Welcome to SurePact!
                    </p>
                </div>
                <div *ngIf="topMenu.topMenuLinks.length>1&&topMenu.topMenuLinks[topMenu.topMenuLinks.length-1].name!='Homepage'">
                    <h3 *ngIf="topMenu.topMenuLinks.length>1" class="header-title">{{ topMenu.topMenuLinks[topMenu.topMenuLinks.length-1].name | translate }}</h3>
                    <p class="header-title-sub page-desc2" *ngIf="topMenu.pageDescription">
                        {{ topMenu.pageDescription }}
                    </p>
                    <p class="header-title-sub" *ngIf="topMenu.pageDescOptional">
                        {{ topMenu.pageDescOptional }}
                    </p>
                </div>
            </li>
            <li *ngIf="store.header.addItem">
                <button class="add-item_button"
                (click)="store.runEvent('addItem')" *ngIf="store.header.addItem">
                    <i class="fa fa-plus" aria-hidden="true"></i> {{ translateByWord(store.header.addItemLinkButtonTitle) }}
                </button>
            </li>
            <li *ngIf="store.header.addButtonArray">
                <span *ngFor="let button of store.header.buttonArray">
                    <button class="add-item_button {{button.addItemButtonCustomClass}}" (click)="store.runEvent(button.event)"
                        *ngIf="store.header.addButtonArray">
                        <i class="fa fa-plus" aria-hidden="true"></i> {{ translateByWord(button.addItemLinkButtonTitle) }}
                    </button>
                </span>
            </li>
            <li *ngIf="store.header.button">
                <button class="add-item_button"
                (click)="store.runEvent('button')" *ngIf="store.header.button">
                    <i class="icon-funder-computer-chart ask-surepact-icon-size" aria-hidden="true" *ngIf="store.header.buttonTitle == 'Ask SurePact'"></i> 
                    {{store.header.buttonTitle | translate}}
                </button>
            </li>
            <li *ngIf="store.header.filter" class="header-tooltip">
                  <span class=" hover-text">
                    <a href="#" class="topbar_icon-bottom topbar_icon" (click)="store.runEvent('filter')">
                        <i class="icon-funder-filter" aria-hidden="true"></i>
                    </a>
                </span>
            </li>
            <li *ngIf="store.header.refresh" class="header-tooltip">
                <i class="fa fa-exclamation-triangle refresh-warning" aria-hidden="true" *ngIf="store.refreshRequired"></i>
                    <span class=" hover-text">
                    <a href="#" class="topbar_icon-bottom topbar_icon {{ store.refreshRequired ? 'refresh-warning-background':''}}" (click)="store.runEvent('refresh')">
                        <i class="icon-funder-refresh" aria-hidden="true"></i>
                    </a>
                    <span class="tooltip-text header-bottom" *ngIf="store.refreshRequired">You have a pending update. Please click refresh to get the latest data.</span>
                </span>
            </li>

            <!-- <a href="#" class="m-nav__link m-dropdown__toggle topbar_icon" id="zzm_topbar_notification_icon">
                <span class="m-nav__link-badge m-badge m-badge--success" *ngIf="notificationsUnreadCount>0">{{notificationsUnreadCount}}</span>
                <span class="m-nav__link-icon" [ngClass]="{' m-animate-shake': notificationsUnreadCount>0}" style="z-index:0">
                    <i class="flaticon-music-2"></i>
                </span>
            </a> -->
            
            <li *ngIf="store.header.info" class="m-nav__item m-topbar__notifications m-topbar__notifications-custom m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width" data-dropdown-toggle="click" data-dropdown-persistent="true">
                <a href="#" class="topbar_icon-bottom m-nav__link m-dropdown__toggle topbar_icon">
                    <i class="icon-funder-info" aria-hidden="true"></i>
                </a>
                <div class="m-dropdown__wrapper" style="padding-top:15px">
                    <div class="m-dropdown__inner">
                        <div class="m-dropdown__header">
                            <span class="notif_head">
                                Training
                            </span>
                            <a href="#" class="notif_right" routerLink="/training/library">
                                <span>
                                    View All  <i class="fa fa-angle-right menu-arrow"  aria-hidden="true"></i>
                                </span>
                            </a>
                        </div>
                        <div class="m-dropdown__body">
                            <div class="header-paragraph">
                                <p>
                                <b class="notif_head"><span *ngIf="topMenu.topMenuLinks.length>1">{{topMenu.topMenuLinks[topMenu.topMenuLinks.length-1].name}}</span></b><br>
                                {{this.store.header.infoText}}
                                </p>
        
                                <div class="button-container">
                                    <button class="logout_button disabled">
                                        <i class="fa fa-download" aria-hidden="true"></i> Download PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li *ngIf="store.header.download" [ngClass]="store.header.downloadDisabled ? 'disabled':''">
                <a href="#" class="topbar_icon-bottom topbar_icon" (click)="store.runEvent('download')">
                    <i class="fa fa-download" aria-hidden="true"></i>
                </a>
            </li>
            <li *ngIf="store.header.folder">
                <a href="#" class="topbar_icon-bottom topbar_icon" (click)="store.runEvent('folder')">
                    <i class="fa fa-folder-open" aria-hidden="true"></i>
                </a>
            </li>
            <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-dark" data-dropdown-toggle="click"
                *ngIf="store.header.print||store.header.exportData||store.header.clone||store.header.delete">
                <div class="m-nav__link m-dropdown__toggle user-header">
                    <a href="#" class="topbar_icon-bottom topbar_icon">
                        <i class="icon-funder-dot" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="m-dropdown__wrapper" style="width: 275px">
                    <div class="m-dropdown__inner">
                        <div class="m-dropdown__body">
                            <div class="m-dropdown__content">
                                <div class="options">
                                    <a href="#" *ngIf="store.header.exportData" (click)="store.runEvent('exportData')"><i class="fa fa-file-text" aria-hidden="true"></i> Export Data</a>
                                    <a href="#" onclick="window.print()" *ngIf="store.header.print"><i class="fa fa-print" aria-hidden="true"></i> Print</a>
                                    <a href="#" *ngIf="store.header.clone" (click)="store.runEvent('clone')" class="{{ store.header.cloneDisabled ? 'disabled': '' }}">
                                        <i class="fa fa-files-o" aria-hidden="true"></i> Clone
                                    </a>  
                                    <a href="#" (click)="store.runEvent('delete')" [ngClass]="store.header.deleteDisabled ? 'disabled':''" *ngIf="store.header.delete">
                                        <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<!-- END: Header -->
