
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  Subject, ReplaySubject ,  Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { shareReplay, map, takeUntil, catchError, tap } from 'rxjs/operators';
import { ModulePermissionsModel } from '@app/models/ModulePermissionsModel';
import { OrganisationModel } from '@app/models/OrganisationModel';
import { ToastrService } from 'ngx-toastr';
import { TerminologytranslatorService } from './TerminologyTranslator.service';
import { OrganisationEntityTypeModel } from '@app/models/OrganisationEntityTypeModel';
import { UserModel } from '@app/models/UserModel';
import Utils from '@app/utils';
import { FundingProgramModel } from '@app/models/ConfigurableFormModel';
import { SignUpModel } from '@app/models/SignUpModel';
import { environment } from '@env/environment';
import { AddressSearchModel } from '@app/models/AddressSearchModel';
import { LookupModel } from '@app/models/common/LookupModel';
import { OrganisationIndustryModel } from '@app/models/OrganisationIndustryModel';
import { OrganisationsModule } from '@app/theme/pages/default/admin/organisations/organisations.module';
import { OrganisationSectorModel } from '@app/models/OrganisationSectorModel';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class OrganisationService {

    constructor(private httpclient: HttpClient, private toastr: ToastrService,private terminologytranslatorService : TerminologytranslatorService) {
    }

    public checkDuplicatedName(name) :Observable<boolean>{
       return  this.httpclient.get<any>(`/api/v1/organisations/checkduplicatedname/${name}`)
            .pipe(map(res => res))
    }

    public checkDuplicatedABN(abn) :Observable<boolean>{
        return  this.httpclient.get<any>(`/api/v1/organisations/checkduplicatedabn/${abn}`)
             .pipe(map(res => res))
     }
 
    public getModulePermissions(): Observable<ModulePermissionsModel> {
        return this.httpclient.get<any>('/api/v1/accounts/modulePermissions/')
            .pipe(map(res => res))
    }

    public getOrganisations(): Observable<OrganisationModel[]> {
        return this.httpclient.get<OrganisationModel[]>(`/api/v1/organisations`);
    }

    public getOrganisationIndustries(): Observable<OrganisationIndustryModel []> {
        return this.httpclient.get<any>(`/api/v1/organisations/industries`);
    }

    public getOrganisationSectors(industryId) : Observable<OrganisationSectorModel[]>{
        return this.httpclient.get<any>(`/api/v1/organisations/sectors/${industryId}`)
    }

    public deleteOrganisation(id): Observable<any> {
        return this.httpclient.delete(`/api/v1/organisations/${id}`)
            .pipe(
            tap(c => {
                this.toastr.success(`${this.terminologytranslatorService.getTerminologyByProp("Organisation")} deleted!`);
            }),
            catchError((err: any) => {        
                return observableThrowError(this.errorHandler(err))
            }));
    }

    public addOrUpdateOrganisation(organisation, isUpdated): Observable<OrganisationModel> {
        
        if(!Utils.isEntityRegistered(organisation.id) || organisation.id==0)
        {
            organisation.id = '00000000-0000-0000-0000-000000000000'
        }
        return this.httpclient.post<any>('/api/v1/organisations', organisation)
            .pipe(
                tap(c => {
                    if (isUpdated) {
                        this.toastr.success(`${this.terminologytranslatorService.getTerminologyByProp("Organisation")} : ${c.name} Updated!`);
                    } else {
                        this.toastr.success(`${this.terminologytranslatorService.getTerminologyByProp("Organisation")} : ${c.name} Added!`);
                    }
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getOrganisationEntityTypes(): Observable<OrganisationEntityTypeModel[]> {
        return this.httpclient.get<any>('/api/v1/organisations/entitytypes');
    }

    public getStateBusinessLocations(): Observable<any[]> {
        return this.httpclient.get<any>('/api/v1/organisations/statebusinesslocations');
    }

    public getPrimaryContacts(): Observable<UserModel[]> {
        return this.httpclient.get<any>('/api/v1/organisations/primarycontacts');
    }

    public searchAddress(query: string): Observable<AddressSearchModel[]>{
        const params = new HttpParams()
            .set('api-version', environment.azureMapsApi.apiVersion)
            .set('countrySet',environment.azureMapsApi.countrySet)
            .set('subscription-key', environment.azureMapsApi.apiKey)
            .set('query', query);
        
        return this.httpclient.get<any>(environment.azureMapsApi.searchApi, { params }).pipe(
            map((response: any) => {
                return response.results.filter(x => x.address.postalCode != null).map((result: any) => {
                    return {
                        streetNumber: '',
                        streetName: result.address.streetName,
                        municipalitySubdivision: result.address.municipalitySubdivision || '',
                        municipality: result.address.municipality || '',
                        countrySecondarySubdivision: result.address.countrySecondarySubdivision || '',
                        countrySubdivision: result.address.countrySubdivision,
                        countrySubdivisionName: result.address.countrySubdivisionName,
                        countrySubdivisionCode: result.address.countrySubdivisionCode,
                        postalCode: result.address.postalCode || '',
                        countryCode: result.address.countryCode,
                        country: result.address.country,
                        countryCodeISO3: result.address.countryCodeISO3,
                        freeformAddress: result.address.freeformAddress,
                        localName: result.address.localName || '',
                        position: result.position
                    } as AddressSearchModel;
            });
        }));
    }

    public getCountries(): Observable<LookupModel[]>{
        return this.httpclient.get<any>('assets/json/countries.json').pipe(
            map((response: any) => {
                return response.data
                .map(country => ({
                    name: country.name,
                    id: country.name
                }));
            })
        );
    }
    
    public getCitiesByCountry(data: any): Observable<LookupModel[]>{
        return this.httpclient.get<any>('assets/json/australia-cities.json').pipe(
            map((response: any) => {
                return response.data
                .map(city => ({
                    name: city,
                    id: city
                }));
            }) 
        );
    }
    
    errorHandler(error: any): void {       
    }
}
