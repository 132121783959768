import { Component, OnInit, ViewEncapsulation, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Helpers } from "../../helpers";
import { AuthService } from '../_services/auth0.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})

@Injectable()
export class LogoutComponent implements OnInit {

    constructor(private _router: Router,

        private authService: AuthService) {
    }

    ngOnInit(): void {
        Helpers.setLoading(true);
        this.authService.logout();
        this._router.navigate(['/login']);
    }
}