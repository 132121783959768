import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        public snackbar: MatSnackBar,
        private toastr: ToastrService,
        private injector: Injector
    ) { }


    handleError(err: any) {
        const router = this.injector.get(Router);
        if (err.status === 400) {
            this.toastr.error(err.error.description);
        }

        else if (err.status === 401 || err.status === 403) {
            console.log('401|403')
            router.navigate(['/logout']);
        }

        else if (err.status === 0) {
            console.log("err: 0");
        }

        else {
            console.log(err.message);
            //this.snackbar.open(err.message, 'close');
        }
    }
}