import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityModel, ActivityTypeModel } from '@app/models/ActivityModel';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError as observableThrowError, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


@Injectable()
export class ActivitiesService {
  constructor(private httpclient: HttpClient,
    private toastr: ToastrService) {
  }


  public getActivities(id: number): Observable<ActivityModel[]> {
    return this.httpclient.get<any>(`/api/v1/activities/${id}`)
    .pipe(map(res => res));
  }
 
  public getActivityTypes(): Observable<ActivityTypeModel[]> {
    return this.httpclient.get<any>(`/api/v1/activities/activityTypes`)
    .pipe(map(res => res));
  }

  public saveActivity(activity, updated, entity): Observable<ActivityModel> {
    return this.httpclient.post<any>(`/api/v1/activities/${entity}`, activity)
      .pipe(
        tap(c => {

          
          if (updated)
          this.toastr.success('Activity Successfully Updated!');
          else
          this.toastr.success('Successfully Added Activity ' + c.activityType.name + '!');
        }),
        catchError((err: any) =>throwError((this.errorHandler(err))))
      );
  }

  public deleteActivity(activityId, contractId, entity): Observable<any> {
    return this.httpclient.delete(`/api/v1/activities/${entity}/${activityId}/${contractId}`)

        .pipe(
        tap(c => {
            this.toastr.success("Activity Successfully Removed!", "Deleted");
        }),
        catchError((err: any) => {        
            return observableThrowError(this.errorHandler(err))
        }));
}

  errorHandler(error: any): void {   
  }
}
