import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import './common.scripts.bundle.js';
declare let mApp: any;



@Component({
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class CommonCodeComponent implements OnInit {


    constructor() {
    }


    ngOnInit() { }

    // Spinners
    blockPage(options) {
        return mApp.block('body', options);
    };


    unblockPage() {
        return mApp.unblock('body');
    };

    blockRegion(region) {
        return mApp.block(region);
    };


    unblockRegion(region) {
        return mApp.unblock(region);
    };



}