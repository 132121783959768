import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';
import { TerminologyTranslatorModule } from '../terminologyTranslator/terminologyTranslator.module';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TerminologyTranslatorModule
    ],
    declarations: [
        HeaderComponent
    ],
    providers: [
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }
