import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, shareReplay, map, takeUntil, catchError, tap, share, delay } from 'rxjs/operators';

import { MessagesService } from '@app/services/messages.service';

@Injectable()
export class ReportService {

    constructor(private httpclient: HttpClient,
        private messagesService: MessagesService) { }

    getReportEmbedToken(reportView: string): Observable<any> {
        //this.messagesService.add('TokenService: fetch tokens');
        var url = '/api/v1/analytics/' + reportView;
        return this.httpclient.get<any>(url).pipe(map(res => res))
    }

    updateEmbedToken(reportView: string): Observable<any> {
        //this.messagesService.add('TokenService: fetch tokens');
        var url = '/api/v1/analytics/token/' + reportView;
        return this.httpclient.get<any>(url).pipe(map(res => res));
    }
    
    private log(message: string) {
        this.messagesService.add(`TokenService: ${message}`);
    }
}
