
import { throwError as observableThrowError, Observable, BehaviorSubject, timer, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DelegationModel } from '../models/DelegationModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, tap, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class DelegationService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }

    public getPortfilios(includeArchived: boolean = false, fromUser: any): Observable<any[]> {
        return this.httpclient.post<any[]>(`/api/v1/delegations/portfolios/${includeArchived}`, fromUser);
    }
    
    public getProjects(includeArchived: boolean = false, fromUser: any): Observable<any[]> {
        return this.httpclient.post<any[]>(`/api/v1/delegations/projects/${includeArchived}`, fromUser);
    }

    public getContracts(includeArchived: boolean = false, fromUser: any): Observable<any[]> {
        return this.httpclient.post<any[]>(`/api/v1/delegations/contracts/${includeArchived}`, fromUser);
    }

    public getGrants(includeArchived: boolean = false, fromUser: any): Observable<any[]> {
        return this.httpclient.post<any[]>(`/api/v1/delegations/grants/${includeArchived}`, fromUser);
    }

    public getAssets(fromUser: any): Observable<any[]> {
        return this.httpclient.post<any[]>(`/api/v1/delegations/assets`, fromUser);
    }

    public getDelegations(userid): Observable<DelegationModel[]> {
        return this.httpclient.get<any>(`/api/v1/delegations/user/${userid}`)
            .pipe(map(res => res))
    }

    public getMyDelegations(): Observable<DelegationModel[]> {
        return this.httpclient.get<any>('/api/v1/delegations')
            .pipe(map(res => res))
    }

    public addUpdateDelegations(fromBody, msg): Observable<any> { 
        return this.httpclient.post<any>('/api/v1/delegations', fromBody).pipe(
            tap(res => {
                if(msg){
                    this.toastr.success('Delegation Created!');
                }
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }


    public addUpdateDelegationsBatch(fromBody): Observable<any> { 
        return this.httpclient.post<any>('/api/v1/delegations/batch', fromBody).pipe(
            tap(res => {
                this.toastr.success('Delegations Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }



    public deleteDelegations(fromBody): Observable<any> { 
        return this.httpclient.post<any>('/api/v1/delegations/remove', fromBody).pipe(
            tap(res => {
                this.toastr.success('Delegations Removed!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }









    errorHandler(error: any): void { 
    }





}
