import {throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { map, catchError, tap } from 'rxjs/operators';
import { AuthService } from "@app/auth/_services/auth0.service";
import { ProjectModel } from '@app/models/ProjectModel';
import { ToastrService } from 'ngx-toastr';
import { CloneBudgetAnswerViewModel, RiskFactorAnswerSetOptionModel } from '@app/models/RiskFactorAnswerSetOptionModel';
import { EntityCloneModel } from '@app/models/EntityCloneModel';

@Injectable()
export class CloneService {
    handleError(): any {
        throw new Error("CloneService Method not implemented.");
    }

    constructor(private httpclient: HttpClient, private toastr: ToastrService) {
    }

    public cloneEntity(id, entityCloneModel: EntityCloneModel, type ): Observable<EntityCloneModel> {
        return this.httpclient.post<EntityCloneModel>(`/api/v1/clone/${type}/${id}` , entityCloneModel)
            .pipe(
            tap(() => {
                var typeName = type && type.length > 1 ? type[0].toUpperCase()+type.substr(1) : '';
                this.toastr.success(`${typeName}  ${entityCloneModel.entityName} Created!`);
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }
 
    public getContractBudgetAnswers(id): Observable<CloneBudgetAnswerViewModel> {
        return this.httpclient.get<any>('/api/v1/clone/contract/budgetAnswers/' + id)
            .pipe(map(res => res))
    }
    public getEntityBudgetAnswers(id,type): Observable<CloneBudgetAnswerViewModel> {
        return this.httpclient.get<any>(`/api/v1/clone/${type}/budgetAnswers/` + id)
            .pipe(map(res => res))
    }
    errorHandler(error: any): void {
    }

}
