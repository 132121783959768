import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AccountsService } from '@app/services/accounts.service';
import { OrganisationTenancyType } from '@app/constants';

@Injectable()
export class DashboardGuard implements CanActivate  {

    constructor(private accountsService: AccountsService, private _router: Router) {
    }

    async canActivate() {
        if(!sessionStorage.getItem("organisationTenancyType")){
            await this.accountsService.setActiveOrganisation();
        }
        const activeOrg = sessionStorage.getItem("organisationTenancyType");
         if(activeOrg == OrganisationTenancyType.FundingBody ||
            activeOrg == OrganisationTenancyType.FundingBodyRecipient ||
            activeOrg == OrganisationTenancyType.RecipientFreemium ||
            activeOrg == OrganisationTenancyType.RecipientPremium) {
                const url = sessionStorage.getItem('redirectUrl');
                if(url) {
                    sessionStorage.removeItem("redirectUrl");
                    this._router.navigateByUrl(url);
                } else {
                    this._router.navigateByUrl('/dashboard');
                }
                return false;
            }
        return true;
    }
}