import { environment } from 'environments/environment';

interface AuthConfig {
    clientID: string;
    domain: string;
    callbackURL: string;
}

export const AUTH_CONFIG: AuthConfig = {
    // DEV
    //clientID: 'xOfXRf5wwcRTkyn2v71Ox2CPi4ISKAJz',
    //domain: 'surepactdemo.au.auth0.com',
    //callbackURL: 'https://dev.surepact.com/login'
    //callbackURL: 'http://localhost:4200/login'
    

    // Pilot
    clientID: `${environment.clientId}`,
    domain: `${environment.domain}`,
    callbackURL: `${environment.callbackUrl}`








};
