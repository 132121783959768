import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  timer } from 'rxjs';
import { UserModel } from '@app/models/UserModel';
import { switchMap, shareReplay, map, takeUntil, catchError, tap, share, delay } from 'rxjs/operators';
//import { Subject, ReplaySubject, of } from 'rxjs';
import * as Rx from 'rxjs';


import { DepartmentModel } from '@app/models/DepartmentModel';
import { BusinessUnitModel } from '@app/models/BusinessUnitModel';
import { AuthService } from "@app/auth/_services/auth0.service";
import { ToastrService } from 'ngx-toastr';
import { PrefixModel } from '@app/models/PrefixModel';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class TestService {
    handleError(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    constructor(private httpclient: HttpClient, private authService: AuthService, private toastr: ToastrService) {

    }



    private cacheString = new BehaviorSubject<string>('default value');
    cacheString$: Observable<string> = this.cacheString.asObservable();

    loadString(str: string) {
        this.cacheString.next(str);
    }



    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }



}
