<!-- begin::Footer -->
<footer class="m-grid__item		m-footer" appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height m-page__container">
		<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
			<div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                <span class="m-footer__copyright">
                    &copy; SurePact
                </span>
				<a href="https://surepact.com/wp-content/uploads/2023/03/SYO1-2002742451-v1-SurePact-Privacy-Policy-Clean-07.02.23.pdf" target="_blank">                                    
					<span class="m-footer__copyright" style="float: right; margin-right: 20px;">
				  	 Privacy Policy
					</span>
				</a>
			</div>
		</div>
	</div>
</footer>
<!-- end::Footer -->
