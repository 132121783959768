
import {throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs';
import { ProjectSettingsModel } from '@app/models/ProjectSettingsModel';
import { DomSanitizer } from '@angular/platform-browser';
import { shareReplay, map, catchError, tap } from 'rxjs/operators';
import * as Rx from 'rxjs';

import { AuthService } from "@app/auth/_services/auth0.service";
import { ProjectModel } from '@app/models/ProjectModel';
import { RiskFactorQuestionModel } from '@app/models/RiskFactorQuestionModel';
import { WorkflowModel } from '@app/models/WorkflowModel';
import { MemberModel } from '@app/models/MemberModel';
import { ToastrService } from 'ngx-toastr';
import { ContractModel, ContractListModel } from '@app/models/ContractModel';
import { ReportTemplateModel } from '@app/models/ReportTemplateModel';
import { ContingencyModel } from '@app/models/ContingencyModel';
import { AssessmentModel } from '@app/models/assessmentmodel';
import { FileModel } from '@app/models/FileModel';
import { Router } from '@angular/router';
import { UserModel } from '@app/models/UserModel';
import { IWorkflowEntityService } from './interfaces/IWorkflowEntity.service';
import { IEntityWithMemberFileService } from './interfaces/IEntityFile.service';
import { WorkflowGroupModel } from '@app/models/WorkflowGroupModel';
import { WorkflowStageModel } from '@app/models/WorkflowStageModel';
import { ProjectSummaryModel } from '@app/models/ProjectSummaryModel';

@Injectable()
export class ProjectsService implements IEntityWithMemberFileService , IWorkflowEntityService{

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService,private router: Router) {
        this.projects = this.httpclient.get<any>('/api/v1/projects')
        .pipe(map(res => res))
    }

    public getWorkflowGroups(id): Observable<WorkflowGroupModel[]> {
        return this.httpclient.get<any>(`/api/v1/projects/${id}/workflowgroup/`)
        .pipe(map(res => res));
    }

    public getWorkflowStagesByGroupId(id,groupId): Observable<WorkflowStageModel[]> {
        return this.httpclient.get<any>(`/api/v1/projects/${id}/workflowstage/group/${groupId}`)
        .pipe(map(res => res));
    }
    

    private projects: Observable<ProjectModel[]> ;

    public shouldRedrawRiskFactorQuestions: Subject<any> = new Rx.Subject<any>();

    public getApprovedFiles(id): Observable<any> {
        return null;
    }

    public uploadEntityFile(formData, projectId): Observable<any>{
        return this.httpclient.post<any>(`/api/v1/projects/${projectId}/files`, formData).pipe(
            tap(res => {
                this.toastr.success(`A new document has been attached to ${res.name}!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }

    public updateEntityFile(formData, projectId): Observable<FileModel>{
        return this.httpclient.post<any>(`/api/v1/projects/${projectId}/files/edit`, formData).pipe(
            tap(res => {
                this.toastr.success(`Document Updated!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }


    public getEntityFile(projectId, id): Observable<any> {
        return this.httpclient.get(`/api/v1/projects/${projectId}/files/${id}`, { responseType: 'blob' })
            .pipe(res => res)
    }

    public deleteEntityFile(projectId, id): Observable<FileModel[]> {
        return this.httpclient.delete<FileModel[]>(`/api/v1/projects/${projectId}/files/${id}`)
            .pipe(res => res)
    }

    public getProjects(): Observable<ProjectModel[]> {
        return this.projects.pipe(shareReplay(1));
    }

    public getAttachableProjects(): Observable<ProjectModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/attachableProjects')
            .pipe(map(res => res))
    }

    public deleteProject(id: string): Observable<any> {
        return this.httpclient.delete(`/api/v1/projects/${id}`)
            .pipe(
            tap(c => {
                this.toastr.success("Project deleted", "Deleted");
            }),
            catchError((err: any) => {
                this.toastr.error("There was an issue during the Project deletion", "not able to delete");
                return observableThrowError(this.errorHandler(err))
            }));;
    }


    public getProjectsArchived(): Observable<ProjectModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/archived')
            .pipe(map(res => res))
    }

    public getProject(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/projects/' + id)
            .pipe(map(res => res),
            catchError(err => 
            {
                return observableThrowError(this.errorHandler(err));      
            }),
            );
    }



    public updateProject(formData): Observable<ProjectModel> {
        return this.httpclient.post<any>('/api/v1/projects', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project ' + p.name + ' Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public addProject(formData): Observable<ProjectModel> {
        return this.httpclient.post<any>('/api/v1/projects', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project ' + p.name + ' Added!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }




    errorHandler(error: any): void {
    }




    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }


    public getProjectQuestionsAtContractGateway(id, contractId, contractGateway) {
        if (contractGateway == null || contractGateway==='') {
            contractGateway = -1;
        }
         return this.httpclient.get<RiskFactorQuestionModel[]>('/api/v1/projects/' + id + '/questions/' + contractId + '/'+ contractGateway + '/')
        .pipe(map(res => res))
     }
 


    public getProjectQuestions(id, gateway) {
        if (gateway == null || gateway==='') {
            return this.httpclient.get<RiskFactorQuestionModel[]>('/api/v1/projects/' + id + '/questions')
                .pipe(map(res => res))
        }
        else {
            return this.httpclient.get<RiskFactorQuestionModel[]>('/api/v1/projects/' + id + '/questions/' + gateway)
                .pipe(map(res => res))

        }
    }

    public setProjectQuestions(id, formData,skipApproval) {
        return this.httpclient.post<any>(`/api/v1/projects/${id}/questions/${skipApproval}`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project Questions Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getProjectSettings(id) {
        return this.httpclient.get<ProjectSettingsModel>(`/api/v1/projects/${id}/settings`).pipe(map(res => res));
    }

    public getWorkflow(id): Observable<WorkflowModel> {
        return this.httpclient.get<any>('/api/v1/projects/' + id + '/workflow')
            .pipe(map(res => res))
    }
    public setWorkflow(id, formData): Observable<WorkflowModel> {
        return this.httpclient.post<any>('/api/v1/projects/' + id + '/workflow', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project Workflow Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getMembers(id): Observable<MemberModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/' + id + '/members')
            .pipe(map(res => res))
    }
    public setMembers(id, formData): Observable<MemberModel> {
        return this.httpclient.post<any>('/api/v1/projects/' + id + '/members', formData).pipe(
            tap(p => {
                this.toastr.success('Project Members Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }


    public getDefaultMembers(): Observable<UserModel[]> {
        return this.httpclient.get<any>(`/api/v1/projects/members/default`)
            .pipe(map(res => res))
    }


    public getProjectApprovals(id): Observable<AssessmentModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/' + id + '/approvals')
            .pipe(map(res => res))
    }

    public getProjectApproval(id, formData: AssessmentModel) {

        return this.httpclient.post<any>('/api/v1/projects/' + id + '/approval/request', formData).pipe(
            tap(res => {
                this.toastr.success('Project Approval Requested!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public setProjectApproved(id, formData: AssessmentModel) {
        return this.httpclient.post<AssessmentModel>('/api/v1/projects/' + id + '/approval/process', formData).pipe(
            tap(res => {
                this.toastr.success('Project Response Submitted!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public getProjectContracts(id): Observable<ContractListModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/' + id + '/contracts')
            .pipe(map(res => res))
    }

    public getDocuments(): Observable<ReportTemplateModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/documents')
            .pipe(map(res => res))
    }


    public getProjectPlan(id): Observable<ContingencyModel[]> {
        return this.httpclient.get<any>('/api/v1/projects/' + id + '/plan')
            .pipe(map(res => res))
    }

    public setProjectCloseout(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/projects/' + id + '/closeout', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project Closed out!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public setProjectArchive(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/projects/' + id + '/archive', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project Archived!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public setProjectUnarchive(id, formData): Observable<any[]> {
        return this.httpclient.post<any[]>('/api/v1/projects/' + id + '/unarchive', formData)
            .pipe(
            tap(p => {
                this.toastr.success('Project Un-Archived!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public updateProjectPc(projectData): Observable<any> {
        return this.httpclient.post<any>('/api/v1/projects/updateProjectPercentageComplete',projectData)
        .pipe(tap(res=>{
                this.toastr.success("Percentage Complete Updated!");  
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public inlineUpdateProject(formData): Observable<ProjectSummaryModel> {
        return this.httpclient.post<any>(`/api/v1/projects/updateprojectsummary/`, formData)
            .pipe(
                tap(c => {}),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public projectSummaryListRefresh(): Observable<ProjectSummaryModel> 
    {
        return this.httpclient.get<any>('/api/v1/projects')
        .pipe(map(res => res))
    }

}
