import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TerminologyModel } from '../../../models/TerminologyModel';
import { TerminologytranslatorService } from '../../../services/TerminologyTranslator.service';
import { LoggingService } from '@app/services/logging.service';

export function HttpLoaderFactory(http : HttpClient){
  return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,TranslateModule.forRoot({
      loader :{
         provide : TranslateLoader,
         useFactory : HttpLoaderFactory,
         deps :  [HttpClient]
      }
  })
  ],exports :[
    TranslateModule
  ]
})

export class TerminologyTranslatorModule {
  constructor(public translate: TranslateService, public terminologytranslatorService: TerminologytranslatorService, private http: HttpClient, loggingService: LoggingService) {
     if (loggingService.successLogin) {
      this.terminologytranslatorService.initializeTerminology(translate);
     }
  }
 }
