import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChangeEventArgs, CheckBoxComponent,  } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'formsCheckbox',
  templateUrl: './formsCheckbox.component.html',
  styleUrls: ['./formsCheckbox.component.css']
})
export class FormsCheckboxComponent implements  AfterViewInit {

  @Input() label:string;
  @Input() checked:boolean=false;
  @Input() disabled:boolean=false;
  @Output() checkBoxValue: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('checkBox')
  public checkBoxObject = CheckBoxComponent;

  ngAfterViewInit(): void {
    this.checkBoxObject['label'] = this.label;
  }

  onChange(event:ChangeEventArgs)
  {
   this.checkBoxValue.emit(event["checked"]);
  }
}
