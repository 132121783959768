import {Subject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { shareReplay, map, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as Rx from 'rxjs';
import { AuthService } from "@app/auth/_services/auth0.service";
import { PortfolioListModel, PortfolioModel, PortfolioSettingsModel } from '@app/models/PortfolioModel';
import { ToastrService } from 'ngx-toastr';
import { MemberModel } from '@app/models/MemberModel';
import { WorkflowModel } from '@app/models/WorkflowModel';
import { IWorkflowEntityService } from './interfaces/IWorkflowEntity.service';
import {  IEntityWithMemberFileService } from './interfaces/IEntityFile.service';
import { FileModel } from '@app/models/FileModel';
import { AssessmentModel } from '@app/models/assessmentmodel';
import { RiskFactorQuestionModel } from '@app/models/RiskFactorQuestionModel';
import { WorkflowGroupModel } from '@app/models/WorkflowGroupModel';
import { WorkflowStageModel } from '@app/models/WorkflowStageModel';

@Injectable()
export class PortfoliosService  implements IWorkflowEntityService, IEntityWithMemberFileService{ 

    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {
        this.portfolios = this.httpclient.get<any>('/api/v1/portfolios')
        .pipe(map(res => res));
    }

     getWorkflowGroups(id): Observable<WorkflowGroupModel[]> {
        return this.httpclient.get<any>(`/api/v1/portfolios/${id}/workflowgroup`)
        .pipe(map(res => res));
    }
    
    getWorkflowStagesByGroupId(id: any, groupId: any): Observable<WorkflowStageModel[]> {
        return this.httpclient.get<any>(`/api/v1/portfolios/${id}/workflowstage/group/${groupId}`)
        .pipe(map(res => res));
    }
    
    public shouldRedrawRiskFactorQuestions: Subject<any> = new Rx.Subject<any>();

    uploadEntityFile(formData: any, portfolioId: any): Observable<any> {
        return this.httpclient.post<any>(`/api/v1/portfolios/${portfolioId}/files`, formData).pipe(
            tap(res => {
                this.toastr.success('Document Uploaded!');
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }
    updateEntityFile(formData: any, portfolioId: any): Observable<FileModel> {
        return this.httpclient.post<any>(`/api/v1/portfolios/${portfolioId}/files/edit`, formData).pipe(
            tap(res => {
                this.toastr.success(`Document Updated!`);
            }),
            catchError((err: any) => throwError(this.errorHandler(err)))
        );
    }
    getEntityFile(projectId: any, id: any): Observable<any> {
        throw new Error('Method not implemented.');
    }
    deleteEntityFile(projectId: any, id: any): Observable<FileModel[]> {
        throw new Error('Method not implemented.');
    }
    getApprovedFiles(id: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    public savePortfolio(formData): Observable<any> {
        var isNew = formData.id == undefined;
        return this.httpclient.post<any>('/api/v1/portfolios/', formData).pipe(
            tap(res => {            
                if(isNew){
                    this.toastr.success('Portfolio Created!');               
                } else {
                    this.toastr.success('Portfolio Updated!'); 
                }
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err))));
    }

    private portfolios: Observable<PortfolioModel[]>;

    public getWorkflow(id): Observable<WorkflowModel> {
        return this.httpclient.get<any>(`/api/v1/portfolios/${id}/workflow`)
            .pipe(map(res => res));
    }

    public setWorkflow(id, formData): Observable<WorkflowModel> {
        return this.httpclient.post<any>(`/api/v1/portfolios/${id}/workflow`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Portfolio Workflow Saved!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public closeout(id, formData): Observable<WorkflowModel> {
        return this.httpclient.post<any>(`/api/v1/portfolios/${id}/closeout`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Portfolio moved to Closeout Risk Profile Stage!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public closeoutComplete(id, formData): Observable<WorkflowModel> {
        return this.httpclient.post<any>(`/api/v1/portfolios/${id}/closeoutcomplete`, formData)
            .pipe(
            tap(p => {
                this.toastr.success('Closeout Stage Completed!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getMembers(id): Observable<MemberModel[]> {
        return this.httpclient.get<any>(`/api/v1/portfolios/${id}/members`)
            .pipe(map(res => res))
    }
    
    public setMembers(id, formData): Observable<MemberModel> {
        return this.httpclient.post<any>(`/api/v1/portfolios/${id}/members`, formData).pipe(
            tap(p => {
                this.toastr.success('Portfolio Members Updated!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }
    
    public getPortfolio(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/portfolios/' + id)
            .pipe(map(res => res),
            catchError(err => 
            {
                return observableThrowError(this.errorHandler(err));      
            }),
            );
    }

    public getProjects(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/portfolios/' + id+'/projects')
            .pipe(map(res => res),
            catchError(err => 
            {
                return observableThrowError(this.errorHandler(err));      
            }),
            );
    }

    public getPortfolios() : Observable<PortfolioModel[]>
    {
        return this.httpclient.get<PortfolioModel[]>('/api/v1/portfolios/')
        .pipe(map(res => res),
        catchError(err => 
        {
            return observableThrowError(this.errorHandler(err));      
        }),
        );
    }

    public getPortfolioList(): Observable<PortfolioListModel[]> {
        return this.httpclient.get<any>('/api/v1/portfolios/list')
            .pipe(map(res => res))
    }

    public updatePortfolioPc(portfolioData): Observable<any> {
        return this.httpclient.post<any>('/api/v1/portfolios/updatePortfolioPc',portfolioData)
        .pipe(tap(res=>{
            this.toastr.success("Percentage Complete Updated!");   
        }),
        catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    errorHandler(error: any): void {
    }

    public setGrantLinkToPortfolio(grantId, portfolioId): Observable<MemberModel> {        
        return this.httpclient.get<any>(`/api/v1/portfolios/${portfolioId}/linkGrant/${grantId}`).pipe(
            tap(p => {
                this.toastr.success('Grant linked to the Portfolio!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }  
   
    public setProjectLinkToPortfolio(projectId, portfolioId): Observable<MemberModel> {        
        return this.httpclient.get<any>(`/api/v1/portfolios/${portfolioId}/linkProject/${projectId}`).pipe(
            tap(p => {
                this.toastr.success('Project linked to the Portfolio!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }  

    public deletePortfolioGrantLink(id, grantId): Observable<any> {
        return this.httpclient.delete(`/api/v1/portfolios/${id}/deleteGrant/${grantId}`)
            .pipe(
            tap(c => {
                this.toastr.success("Grant unlinked from the portfolio");
            }),
            catchError((err: any) => {
                this.toastr.error("There was an issue during the grant deletion");
                return observableThrowError(this.errorHandler(err))
            }));;
    }

    public deletePortfolioProjectLink(id, projectId): Observable<any> {
        return this.httpclient.delete(`/api/v1/portfolios/${id}/deleteProject/${projectId}`)
            .pipe(
            tap(c => {
                this.toastr.success("Project unlinked from the portfolio");
            }),
            catchError((err: any) => {
                this.toastr.error("There was an issue during the project deletion");
                return observableThrowError(this.errorHandler(err))
            }));;
    }

    public getPortfolioApprovals(id): Observable<AssessmentModel[]> {
        return this.httpclient.get<any>('/api/v1/portfolios/' + id + '/approvals')
            .pipe(map(res => res))
    }

    public getPortfolioSettings(id) {
        return this.httpclient.get<PortfolioSettingsModel>(`/api/v1/portfolios/${id}/settings`).pipe(map(res => res));
    }

    public getPortfolioQuestions(id, gateway) {
        if (gateway == null || gateway === '') {
            return this.httpclient.get<RiskFactorQuestionModel[]>('/api/v1/portfolios/' + id + '/questions')
                .pipe(map(res => res))
        }
        else {
            return this.httpclient.get<RiskFactorQuestionModel[]>('/api/v1/portfolios/' + id + '/questions/' + gateway)
                .pipe(map(res => res))

        }
    }

    public setPortfolioQuestions(id, formData,skipApproval) {
        return this.httpclient.post<any>(`/api/v1/portfolios/${id}/questions/${skipApproval}`, formData)
            .pipe(
                tap(p => {
                    this.toastr.success('Portfolio Questions Saved!');
                }),
                catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    }

    public getPortfolioApproval(id, formData: AssessmentModel) {

        return this.httpclient.post<any>('/api/v1/portfolios/' + id + '/approval/request', formData).pipe(
            tap(res => {
                this.toastr.success('Portfolio Approval Requested!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public setPortfolioApproved(id, formData: AssessmentModel) {
        return this.httpclient.post<AssessmentModel>('/api/v1/portfolios/' + id + '/approval/process', formData).pipe(
            tap(res => {
                this.toastr.success('Portfolio Response Submitted!');
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    public getPortfolioDocumentFiles(portfolioId) : Observable<any>
    {
        return this.httpclient.get<any>(`/api/v1/portfolios/GetPortfolioFiles/${portfolioId}` )
        .pipe(map(res => res),
        catchError(err => 
        {
            return observableThrowError(this.errorHandler(err));      
        }),
        );
    }

    public deletePortfolio(id: string): Observable<any> {
        return this.httpclient.delete(`/api/v1/portfolios/${id}`)
            .pipe(
                tap(c => {
                    this.toastr.success("Portfolio deleted", "Deleted");
                }),
                catchError((err: any) => {
                    this.toastr.error("There was an issue during the Portfolio deletion", "Not able to delete");
                    return observableThrowError(this.errorHandler(err))
                }));;
    }
}
