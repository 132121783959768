
import { AfterViewInit, Component, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AccountsService } from '@app/services/accounts.service';
import { UserModel } from '@app/models/UserModel';
import { CommonCodeComponent } from '@app/components/commoncode/commoncode.component';
import { SimpleModalService, SimpleModalComponent } from 'ngx-simple-modal';
import { BusinessUnitModel } from '@app/models/BusinessUnitModel';
import { DepartmentModel } from '@app/models/DepartmentModel';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, finalize } from 'rxjs/operators';
import { ContractorSummaryModel } from '@app/models/ContractorSummaryModel';
import { ContractorsService } from '@app/services/contractors.service';
import { OrganisationService } from '@app/services/organisation.service';
import { OrganisationModel } from '@app/models/OrganisationModel';
import { SelectedEventArgs, UploaderComponent, UploadingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { OrganisationTenancyType } from '@app/constants';
import Utils from '@app/utils';
import * as moment from 'moment';
import { AddressSearchModel } from '@app/models/AddressSearchModel';
import { LookupModel } from '@app/models/common/LookupModel';
import { UserDetailsComponentBase } from './userdetails.component.base';

@Component({
    selector: 'app-userdetails',
    templateUrl: './userdetails.component.html',
    styleUrls: ['./userdetails.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class UserDetailsComponent extends UserDetailsComponentBase {
    selectedDepartment = null;
    selectedBusinessUnit = null;
    selectedContractor = null;
    businessUnits: BusinessUnitModel[] = [];
    departments: DepartmentModel[] = [];
    contractors :ContractorSummaryModel [] = [];
    countryIsoCode: string;
    timezone: string;
    timezoneGMT: string = "";

    public fields: Object = {text: 'displayName', value: 'id'};

    constructor(
        simpleModalService: SimpleModalService,  
        organisationService : OrganisationService,
        sanitizer: DomSanitizer, 
        protected commonComponent: CommonCodeComponent, 
        protected contractorsService: ContractorsService,
        protected accountsService: AccountsService
    ) 
    {
        super(simpleModalService, organisationService, accountsService, sanitizer, commonComponent);
    }

    ngOnInit() {

        this.contractorsService.getContractors().subscribe(contractors =>{
            this.contractors = contractors;
            if(this.user){
                this.selectedContractor = this.user.contractor;
            }
        });

        this.accountsService.getDepartments().subscribe(dept => {
            this.departments = dept;
            if(this.user)
            {
                this.selectedDepartment = this.user.department;
            }
        });

        this.accountsService.getBusinessUnits().subscribe(bu => {
            this.businessUnits = bu;
            if(this.user)
            {
                this.selectedBusinessUnit = this.user.businessUnit;
            }
        });

        this.initBaseComponent();
    }
  
    onDepartmentOptionSelected(event) {
        this.user.department = event
        this.user.department.organisation = this.user.organisation;
    }

    onBusinessUnitOptionSelected(event) {
        this.user.businessUnit = event;
        this.user.businessUnit.organisation = this.user.organisation;
    }

    onContractorOptionSelected(event) {
        this.user.contractor = event;
    }

    compareFn(item1: any, item2: any): boolean {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }

    onCheckBoxPrimaryContactChange(value: boolean) {
        this.user.isPrimaryContact=value
    }

    onCheckBoxActiveChange(value: boolean) {
        this.user.active=value
    }

    saveChanges() {
        this.commonComponent.blockRegion('#user');

        if(this.modal)
        {
            if(!this.user.id)
            {
                this.accountsService.AddUser(this.user,this.isRecipient).subscribe(res => {
                    this.commonComponent.unblockPage();
                    this.result = res;
                    this.close();
    
                }, () => {
                    this.commonComponent.unblockPage();
                    this.close();
                });
            }
            else
            {
                this.accountsService.UpdateUser(this.user,this.isRecipient).subscribe(res => {
                    this.commonComponent.unblockPage();
                    this.result = res;
                    this.close();
    
                }, () => {
                    this.commonComponent.unblockPage();
                    this.close();
                });
            }
        }
        else
        {
            this.accountsService.UpdateMyProfle(this.user).pipe(
                finalize(() => {
                    this.commonComponent.unblockRegion('#user');
                }))
                .subscribe(res => {
                    this.accountsService.UserClearCache();
                    this.user = res;
                });
        }
    }
}

function observableThrowError(arg0: any): any {
    throw new Error('Function not implemented.');
}

