export const environment = {

    production: false,

    showMaintenance: false,
    directApiUrl: `https://sp-dev-api.azurewebsites.net`,
    apiUrl: `https://sp-dev-api.azurewebsites.net`,

    apiKey: `c99d100b99c048358088b5e4ac07e96a`,

    clientId: `qD3qz7xD5GRlsFeKh2dMD1VDsIbYJD7F`,

    domain: `surepactdemo.au.auth0.com`,

    callbackUrl: `xxxxx`,
    aadClientId: '65d5b6fb-54d7-41c9-b1c9-5688254d93ab',
    aadAuthority: 'https://login.microsoftonline.com/9aeba085-5f43-4eee-a13e-a0c5dbf9950c',
    aadScope:'https://surepactauth.onmicrosoft.com/65d5b6fb-54d7-41c9-b1c9-5688254d93ab/ReadUser',
    aadTenants: [''],
    eulaLink: 'https://surepact.com/end-user-licence-agreement/',
    siteKey: '6LeIX_spAAAAABKZum9YnkA0idXM8rdRC353tPNM',
    azureMapsApi: {
        apiVersion: '1.0',
        language: 'en-AU',
        countrySet: 'AU',
        apiKey: '9hOsTT8wE9CLbKQWQNF1vfNPeHR1dWO1LDcFMlX1lwIJRCl4ZNkwJQQJ99AFACYeBjFyIlYSAAAgAZMPEDgO',
        searchApi: 'https://atlas.microsoft.com/search/address/json'
    }
};