import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReferenceTypeModel } from '../models/ReferenceTypeModel';



@Injectable()
export class ReferenceTypesService {
    constructor(private httpclient: HttpClient) {
    }
    
    public getReferenceTypes(entityType: string): Observable<ReferenceTypeModel[]> {
        return this.httpclient.get<any>(`/api/v1/${entityType}/referencetypes`)
            .pipe(map(res => res));
    }
}
