import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TerminologyModel } from '../models/TerminologyModel';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { shareReplay } from 'rxjs/operators';
import { map } from 'jquery';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TerminologytranslatorService {

  public defaultTerminologyLang = "defaultterminology";
  public loaded: EventEmitter<any> = new EventEmitter();
  public fullyLoaded = false;
  public terminologies = [];

  public terminologyModuleOrgIsEnabled$;
  public tenantTerminologyFile$;
  constructor(private httpclient: HttpClient, public translate: TranslateService, private toastr: ToastrService, private http: HttpClient,  
    private router: Router) {
     this.terminologyModuleOrgIsEnabled$ = this.http.get<any>(`/api/v1/terminology/TerminologyModuleIsEnabled`).pipe(shareReplay());
     this.tenantTerminologyFile$ = this.http.get<string>(`/assets/i18n/${this.getTerminology()}.json`).pipe(shareReplay());
  }
 
  public initializeTerminology(translate: TranslateService) {

    this.terminologyModuleOrgIsEnabled$.subscribe(isTerminologyEnable => {
      if (isTerminologyEnable) {

        //check if file existing on asset folder if exist use tenancy terminology mapping file if not use Default language
        this.tenantTerminologyFile$.subscribe(
          res => {
            this.terminologies = res;
            //set translation if there is no current language
            if (!translate.store.currentLang)
              translate.use(this.getTerminology()).pipe(shareReplay()).subscribe(v=>{  
                this.fullyLoaded = true;
                 this.loaded.next(true);
                });

       
          },
          err => {

            this.toastr.error("Organisation Terminology mapping file not found.");

            //set translation if there is no current language
            if (!translate.store.currentLang)
            this.useDefaultTerminology(translate);
          }
        )
      } else {
        if (!translate.store.currentLang)
         this.useDefaultTerminology(translate);

      }
    })
  }

  public useDefaultTerminology(translate : TranslateService)
  {
    translate.use(this.defaultTerminologyLang).pipe(shareReplay()).subscribe(v=>{ 
      this.terminologies = v;
      this.fullyLoaded = true;
       this.loaded.next(true);
      },    err =>{ 
        this.toastr.error("Default Terminology mapping file not found.");
        this.router.navigate(['/logout']);
      });
  }

  public getTerminology(): string {

    //uncomment this in production or environment testing
    return window.location.hostname.replace(".surepact.com", "");
  }

  public setDomTerminologyValue(terminologyModel: TerminologyModel, concatString: string = "") {
    if (this.fullyLoaded) {
      this.setDomTerminologyValueInternal(terminologyModel, concatString);
    } else {
      this.loaded.subscribe(v => {
        this.setDomTerminologyValueInternal(terminologyModel, concatString);
      });
    }
  }

  private setDomTerminologyValueInternal(terminologyModel: TerminologyModel, concatString: string) {
    if (this.translate.store.currentLang) {
      //wait Translate service to be initialize
      this.translate.get(`${terminologyModel.terminologyProperty}`).subscribe((translation) => {

        //query
        var queryResults = document.querySelectorAll(terminologyModel.querySelector);

        //iterate on result implement translation
        queryResults.forEach(result => {

          result['innerText'] = translation + concatString;
        });
      });
    }
  }

  public setDomsTerminologyValues(terminologyModels: TerminologyModel[]): any {

    terminologyModels.forEach(element => {
      this.translate.get(`${element.terminologyProperty}`).subscribe((translation) => {
        document.querySelectorAll(element.querySelector)[0]['innerText'] = translation;
      });
    });

  }

  public getTerminologyByProp(prop: string): string {
    if (this.translate.currentLang == this.defaultTerminologyLang) {
      return prop;
    }
    else if (!this.translate.currentLang) {
      this.initializeTerminology(this.translate);
    }

    var term = ""
    this.translate.get(prop).subscribe((translation) => {
      term = translation;
    });

    return term;
  }

  public getTenantJsonData(): Observable<any> {
    let jsonFolderPath:string = `./assets/i18n/${this.getTerminology()}.json`;
    return this.http.get(jsonFolderPath);
  }

  public backEndRpopErrorConstructor(error: string) {

    //get message value from json file using the thrown Backend RPOP exception mapping
    var rpopMessageTerminology = this.getTerminologyByProp(error.substring(error.indexOf("{") + 1, error.indexOf("}")));

    //split using bracket to identify the properties need for translation
    var rpopMessagePropertiesSplitted= rpopMessageTerminology.split("{");

    //array iteration
    rpopMessagePropertiesSplitted.forEach(text => {
      if (text.includes("}")){
        var closeBracketIndex = text.indexOf("}")
        var targetTerminologyProperty = text.substring(0, text.indexOf("}"));
        rpopMessageTerminology= rpopMessageTerminology.replace(`{${targetTerminologyProperty}}`, this.getTerminologyByProp(targetTerminologyProperty));
      }
    })

    this.toastr.error(rpopMessageTerminology);
  }

  public ProcessJsonFiles(terminologies: TerminologyModel[]) {
    this.PostTerminologyJsonData(terminologies).subscribe(data=>{});
  }

  public PostTerminologyJsonData(terminologies: TerminologyModel[]): Observable<any> {
    return this.httpclient.post<any>(`/api/v1/terminology/save`, terminologies).pipe(res => res);
  }

  public paragraphTranslation(prop:string) {
    const terms = this.terminologies;
    return Object.keys(terms).reduce((term, key) => term.replace(key, terms[key]), prop);
  }
}
