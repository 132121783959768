import { AccountsService } from '@app/services/accounts.service';
import { MemberModel } from '@app/models/MemberModel'; 
import { Injectable } from '@angular/core';


@Injectable()
export class MembersHelperService {

    constructor(private accountsService: AccountsService) {
    }

    filterUsers(users, currentMembers = []) {
            // Converting the UserModel into the MemberModel for the Project
            return users.filter(um => !currentMembers.some(pm => um.id === pm.user.id));
    }

    buildMember(user, requestor = null) {
        requestor = requestor ? requestor : user;

        return <MemberModel> {
            id: 0,
            user: user,
            assessableEntity: null,
            notificationsEnabled: true,
            viewOnly: user.viewOnly,
            grantedOn: null,
            grantedBy: requestor,
            isApprover: false,
            isOwner: false,
            isManager: false,
            isSurePactAdmin: false,
            isTennantAdmin: false,
            isCreator: false,
            hideFinancialData: false,
            isMassMoveable : true,   
            isDefaultMember : user.isDefaultMember         
        };
    }

    buildDefaultMember(user, requestor = null) {
        requestor = requestor ? requestor : user;

        return <MemberModel> {
            id: 0,
            user: user,
            assessableEntity: null,
            notificationsEnabled: false,
            viewOnly: true,
            grantedOn: null,
            grantedBy: requestor,
            isApprover: false,
            isOwner: false,
            isManager: false,
            isSurePactAdmin: false,
            isTennantAdmin: false,
            isCreator: false,
            hideFinancialData: false,
            isMassMoveable : true,
        };
    }
}