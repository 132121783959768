
import { Component, ViewEncapsulation } from '@angular/core';
import { AsideNavComponent } from '../aside-nav/aside-nav.component';
import { TerminologytranslatorService } from '@app/services/TerminologyTranslator.service';

@Component({
    selector: "app-fundingbodyrecipientmenu",
    templateUrl: "./fundingbodyrecipientmenu.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../aside-nav/aside-nav.component.scss'],
})
export class FundingbodyRecipientMenuComponent extends  AsideNavComponent {

    constructor(terminologytranslatorService: TerminologytranslatorService)
    {
        super(terminologytranslatorService);
    }
     
}