
import {throwError as observableThrowError} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs'
import { catchError, map, tap, shareReplay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { GanttViewModel } from '@app/models/GanttViewModel';

@Injectable()
export class GanttViewService   {
    constructor(private httpclient: HttpClient, private toastr: ToastrService) {
    }

    public getGanttViewData(): Observable<GanttViewModel[]> {
        return this.httpclient.get<GanttViewModel[]>('/api/v1/ganttview/data').pipe(shareReplay(1));
    }

    public getPortfolioGanttViewData(id : string): Observable<GanttViewModel[]> {
        return this.httpclient.get<GanttViewModel[]>('/api/v1/ganttview/portfoliodata/'+id).pipe(shareReplay(1));
    }

    errorHandler(error: any): void {
    }
}