import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AgGridModule } from 'ag-grid-angular';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgDragDropModule } from 'ng-drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthModule } from "./auth/auth.module";
import { GlobalErrorHandler } from './_services/error-handler.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { UserComponent } from './theme/pages/default/user/user.component';
import { PrefixesService } from '@app/services/prefixes.service';
import { AccountsService } from '@app/services/accounts.service';
import { ProjectsService } from '@app/services/projects.service';
import { PortfoliosService } from '@app/services/portfolios.service';
import { WorkflowService } from '@app/services/workflow.service';
import { CloneService } from '@app/services/clone.service';
import { GovernanceLogService } from '@app/services/governancelog.service';
import { NotificationsService } from '@app/services/notifications.service';
import { AuthService } from '@app/auth/_services/auth0.service';
import { LibraryService } from '@app/services/library.service';
import { ContractsService } from '@app/services/contracts.service';
import { MembersHelperService } from '@app/services/members.helper.service';
import { ContractorsService } from '@app/services/contractors.service';
import { ReportsService } from '@app/services/reports.service';
import { TrainingService } from '@app/services/training.service';
import { DelegationService } from '@app/services/delegations.service';
import { TestService } from '@app/services/test.service';
import { CommonCodeComponent } from '@app/components/commoncode/commoncode.component';
import { ChartModule } from '@app/components/chart/chart.module';
import { ChartsService } from '@app/services/charts.service';
import { ReportService } from '@app/services/report.service';
import { MessagesService } from '@app/services/messages.service';
import { HeaderModule } from '@app/components/reusable/surepact-header/header.module';
import { ReferenceTypesService } from './services/referenceTypes.service';
import { GrantsService } from '@app/services/grants/grants.service';
import { GrantCostService } from './services/grants/grantcost.service';
import { ActivitiesService } from '@app/services/activities.service';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DocumentService } from '@app/services/document.service';
import { GanttViewService } from '@app/services/ganttview.service';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { LoggingService } from './services/logging.service';
import { BusinessUnitsService } from './services/businessunits.service';
import { DepartmentsService } from './services/departments.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './components/reusable/terminologyTranslator/terminologyTranslator.module';
import { FundingProgramsComponent } from './theme/pages/default/fundingprograms/fundingprograms.component';
import { NavigationHelperService } from '@app/services/navigation.helper.service';
import { HeaderStore } from '@app/auth/_helpers/headerstore';
import { SignalRService } from './services/signalr.service';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth/auth-config';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

@NgModule({
    declarations: [
        ThemeComponent,
        AppComponent,
        CommonCodeComponent
    ],
    imports: [
        HeaderModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AuthModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AgGridModule,
        MatSnackBarModule,
        MatMenuModule,
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
        }),
        ChartModule,
        //CurrencyMaskModule,
        NgDragDropModule.forRoot(),
 
        NumericTextBoxModule,
        MultiSelectAllModule,
        DropDownListModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            },
            isolate: false
          }),
    ],
    providers: [
        Location,
        GlobalErrorHandler,
        ScriptLoaderService,
        UserComponent,
        AccountsService,
        AuthService,
        PrefixesService,
        CloneService,
        ReferenceTypesService,
        ProjectsService,
        WorkflowService,
        GovernanceLogService,
        NotificationsService,
        LibraryService,
        ContractsService,
        MembersHelperService,
        ContractorsService,
        ReportsService,
        TrainingService,
        TestService,
        AgGridModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        ToastrService,
        CommonCodeComponent,
        ChartsService,
        DelegationService,
        ReportService,
        MessagesService,
        GrantsService,
        GrantCostService,
        ActivitiesService,
        DocumentService,
        GanttViewService,
        PortfoliosService,
        BusinessUnitsService,
        LoggingService,
        DepartmentsService,
        NavigationHelperService,
        HeaderStore,
        SignalRService,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    exports: [
        HttpClientModule,
        CommonCodeComponent
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
