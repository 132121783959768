
import {throwError as observableThrowError,  Observable ,  BehaviorSubject ,  Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { switchMap, shareReplay, map, takeUntil, catchError, tap, share, delay } from 'rxjs/operators';
import * as Rx from 'rxjs';


import { AuthService } from "@app/auth/_services/auth0.service";
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { ViewContainerRef } from '@angular/core';
import { ProjectsAddComponent } from '@app/theme/pages/default/projects/add.component';


const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;



const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};




@Injectable()
export class ChartsService {
    handleError(arg0: any): any {
        throw new Error("Charts Method not implemented.");
    }



    constructor(private httpclient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer, private toastr: ToastrService) {

    }









    public getChart(id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/charts/' + id)
            .pipe(map(res => res))
    };
    public setChart(id, formData): Observable<any> {
        return this.httpclient.post<any>('/api/v1/charts/' + id, formData)
            .pipe(
            tap(c => {
                this.toastr.success('Chart Updated!');
                return c;
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    };







    public getMyDashboardCharts(): Observable<any> {
        return this.httpclient.get<any>('/api/v1/charts/dashboard/my')
            .pipe(map(res => res))
    };
    public setMyDashboardCharts(formData): Observable<any> {
        return this.httpclient.post<any>('/api/v1/charts/dashboard/my', formData)
            .pipe(
            tap(c => {
                this.toastr.success('My Charts Updated!');
                return c;
            }),
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
            );
    };







    public getChartBar(): Observable<any> {
        return this.httpclient.get<any>('/api/v1/charts/bar')
            .pipe(map(res => res))
    };






    public getChartOverallRisk(type): Observable<any> {

        return this.httpclient.get<any>('/api/v1/charts/overallrisk/' + type)
            .pipe(map(res => res))

    };






    public getChartTypes(type): Observable<any> {


        if (type.toLowerCase() === 'project') {
            return this.httpclient.get<any>('/api/v1/charts/dashboard/project')
                .pipe(map(res => res))
        }

        if (type.toLowerCase() === 'contract') {
            return this.httpclient.get<any>('/api/v1/charts/dashboard/contract')
                .pipe(map(res => res))
        }

    };


    public getChartByTypeAndId(chartId, type, id): Observable<any> {
        return this.httpclient.get<any>('/api/v1/charts/' + chartId + '/' + type + '/' + id)
            .pipe(map(res => res))
    };







    errorHandler(error: any): void {
    }
}
