import { Component, OnInit, ViewEncapsulation, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Helpers } from "../../helpers";
import { AuthService } from '../_services/auth0.service';

@Component({
    selector: 'maintenance',
    templateUrl: './maintenance.component.html',
    encapsulation: ViewEncapsulation.None,
})

@Injectable()
export class MaintenanceComponent implements OnInit {

    constructor(private _router: Router,

        private authService: AuthService) {
    }

    ngOnInit(): void {
      
    }
}